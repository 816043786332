@import "../helpers/_variables";
@import "../helpers/_functions";

$side-padding: 24;

@mixin round-icon($size, $fontSize, $color, $borderWidth: 1) {
    color: $color;
    font-size: rem-calc($fontSize);
    width: rem-calc($size);
    height: rem-calc($size);
    min-width: rem-calc($size);
    padding: rem-calc(($size - $fontSize - ($borderWidth * 2)) / 2);
    border: rem-calc($borderWidth) solid $color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.station-info {
    padding: rem-calc($side-padding);
    display: flex;
    flex-direction: column;

    &__title {
        font-size: rem-calc(18);
        line-height: rem-calc(28);
        font-weight: 600;
        color: $color-primary;
    }

    &__address {
        font-size: rem-calc(14);
        line-height: rem-calc(24);
    }

    &__directions {
        padding: rem-calc(16) rem-calc($side-padding) rem-calc($side-padding);
        margin-top: rem-calc(16);
        background-color: $color-blue-50;

        &__title {
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            text-align: center;
            margin-bottom: rem-calc(12);
        }

        &__links {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        &__link {
            font-size: rem-calc(10);
            line-height: rem-calc(12);
            color: $color-blue-10;
            text-align: center;

            img {
                width: rem-calc(56);
                height: rem-calc(56);
            }

            &__text {
                margin-top: rem-calc(8);
            }
        }
    }

    &__icons {
        padding-top: rem-calc(16);
        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            flex-wrap: nowrap;
            margin: 0 auto;

            &:nth-of-type(2) {
                padding-top: rem-calc(8);
            }
        }

        i {
            @include round-icon(32, 20, $color-blue-10, 1.6);
            margin: 0 rem-calc(5);

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            @include responsive_max(sm) {
                @include round-icon(26, 18, $color-blue-10, 1.6);
                margin: 0 rem-calc(2) rem-calc(3);
            }
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top:rem-calc(24);
        padding-bottom: rem-calc(8);

        .mdc-button {
            color: $secondary;
            text-transform: none;
            text-decoration: underline;
            font-size: rem-calc(14);
            line-height: rem-calc(24);
            height: auto;
        }
    }
}
