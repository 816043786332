$mdc-typography-styles-headline1: (
  font-family: $base-font-family,
  text-transform: uppercase,
  font-size: rem-calc(32),
  line-height: 1.1875em, // 32px
  font-weight: 400,
  letter-spacing: rem-calc(-0.5)
);
$mdc-typography-styles-headline2: (
  font-family: $base-font-family,
  text-transform: uppercase,
  font-size: rem-calc(28),
  line-height: 1.286em, // 36px
  font-weight: 400,
  letter-spacing: rem-calc(-0.5)
);
$mdc-typography-styles-headline3: (
  font-family: $base-font-family-secondary,
  font-size: rem-calc(32),
  font-weight: 600,
  line-height: rem-calc(40)
);

$mdc-typography-styles-headline4: (
  font-family: $base-font-family,
  font-size: rem-calc(22),
  line-height: 1.3636em, // 30px
  font-weight: 400,
  letter-spacing: rem-calc(-0.5)
);

$mdc-typography-styles-headline5: (
  font-family: $base-font-family,
  font-size: rem-calc(20),
  line-height: 1.5em,
  font-weight: 400,
  letter-spacing: rem-calc(-0.5)
);

$mdc-typography-styles-headline6: (
  font-family: $base-font-family,
  font-size: rem-calc(16),
  font-weight: 400,
  letter-spacing: rem-calc(-0.5),
  line-height: 1.25em
);

$mdc-typography-styles-body1: (
  font-size: rem-calc(16),
  line-height: rem-calc(26), // 26px
  letter-spacing: 0
);

$mdc-typography-styles-button: (
  font-family: $base-font-family,
  font-size: rem-calc(18),
);

.mdc-typography {
  &:lang(ru) {
    font-family: $base-font-family;
  }

  &.mdc-typography--headline2 {
    &.up {
      margin-top: rem-calc(15);
    }

    &.down {
      margin-bottom: rem-calc(20);
    }

    &.downer {
      margin-bottom: rem-calc(45);
    }
  }

  &.mdc-typography--headline3 {
    &.down {
      margin-bottom: rem-calc(20);
    }

    &.downer {
      margin-bottom: rem-calc(45);
    }
  }
}

@include responsive_min(sm) {
  .mdc-typography {
    &.mdc-typography--headline1 {
      font-size: rem-calc(44); // 44px
      line-height: 1.364em; // 60px
    }

    &.mdc-typography--headline2 {
      font-size: rem-calc(36);
      line-height: 1.3334em; // 48px

      &.up {
        margin-top: rem-calc(45);
      }

      &.down {
        margin-bottom: rem-calc(30);
      }
    }

    &.mdc-typography--headline3 {
      font-size: rem-calc(30);
      line-height: 1.4em; // 42px

      &.down {
        margin-bottom: rem-calc(30);
      }
    }

    &.mdc-typography--headline4 {
      font-size: rem-calc(24);
      line-height: 1.5em; // 36px
    }

    &.mdc-typography--headline5 {
      font-size: rem-calc(16);
      line-height: 1.25em; // 20px
    }
  }

  .mdc-typography--subtitle1 {
    font-size: rem-calc(18) !important;
    line-height: 1.6667em !important; // 30px
  }

  .mdc-typography--subtitle2 {
      font-size: rem-calc(20) !important;
      font-weight: 400 !important;
      line-height: 1.5em !important; // 30px
  }
}

.mdc-typography--highlighted,
.mdc-typography--body1.mdc-typography--highlighted {
  font-weight: 700;
  @include theme() {
    color: t($t-color-link);
  }
}

.mdc-typography--subtitle1 {
  line-height: 1.625em; // 26px
  font-weight: 700 !important;
  margin-bottom: rem-calc(5);
  font-size: rem-calc(16);

  &.up {
    margin-top: rem-calc(10);
  }
}

.mdc-typography--subtitle2 {
  line-height: 1.625em; // 26px
  font-weight: 700;
  font-size: rem-calc(16);
}

.mdc-typography.mdc-typography--body1 {

  &--bold{
    font-weight: bold;
  }

  &-link {
    margin-top: rem-calc(15);
    text-transform: uppercase;
  }

  &-wrapped {
    text-align: center;

    @include responsive_min(sm) {
      padding-left: rem-calc(62);
      padding-right: rem-calc(62);
      text-align: left;
    }

    .mdc-typography--body1-link {
      display: inline-block;
      width: auto;
      font-size: rem-calc(18);
      color: $color-blue-10 !important;
      transition: all 0.15s ease-in-out;

      &:hover,
      &:focus {
        color: $primary !important;
      }
    }

    .number {
      font-size: rem-calc(18);
    }

    p.head {
      margin-bottom: rem-calc(15);
    }
  }

  &-grouped {

    h4 {
      margin-bottom: rem-calc(15);
    }

    p:not(:last-child) {

      // @include responsive_min(sm) {
        margin-bottom: rem-calc(5);
      // }
    }

    a {
      // font-weight: 600;
      // text-transform: uppercase;
      text-decoration: none;
      // color: #2499d6;
    }
  }
}

.mdc-typography--disabled {
  color: $color-gray-80;
}

.mdc-typography--bold{
  font-weight: bold !important;
}

.capitalize {
  text-transform: capitalize;
}

.mdc-theme-desktop-margin {
  @include responsive_min(md) {
    margin-bottom: 15px;
  }
}

.mdc-typography--textblock {
  @include style-scrollbar;
  overflow-y: scroll;
  height: 20rem;

  span {
    display: block;
    padding: rem-calc(20);
  }
}

.mdc-typography--monospace {
  font-family: $base-font-family-monospace;
  font-size: rem-calc(14);
}