@import "../helpers/_functions";
@import "../helpers/_variables";
.translation-items {
    & > div:nth-child(even) {
        background-color:  $color-gray-20;
    }
}
.translation-row {
    padding: rem-calc(15) rem-calc(5);
    border-top: rem-calc(1) solid  $color-gray-50;
    background-color:  $color-gray-30;

    .mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__input {
        resize: none;
        margin: 0;
        padding-top: rem-calc(16);
    }
    .mdc-text-field-helper-line {
        display: none;
    }
    &__content,
    &__header {
        display: flex;
        &__last-item {
            margin-left: auto;
            .mdc-checkbox {
                margin-right: rem-calc(5);
            }
        }
    }

    &__content {
        font-size: rem-calc(14);

        div.mdc-text-field.mdc-text-field--outlined .mdc-text-field__input {
            font-size: rem-calc(14);
            line-height: rem-calc(18);
        }
    }

    &__new-header {
        display: flex;
        .item-1 {
            width: rem-calc(90);
            min-width: rem-calc(90);
        }

        div:not(.item-1) {
            flex: 1 1;
        }
    }
}
.mdc-button {
    &.translation-delete,
    &.translation-create {
        flex-direction: column;
        margin: 0 0 0 rem-calc(5);
        padding: 0;
        color: $color-green;
        width: rem-calc(85);
        min-width: rem-calc(85);
        overflow: hidden;
        align-self: center;
        &:disabled {
            color: $color-gray;
        }
        .icon {
            margin: 0;
        }
    }
    &.translation-delete {
        color: $color-red;
    }
}

.translation-header-row {
    display: flex;
    padding: rem-calc(15) 0;
    border-top: rem-calc(1) solid  $color-gray-50;
    background-color:  $color-gray-30;
    & > div {
        padding-left: rem-calc(10);
        overflow: hidden;
        flex: 1 1 0;
    }
}

.translation-key {
    width: rem-calc(140);
    padding-right: rem-calc(20);
    min-width: rem-calc(140);
    overflow-wrap: break-word;
    padding-top: rem-calc(10);
}

.translation-group {
    padding-right: rem-calc(40);
}