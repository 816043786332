$angle-edge: 1;
$angle-edge-sm: 1.5;
$max-width: 1366px;
$spacer-large: 5.625rem;
$smaller-font-size: 1rem; // 16px
$small-font-size: .875rem; // 14px

.c-footer {
  max-width: $max-width;
  overflow: hidden;
  margin: 2rem auto 1.75rem;
  line-height: 1.667;

  @include responsive_min(lg) {
    margin: 2rem auto 1.75rem;
  }

  // grid
  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 9vw;
    padding-right: 9vw;
    
    @include responsive_min(md) {
      padding-right: 15px;
      padding-left: 15px;
    }

    &--fixed {
      max-width: rem-calc(1200);
    }

    & > hr {

      @include responsive_max(md) {
        margin-right: -9vw;
        margin-left: -9vw;
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -9vw;
    margin-left: -9vw;

    @include responsive_min(md) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  .col {
    position: relative;
    width: 100%;
    min-height: 1px;
    max-width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    padding-left: 9vw;
    padding-right: 9vw;

    @include responsive_max(md) {
      flex: 0 0 100%;
    }
    
    @include responsive_min(md) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  a {
    transition: all .15s;

    &:hover {
      text-decoration: underline;
    }
  }

  hr {
    margin: 0 0 2.5rem;

    @include responsive_min(md) {
      margin-bottom: 3rem;
    }
  }

  ul {
    @include reset-list($margin: 0 0 rem-calc(16));
  }
  
  // menu
  &__menu {
    display: none;
    @include angle-edge($angle-edge, both, left, right);
    padding: 4.625rem $spacer-large 4.625rem;
    background-color: $primary;
    color: $color-white;
    z-index: 1;

    @include responsive_min(lg) {
      display: flex;
    }

    li {
      margin-bottom: .5rem;
      font-size: $smaller-font-size;

      &.c-footer__menu-first-level {
        position: relative;
        margin-bottom: 1rem;
        font-weight: bold;
        min-height: 3rem; // 2x row height (1.5)

        span {
          position: absolute;
          bottom: 0;
        }
        
        a {
          color: $color-white;
          font-size: $smaller-font-size;
        }
      }

      &:not(.c-footer__menu-first-level) {

        a {
          color: $secondary;
          font-size: $small-font-size;

          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }

  &__middle {
    margin-bottom: 1rem;
    font-size: $small-font-size;
    margin-top: -0.75rem;

    @include responsive_min(lg) {
      margin-top: 2rem;
      font-size: $smaller-font-size; // 1rem
      margin-bottom: 1rem;
    }

    &-divider {
      margin-top: 2.5rem;

      @include responsive_min(lg) {
        display: none;
      }

      hr {
        margin-bottom: rem-calc(25);
      }
    }

    &-title {
      margin-bottom: 1.25rem;
      margin-top: 1.5rem;
      font-size: rem-calc(20); // 20px
      line-height: 1.3em;
      color: $primary;

      @include responsive_min(lg) {
        margin-top: 0;
        line-height: 1.5em;
      }
    }

    ul {

      li {

        &:not(:last-child) {
          margin-bottom: .75rem; // 20px
        }
      }

      &.links {

        li {
          padding-top: .2em;
        }
      }
    }

    .icon {
      width: rem-calc(28);
      margin-right: .75rem;
      font-size: 1.75rem; // 28px
      color: $secondary;
      vertical-align: middle;
      text-align: center;
    }

    a {
      color: $color-blue-10;

      &:hover {
        color: #196a95;
      }
    }

    .col {

      @include responsive_min(md) {
        flex: 0 0 25%;
        max-width: 25%;
        margin-left: 8.33333%;
      }
    }
  }

  &__bottom {
    font-size: $small-font-size;

    @include responsive_min(lg) {
      font-size: $smaller-font-size;
    }

    a {
      color: $color-blue-10;

      &:hover {
        color: #196a95;
      }
    }

    .col {

      @include responsive_min(md) {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
        margin-left: 8.33333%;
      }
    }
  }
}
