@import "../../helpers/functions";
// Default styles
$mdc-top-app-bar-row-height: 0;
// $mdc-top-app-bar-title-left-padding: 20px !default;
// $mdc-top-app-bar-section-vertical-padding: 8px !default;
// $mdc-top-app-bar-section-horizontal-padding: 12px !default;

// $mdc-top-app-bar-icon-padding: 12px !default;
// $mdc-top-app-bar-icon-size: 24px !default;

$mdc-top-app-bar-mobile-breakpoint: 992px;

// Default mobile styles
$mdc-top-app-bar-mobile-row-height: 40px;
$mdc-top-app-bar-mobile-section-padding: 0;

// Short top app bar
// $mdc-top-app-bar-short-collapsed-border-radius: 4px !default;
// $mdc-top-app-bar-short-collapsed-width: 56px !default;
// $mdc-top-app-bar-short-collapsed-right-icon-padding: 12px !default;

// Prominent styles
// $mdc-top-app-bar-prominent-row-height: 128px !default;
// $mdc-top-app-bar-prominent-title-bottom-padding: 2px !default;

// Prominent mobile styles
// $mdc-top-app-bar-prominent-mobile-title-bottom-padding: 6px !default;

// Dense styles
// $mdc-top-app-bar-dense-row-height: 48px !default;
// $mdc-top-app-bar-dense-section-horizontal-padding: 4px !default;
// $mdc-top-app-bar-dense-title-left-padding: 12px !default;

// Dense & Prominent styles
// $mdc-top-app-bar-dense-prominent-title-bottom-padding: 9px !default;

$top-app-bar-bg-transform: skewY(-2deg);
$top-app-bar-padding: rem-calc(18) rem-calc(20) rem-calc(22) rem-calc(24);

.mdc-top-app-bar {
  @include theme() {
    background-color: t($t-color-secondary);
  }
  padding: $top-app-bar-padding;
  z-index: 101;


  @include responsive_min(md) {
    display: none;
  }



  &__title {
    padding-left: 0;
    height: $mdc-top-app-bar-mobile-row-height;

    .logo {
      padding: 0;
      display: inline-block;

      img {
        width: auto;
        height: $mdc-top-app-bar-mobile-row-height;
      }
    }
  }

  &__action-item {
    &.top-app-user-mobile {
      padding: rem-calc(5);
    }

    .icon2.icon-user-mobile {
      font-size: rem-calc(35);
      line-height: 1;
      padding: rem-calc(1) rem-calc(5) 0 rem-calc(10);
    }

    .icon,
    .icon2 {
      font-size: rem-calc(24);
      @include theme() {
        color: t($t-color-primary);
      }
    }

    &.active {
      .icon {
        color: $color-blue-10;
      }
    }
  }

  .mdc-list--links {
    border-bottom: 1px solid $secondary;
  }
}