$mdc-text-field-label: $color-black;
$mdc-text-field-background: $color-white;
$mdc-text-field-outlined-idle-border: $color-gray;
$mdc-textarea-border: $color-gray;
$mdc-text-field-height: 48px;
$mdc-text-field-helper-color: $color-gray-10;

div.mdc-text-field {
  display: flex;
  width: 100%;

  @at-root .mdc-dialog__content .mdc-text-field {
    display: inline-flex;
  }

  @include responsive_max(sm) {
    &:not(.mdc-text-field--textarea) {
      height: rem-calc(48);
    }
  }

  // add space for the absoluted label
  &.mdc-text-field--outlined {
    margin-top: rem-calc(26);

    &.dropped-label {
      margin-top: rem-calc(65);

      @include responsive_min(sm) {
        margin-top: rem-calc(71);
      }
    }

    .mdc-text-field__input {
      font-size: rem-calc(16);

      &:-webkit-autofill {
        z-index: 1 !important;
        border: rem-calc(2) solid $mdc-textarea-border !important;
      }

      &.mdc-text-field--invalid {
        &:-webkit-autofill {
          border: rem-calc(2) solid $mdc-theme-error !important;
        }
      }

      @include responsive_min(sm) {
        font-size: rem-calc(16);
      }

      @include placeholder {
        opacity: 1;
        color: $color-gray-80;
      }
    }
  }

  // field without label
  &.mdc-text-field--no-label {
    margin-top: rem-calc(0);

    @include responsive_min(sm) {
      margin-top: rem-calc(0);
    }
  }

  &.mdc-text-field--light {

    .mdc-floating-label {
      font-weight: 600;
    }
  }

  &.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input {

    @include responsive_max(sm) {
      padding-right: rem-calc(40);
    }
  }

  &:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: $color-gray-10;
  }

  // size modification
  &.short {

    @include responsive_min(sm) {
      max-width: rem-calc(180);
    }
  }

  &.narrow {

    @include responsive_min(sm) {
      max-width: rem-calc(470);
    }
  }

  // without border
  &.mdc-text-field--no-border:not(.mdc-text-field--disabled) {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $color-white !important;
    }
  }

  &.mdc-text-field--no-border-readonly {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $color-white !important;
    }

    input {
      color: #222;
      font-weight: bold;
    }
  }

  // invalid state
  &.mdc-text-field--invalid:not(.mdc-text-field--disabled) {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $color-red-10;
    }
  }

  &.override-invalid-state {
    border: rem-calc(2) solid $mdc-textarea-border !important;
  }

  // disabled state
  &.mdc-text-field--disabled {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $color-gray-30;
    }
  }

  &.mdc-text-field--phone-input input {
    padding-left: rem-calc(65) !important;
  }

  &.mdc-text-field--long-desc {
    margin-top: rem-calc(50) !important;
  }

  &.mdc-text-field--phone-input i {

    font-size: rem-calc(16);
  }

  &--coop-card-number {
    &.mdc-text-field--with-leading-icon.mdc-text-field--outlined {

      .mdc-text-field__input {
        padding-left: rem-calc(144);
      }

      @include responsive_max(sm) {
        .mdc-text-field__input {
          padding-left: rem-calc(132);
        }

        .mdc-text-field__icon {
          font-size: rem-calc(18);
          top: rem-calc(14);
        }
      }
    }
  }
}





// trayling icon
.mdc-text-field__icon {
  right: rem-calc(12) !important;
  bottom: rem-calc(15) !important;
  font-size: rem-calc(24);
  line-height: 1;

  @include responsive_max(sm) {
    &.icon-calendar {
      bottom: rem-calc(13) !important;
    }
  }

  @include responsive_min(sm) {
    right: rem-calc(15) !important;
    bottom: rem-calc(19) !important;
  }

  &--text {
    bottom: rem-calc(17) !important;
    font-size: rem-calc(16);
    color: $color-gray-80;

    @include responsive_min(sm) {
      bottom: rem-calc(22) !important;
      font-size: rem-calc(18);
    }
  }
}

.mdc-notched-outline {

  &__leading,
  &__notch,
  &__trailing {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
    background-color: $color-white;
  }

  &__leading {
    border-left-width: 2px !important;
  }

  &__trailing {
    border-right-width: 2px !important;
  }
}

// helper text
.field~.mdc-text-field-helper-text,
.field~.mdc-text-field-helper-line .mdc-text-field-helper-text:not([role="alert"]),
.field~.mdc-checkbox-helper-text,
.field~.mdc-checkbox-helper-line .mdc-checkbox-helper-text:not([role="alert"]),
.form-field-group__item>.mdc-text-field-helper-text {
  color: $mdc-text-field-helper-color;

  // set position with form fields
  margin-top: rem-calc(4) !important;
  margin-left: rem-calc(47) !important;

  p {
    margin-top: rem-calc(15);
  }
}

.mdc-text-field-helper-text:empty {
  margin: 0;
}

.field~.mdc-text-field-covert-text {
  color: $color-gray-80;
  margin-top: 0 !important;
  margin-left: rem-calc(47) !important;
}

.mdc-text-field--with-counter {
  position: relative;

  .counter {
    position: absolute;
    top: 0;
    right: 0;
    font-size: rem-calc(14);
    line-height: rem-calc(26);
    font-weight: 700;

    &+.mdc-text-field {
      display: inline-flex;
    }
  }
}