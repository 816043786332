@import "../helpers/variables";
@import "../helpers/functions";
@import "../helpers/mixins";
@import "../base/typography";


.unsubscribe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $secondary;
  text-align: center;
  padding: rem-calc(15);
  overflow-y: auto;

  &__logo {
    margin: 0 auto 0;
    width: 100%;
    max-width: rem-calc(300);
  }

  &__notice {
    max-width: rem-calc(500);
    margin: rem-calc(50) auto 0;
  }

  &__terms {
    color: $color-white;
    max-width: rem-calc(600);
    margin: rem-calc(50) auto 0;
    padding: 0 rem-calc(15);
  }

  &__limiter {
    border-top: 2px solid $color-white;
    padding-top: rem-calc(50);
  }
  

  &__language {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .mdc-button {
      color: $color-white;
    }
  }
}