.pager {
  margin-top: rem-calc(45);
  font-size: rem-calc(18);
  line-height: rem-calc(40);
  display: flex;
  justify-content: space-between;
  
  @include theme() {
    color: t($t-color-primary);
  }

  @include responsive_max(sm) {
    flex-direction: column;
    align-items: center;
  }

  .page:not(.active) {
    @include responsive_max(sm) {
        display: none;
    }
  }

  ul {
    display: flex;
  }

  li {
    &:not(.previous):not(.next) {

      a {
        display: block;
        width: rem-calc(40);
      }
    }

    &.previous,
    &.next {

      a {
        @include clearfix;
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
      }
    }

    &.active {
      font-weight: 700;
      @include theme() {
        color: t($t-color-secondary);
      }
    }

    &.disabled {
      color: $color-gray;

      a {
        cursor: default;
        opacity: 1 !important;
      }
    }

    &:not(.disabled):not(.active) {
      a:hover,
      a:focus {        
        .icon {
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
          &.icon-arrow-left {
            animation-name: icon-arrow-left--wobble;
            &.rotate {
              animation-name: icon-arrow-right--wobble;
            }
          }
        }
      }
    }
  }

  a {
    display: block;
    color: inherit;
    text-align: center;
    text-decoration: none;
    transition: all .15s;
  }

  .icon {
    font-size: rem-calc(14);
    line-height: inherit;
  }
}


