@import "../helpers/variables";

.container {
    padding: 50px 300px;

    @include responsive_max(lg) {
        padding: 50px 50px;
    }

    @include responsive_max(sm) {
        padding: 50px 20px;
    }
}

.order-form-title {
    margin-bottom: 40px;
}

.form-fields-list {

    &__radio-item {
        margin-bottom: 20px;
    }

    &__text-field {
        margin-bottom: 60px;

        &.phone {
            max-width: 300px;
        }

        &.fix-margin {
            margin-bottom: 25px;
        }

        textarea {
            min-height: 45px;
        }
    }
}

.form-fields-sublist {
    margin-left: 30px;

    &__radio-item {
        margin-bottom: 20px;
    }
}

.field-required {
    color: $color-red;
}

.field-sub-text {
    font-size: 15px;
    color: gray;
}

.label-has-sub-text {

    &.mdc-text-field.mdc-text-field--outlined {
        margin-top: 3.125rem;
    }

    .mdc-text-field__input:required~.mdc-floating-label,
    .mdc-text-field__input:required~.mdc-notched-outline .mdc-floating-label {
        $label: &;

        overflow: initial;

        &::after {
            display: none;
        }

        .text {

            &::after {
                margin-left: 1px;
                content: "*";
            }
        }

        .sub-text {
            font-size: 15px;
            color: gray;
            line-height: 1rem;
            font-weight: normal;
            display: block;
        }
    }


    &.mdc-text-field--invalid .text::after {
        color: var(--mdc-theme-error, $color-red);
    }
}

.base-margin-bottom {
    margin-bottom: 30px;
}

.submit-btn-container {
    text-align: end;
}

.alert-message {

    &__error {
        margin-bottom: 20px;
    }
}