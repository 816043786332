@import "../helpers/_functions";
@import "../helpers/_variables";


/*
* This used to be called .action-banner, but because it seems that Alexela wants to use it as more general alert messages too, 
* it is now simply Alert component (with class .alert).
* There is still the old AlertMessage component which uses .message classes
*/

.alert {
    $self: &;

    display: flex;
    flex: 1 0 100%;
    flex-direction: column;

    padding: rem-calc(24);
    border-radius: rem-calc(8);

    @include theme() {
        background-color: t($t-color-background);
    }

    &__image {
        align-self: center;
        flex: 0 0 auto;
    }

    &__title {
        font-size: rem-calc(24);
        line-height: rem-calc(32);
        font-weight: bold;
        text-align: center;
        margin-top: rem-calc(8);
        color: $color-primary;
    }

    &__action-button {
        margin-top: rem-calc(24);

        @include theme() {
            background-color: t($t-color-link) !important;
        }
    }


    @include responsive_max(md) {
        &--mobile-small {

            padding: rem-calc(16);

            #{$self}__image {
                padding-bottom: rem-calc(10);
            }

            #{$self}__title {
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                margin-top: 0;
            }

            #{$self}__action-button {
                margin-top: rem-calc(16);
                height: rem-calc(32);
                font-size: rem-calc(12);
            }

            .icon {
                padding: 0 rem-calc(10) rem-calc(10) !important;
            }
        }
    }

    @include responsive_min(lg) {
        flex-direction: row;

        &__image {
            align-self: flex-start;
            max-width: 15%;
        }

        &__title {
            margin-top: 0;
            margin-left: rem-calc(24);
            align-self: center;
            flex-grow: 1;
            text-align: left;
            font-size: rem-calc(20);
        }

        &__action-button {
            align-self: center;
        }
    }

    &--discount {
        display: flex;
        flex-shrink: 1;
        flex-direction: row;
        padding: rem-calc(8) rem-calc(16);
        justify-content: space-between;
        align-items: center;
        color: $color-primary;

        #{$self}__title {
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            text-align: left;
            flex: 1 1 100%;
            padding-right: rem-calc(8);
        }

        #{$self}__content {
            font-size: rem-calc(32);
            line-height: rem-calc(40);
            font-weight: bold;
            flex: 1 0 0;
        }

        @media (max-width: 360px) {
            #{$self}__title {
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                padding-right: rem-calc(4);
            }

            #{$self}__content {
                font-size: rem-calc(24);
                line-height: rem-calc(30);
            }
        }
    }

    &--green {
        background-color: transparent !important;
        border: 2px solid $color-green;
        color: $color-green;
    }

    &--yellow {
        background-color: $color-orange-50 !important;

        #{$self}__action-button {
            background-color: $color-orange-40 !important;

            @include responsive_min(lg) {
                margin: 0;
            }
        }
    }

    .icon {
        display: flex;
        align-self: center;
        font-size: rem-calc(40);
        padding: 0 rem-calc(10);
    }

    &--child-right {
        @include responsive_min(sm) {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(-50%, 75%);
        }
    }
}