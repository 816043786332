@import "../base/typography";
@import "../helpers/mixins";
@import "../helpers/functions";
@import "../helpers/variables";

.overview-campaign {
  &-banner {
    /* Auto resize background image */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    height: 106px;

    &-image {
      &--et {
        background-image: url('../../images/Alexela_Suvekampaania2024_Digi_Mob_327x106px-EST@2x.png');
      }

      &--en {
        background-image: url('../../images/Alexela_Suvekampaania2024_Digi_Mob_327x106px-ENG@2x.png');
      }

      &--ru {
        background-image: url('../../images/Alexela_Suvekampaania2024_Digi_Mob_327x106px-RU@2x.png');
      }
    }
  }
}