@import "../helpers/functions";
@import "../helpers/variables";
@import "../base/typography";

$input-padding: rem-calc(12);
$border-radius: 4px;
$item-font-size: rem-calc(16);

input {
    width: 100%;
    padding: $input-padding;
    border: 2px solid $color-gray;
    border-radius: $border-radius;
    font-family: "Open Sans", sans-serif;
    font-size: $item-font-size;
}

input:hover {
    border-color: $color-gray-10;
}

label {
    font-size: rem-calc(14);
    font-weight: bold;
}

.pac-container {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.pac-icon {
    display: none;
}

.pac-item {
    padding: $input-padding;
    font-size: $item-font-size;
}

.pac-item-query {
    font-size: $item-font-size;
}