@import "../helpers/variables";

.card-dialog {
    & .delete {
        color: $color-red;

        &::before {
            margin-right: rem-calc(6);
        }
    }
}