$loader-background-color: $color-white;

.loader {
  position: relative;

  &:after {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    z-index: 1;
  }

  .lds-roller {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .loader__title {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: rem-calc(125);
    text-align: center;
    transform: translate(-50%, -50%);
    z-index: 2;

    @include responsive_min(sm) {
      margin-top: rem-calc(115);
    }
  }

  // block
  &--block {
    user-select: none;

    &.loader--body {

      &:before {
        background-color: rgba($loader-background-color, .9);
        z-index: 2;
      }

      &:after,
      .lds-roller,
      .loader__title {
        top: rem-calc(300);

        @include responsive_min(sm) {
          top: rem-calc(480);
        }

        @media (orientation: landscape) {
          @include responsive_max(lg) {
            top: rem-calc(300);
          }

          @include responsive_max(sm) {
            top: rem-calc(140);
          }
        }
      }

      &:after {
        z-index: 3;
      }

      .lds-roller,
      .loader__title {
        z-index: 110;
      }
    }

    &:before {
      display: block !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($loader-background-color, .6);
      content: '';
      z-index: 1;
    }

    &:after {
      display: block !important;
      top: 50%;
      left: 50%;
      width: rem-calc(120);
      height: rem-calc(120);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: $loader-background-color;
    }
  }

  // button
  &--button {
    display: inline-block;
    margin-top: rem-calc(40);

    .mdc-button {
      margin-top: 0;
      cursor: default;

      &:after {
        display: block;
        border-radius: 0;
        background-color: $color-gray-30 !important;
        opacity: 1 !important;
      }
    }

    .lds-roller {
      transform: translate(-50%, -50%) scale(0.6);
    }
  }

  // textfield
  &--textfield {
    display: inline-block;
    margin-top: rem-calc(34);

    @include responsive_min(sm) {
      margin-top: rem-calc(40);
    }

    .mdc-text-field {
      margin-top: 0 !important;
    }

    .lds-roller {
      top: 50%;
      left: auto;
      right: rem-calc(10);
      transform: translate(0, -50%) scale(0.5);
    }
  }

  // select
  &--select {
    margin-top: rem-calc(34);

    @include responsive_min(sm) {
      margin-top: rem-calc(40);
    }

    .mdc-select {
      margin-top: 0 !important;
    }

    .lds-roller {
      top: 50%;
      left: auto;
      right: rem-calc(45);
      transform: translate(0, -50%) scale(0.5);
    }
  }

  // theme modificators
  &--light {

    .lds-roller {

      div:after {
        background-color: $secondary;
      }
    }
  }

  &--dark {

    .lds-roller {

      div:after {
        border: 1px solid $primary;
        background-color: $secondary;
      }
    }
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  &--xs {
    transform: scale(0.25);
    width: 24px;
    height: 24px;
  }

  &--small {
    transform: scale(0.5);
  }

  &--medium {
    transform: scale(0.75);
  }

  &--large {
    transform: scale(1);
  }

  &--inline {
    display: inline-block;
  }

  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #fff;
      margin: -3px 0 0 -3px;
    }

    &:nth-child(1) {
      animation-delay: -0.036s;
    }

    &:nth-child(1):after {
      top: 50px;
      left: 50px;
    }

    &:nth-child(2) {
      animation-delay: -0.072s;
    }

    &:nth-child(2):after {
      top: 54px;
      left: 45px;
    }

    &:nth-child(3) {
      animation-delay: -0.108s;
    }

    &:nth-child(3):after {
      top: 57px;
      left: 39px;
    }

    &:nth-child(4) {
      animation-delay: -0.144s;
    }

    &:nth-child(4):after {
      top: 58px;
      left: 32px;
    }

    &:nth-child(5) {
      animation-delay: -0.18s;
    }

    &:nth-child(5):after {
      top: 57px;
      left: 25px;
    }

    &:nth-child(6) {
      animation-delay: -0.216s;
    }

    &:nth-child(6):after {
      top: 54px;
      left: 19px;
    }

    &:nth-child(7) {
      animation-delay: -0.252s;
    }

    &:nth-child(7):after {
      top: 50px;
      left: 14px;
    }

    &:nth-child(8) {
      animation-delay: -0.288s;
    }

    &:nth-child(8):after {
      top: 45px;
      left: 10px;
    }
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}