$table-color: $color-gray-10;
$table-disabled-color: $color-gray-80;
$table-link-color: $color-green;
$table-border: 1px solid $color-gray-20;

.table {
  width: 100%;
  font-size: rem-calc(18);
  line-height: 1.667em; // 30px
  color: $table-color;

  @at-root div#{&} {
    width: auto;
  }

  @include responsive_max(sm) {
    font-size: rem-calc(16);
  }

  &__wrapper {
    margin-bottom: rem-calc(16);
    padding-right: rem-calc(33);
  }

  // for the mobile
  &-overflow {

    @include responsive_max(sm) {
      position: relative;
      max-width: 100vw;
      margin-left: rem-calc(-24);
      margin-right: rem-calc(-24);
      overflow: hidden;
      @include style-scrollbar;

      &:before,
      &:after {
        display: none;
        content: '';
        width: rem-calc(60);
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 5;
      }

      &.left-side-light {
        &:before {
          display: block;
          left: 0;
          background-image: linear-gradient(-90deg, rgba($color-black, 0) 0%, rgba($color-black, .1) 100%);
        }
      }

      &.right-side-light {
        &:after {
          display: block;
          right: 0;
          background-image: linear-gradient(90deg, rgba($color-black, 0) 0%, rgba($color-black, .1) 100%);
        }
      }

      &__inner {
        overflow: auto;
      }
    }
  }

  // for the mobile
  &-wrapper {
    @include responsive_max(sm) {
      margin-left: rem-calc(-16);
      margin-right: rem-calc(-16);
    }
  }

  thead {

    th {
      text-align: left;
      padding-left: rem-calc(24);

      @include responsive_min(lg) {
        padding-left: rem-calc(14);
      }

      &.wide {
        width: 50%;
      }

      &.narrow {
        width: 22%;
      }

      &.normal {
        width: 27%;
      }

      &.w-12 {
        width: 12%;
      }

      &.w-14 {
        width: 14%;
      }

      &.w-15 {
        width: 15%;
      }

      &.w-18 {
        width: 18%;
      }

      &.w-20 {
        width: 20%;
      }

      &.w-21 {
        width: 21%;
      }

      &.w-25 {
        width: 25%;
      }

      &.w-30 {
        width: 30%;
      }

      &.w-34 {
        width: 34%;
      }

      &.w-35 {
        width: 35%;
      }

      &.pull-right {
        padding-right: rem-calc(30);
      }

      &.pull-left {
        padding-left: rem-calc(30);
      }
    }
  }

  tfoot {

    td {
      padding: rem-calc(30) rem-calc(28) rem-calc(0);

      &.pull-right {

        @include responsive_min(sm) {
          padding-right: rem-calc(30);
        }
      }

      &.pull-left {

        @include responsive_min(sm) {
          padding-left: rem-calc(30);
        }
      }
    }

    &.narrow {

      td {
        padding: rem-calc(20) rem-calc(15) 0;
      }
    }
  }

  tbody {

    // row
    tr {
      border-bottom: $table-border;

      &:first-child {
        border-top: $table-border;
      }

      // disabled colors
      &.disabled {
        color: $table-disabled-color;

        h4,
        .main-content {
          color: inherit !important;
        }
      }

      // primary
      &.primary {

        a {
          color: $color-blue-10;
          transition: color .15s;

          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }

      // hidden state
      &.hidden {
        display: none;
      }

      &.open {
        display: flex;

        @include responsive_min(sm) {
          display: table-row !important;
        }
      }
    }

    td {
      padding: rem-calc(14) rem-calc(24);

      @include responsive_min(lg) {
        padding: rem-calc(20) rem-calc(14) rem-calc(19);
        font-size: rem-calc(18);
      }

      &:not(.wrap) {
        white-space: nowrap;

        @include responsive_min(sm) {
          @include responsive_max(md) {
            white-space: normal;
          }
        }
      }

      &.wide {
        width: 50%;
      }

      &.w-35 {
        width: 35%;
      }

      &.w-37 {
        width: 37%;
      }

      &.w-25 {
        width: 25%;
      }

      &.w-12 {
        width: 12%;
      }

      &.w-21 {
        width: 21%;
      }

      &.pull-right {

        @include responsive_min(sm) {
          padding-right: rem-calc(30);
        }
      }

      &.pull-left {

        @include responsive_min(sm) {
          padding-left: rem-calc(30);
        }
      }

      &.action-button {

        @include responsive_max(sm) {
          position: absolute;
          top: rem-calc(16);
          right: rem-calc(12);
        }

        @include responsive_min(sm) {
          padding: rem-calc(9) 0 !important;
          width: rem-calc(48);
        }
      }

      .main-content {
        font-size: rem-calc(16);
        line-height: 1.444em; // 26px

        @include responsive_min(sm) {
          font-size: rem-calc(20);
          line-height: 1.5em; // 30px
        }
      }

      &.secondary-content {

        @include responsive_max(sm) {
          font-size: rem-calc(16) !important;
          line-height: 1.75em; // 28px
        }

        &.other {

          p {
            display: inline-block;
          }
        }
      }
    }

    a {
      color: $table-link-color;
    }
  }

  // light modificate (for the chart content)
  &--light {
    width: 100%;
    font-size: rem-calc(13);
    line-height: 1.2308em; // 16px
    color: $table-color;

    th {
      padding: rem-calc(15) rem-calc(28) rem-calc(14);
      background-color: #f5f6fa;
      color: #a3a6b4;
      text-align: left;

      &.w-30 {
        width: 30%;
      }

      &.w-21 {
        width: 21%;
      }

      &.w-16 {
        width: 16%;
      }

      &.w-11 {
        width: 11%;
      }
    }

    td {
      padding: rem-calc(19) rem-calc(28) rem-calc(16) !important;
      color: #4d4f5c;
    }

    tbody {

      // row
      tr {
        border-bottom: 1px solid #f1f1f3;

        &:first-child {

          td {
            padding-top: rem-calc(18);
          }
        }

        td {
          font-size: rem-calc(13);
        }
      }
    }

    tfoot {
      td {
        padding: rem-calc(15) rem-calc(28) rem-calc(14);
        background-color: #f5f6fa;
        color: #a3a6b4;
        text-align: left;
      }
    }

    @include responsive_max(sm) {
      thead {
        th {
          padding: rem-calc(15) rem-calc(10) rem-calc(14);
        }
      }

      td {
        padding: rem-calc(14) rem-calc(10) rem-calc(14) !important;
      }
    }

    @media (max-width: 400px) {
      th {
        word-break: break-all;
      }
    }
  }

  // primary modificate (for the invoices pages)
  &--primary {

    thead {

      tr {
        &:not(.disabled) {
          border-bottom: 1px solid $secondary;
          background-color: #f2fcff;
        }

        &.disabled {
          border-bottom: 1px solid $color-gray-20;
          background-color: #f5f5f5;
          color: $color-gray-80;
        }

        // light colors
        &.light {

          th {
            font-weight: 400;
          }
        }
      }

      th {
        padding: rem-calc(15) rem-calc(24) rem-calc(16);
        font-weight: 600;
        white-space: nowrap;

        @include responsive_min(md) {
          padding: rem-calc(14) rem-calc(15);
        }

        &:not(.text-right) {
          text-align: left;
        }
      }
    }

    tbody {

      tr:first-child {
        border-top: 0 none;
      }
    }

    small {
      font-size: rem-calc(14);
    }
  }

  // list modificate (for the page contracts)
  &--list {
    @include responsive_max(md) {

      tbody {

        td {
          flex: 1 100%;
          width: auto !important;
          padding: 0 rem-calc(12) !important;

          &.last {
            flex: 0 auto;
          }
        }
      }

      tr {
        display: flex;
        flex-wrap: wrap;
      }

      .number-label {

        .icon {
          color: inherit !important;
        }
      }
    }

    @include responsive_min(sm) {

      // User settings contact list
      &.left-aligned tbody td {
        padding-left: 0 !important;
      }
    }

    @include responsive_min(sm) {

      // User settings contact list
      &.left-aligned tbody td:first-child {
        padding-left: 0 !important;
      }

      @include responsive_max(md) {
        &.cards {
          tr {
            display: table-row;
          }

          td {
            font-size: rem-calc(15);
          }
        }
      }
    }

    tbody {

      td {

        &.flip {
          padding-right: 0 !important;

          @include responsive_max(sm) {
            flex: 0 0 rem-calc(125);
          }

          @media (max-width: 360px) {
            display: none;
          }

          @include responsive_min(sm) {
            width: rem-calc(145);
            padding-top: rem-calc(20) !important;
            padding-bottom: rem-calc(20) !important;
          }

          img {
            max-width: rem-calc(120);
            width: 100%;
          }
        }

        @include responsive_max(sm) {
          &.card-info {
            flex: 1 auto;
            align-self: center;
          }

          &.card-actions {
            display: flex;
            flex-direction: row;
            align-self: center;

            div,
            button {
              margin-right: rem-calc(10);
            }

            &--col {
              flex-direction: column;

              div,
              button {
                margin-right: 0;
                margin-top: rem-calc(10);
              }
            }
          }

          p {
            display: block;
            font-size: 16px;
            padding-bottom: rem-calc(4);

            &:before {
              padding-right: rem-calc(10);
            }

            &.icon-active:before {
              font-size: rem-calc(15);

            }

            &.icon-card:before {
              padding-right: rem-calc(12);
            }

            &.icon-calendar-numbered:before {
              font-size: rem-calc(20);
              padding-right: rem-calc(12);
              padding-left: rem-calc(1);
            }
          }
        }

      }
    }
  }

  // modificate (for the page invoice item)
  &--wrapped {

    @include responsive_max(sm) {

      thead {
        display: none;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: rem-calc(15);
        padding-bottom: rem-calc(15);
      }

      tbody {

        td {
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          &[data-title] {
            text-align: right;

            &:before {
              float: left;
              content: attr(data-title);
              text-align: left;
            }
          }
        }
      }

      tfoot {

        td {
          padding: rem-calc(5) rem-calc(24) rem-calc(5);

          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  // modificate (for the page invoices page)
  &--accordion {

    @include responsive_min(sm) {
      @include responsive_max(md) {
        font-size: rem-calc(14);

        th,
        td {
          padding: rem-calc(13) !important;
        }

        tr:not(.light) {

          th:last-child {
            min-width: rem-calc(160);
          }
        }


        td.action {
          white-space: nowrap !important;
        }
      }
    }

    @include responsive_max(sm) {

      tr {
        display: flex;
        flex-wrap: wrap;
        padding: rem-calc(15) rem-calc(24) rem-calc(14);

        &.open {

          &:not(.light) {
            background-color: #f7f9fa;
          }

          td {
            flex: 1 100%;

            &.primary {
              padding-bottom: rem-calc(15) !important;
            }

            &.action {
              flex: 1 50%;
              padding: rem-calc(15) 0 !important;
              align-self: center;

              &:last-child {
                text-align: right;
              }
            }

            &.secondary {
              padding-top: rem-calc(10) !important;
            }
          }

          &.light {
            position: relative;

            td.primary {
              padding-right: rem-calc(40) !important;
              padding-bottom: rem-calc(10) !important;
            }
          }

          .amount {
            margin: 0 !important;
          }

          .previous {
            float: left;
            max-width: 65%;
          }

          .last {
            float: right;
            max-width: 35%;
          }
        }

        &:not(.open) {

          .toggle-hidden {
            display: none;
          }
        }

        .amount {
          float: right;
          min-width: inherit;
          margin: 0;
        }
      }

      thead {

        tr {
          flex-flow: column nowrap;
        }

        th {
          width: auto !important;
          padding: 0;
          font-size: rem-calc(14);
          line-height: 1.5714em; // 22px
          text-align: left;

          &:first-child {
            font-size: rem-calc(16);
            line-height: 1.625em; // 26px
          }
        }
      }

      tbody {

        tr.light {
          font-size: rem-calc(14);
        }

        td {
          flex: 0 0 auto;
          padding: 0 !important;
          text-align: left;

          &:first-child {
            flex: 1 100%;
          }

          &.primary {
            position: relative;
          }

          .amount {
            margin-right: rem-calc(15);
          }
        }
      }

      tfoot {

        tr.light {

          &:not(:last-child) {
            padding-bottom: 0;
          }

          td {
            padding: 0;
            flex: auto;
          }
        }
      }

      .arrow-link {
        display: block !important;
      }

      .label-icon {
        // position: relative;
        padding-left: 0 !important;
        // padding-left: rem-calc(28);

        .icon {
          position: static !important;
          top: auto !important;
          left: auto !important;
          float: left;
          margin-right: rem-calc(10);
        }

        .toggle-hidden {
          clear: left
        }
      }
    }

    tr.open.light {

      .toggle-hidden {

        @include responsive_min(sm) {
          display: none;
        }
      }
    }

    // Override some previous styles
    @include responsive_max(sm) {
      tbody {
        tr {
          padding: rem-calc(12);

          td.primary {
            flex: 1 0 0;
          }

          .amount {
            margin-right: 0;

            &.total {
              font-style: italic;
            }

            &.to-be-paid {
              font-weight: bold;
            }
          }

        }
      }
    }
  }

  &--sticky-header {
    th {
      position: sticky;
      top: 0;

      // @include responsive_max(md) {
      //   // 80px is mobile nav menu height
      //   top: rem-calc(80);
      // }
    }
  }

  &--sticky-footer {
    tfoot {

      td {
        position: sticky;
        bottom: 0;

        // @include responsive_max(md) {
        //   // 68px is mobile nav menu height
        //   bottom: rem-calc(68);
        // }
      }
    }
  }

  @each $width in (2, 3, 4, 5, 6, 7) {
    &--#{$width}-columns {

      td,
      th {
        width: 100%/$width;
      }
    }
  }

  // modification for transactions page
  &--transactions {
    & th, td {
      font-size: rem-calc(16) !important;
    }
  }

  // helpers items
  .amount {
    display: inline-block;
    margin-left: rem-calc(10);
    text-align: left;

    @include responsive_min(lg) {
      min-width: rem-calc(130);
    }
  }

  .label-icon {
    position: relative;
    padding-left: rem-calc(28);

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      font-size: rem-calc(16);
      line-height: inherit;
    }
  }

  .number-label {
    font-size: rem-calc(18);

    @include responsive_min(sm) {
      font-size: rem-calc(20);
    }

    .icon {
      height: rem-calc(26);
      margin-right: rem-calc(10);
      font-size: rem-calc(23);
      line-height: inherit;

      @include responsive_min(sm) {
        height: rem-calc(30);
        margin-right: rem-calc(19);
        font-size: rem-calc(32);
      }
    }
  }

  .toggle-link {

    @include responsive_max(sm) {
      display: flex;
      justify-content: space-between;
    }

    .icon {
      height: rem-calc(29);
      margin-top: 1px;
      margin-right: rem-calc(15);
      font-size: rem-calc(13);
      line-height: inherit;

      @include responsive_max(sm) {
        order: 1;
        margin-right: 0;
      }
    }

    // active state
    &.active {

      .icon {
        margin-top: 1px;
        transform: rotate(180deg);
      }
    }

    &>* {
      float: left;
    }
  }

  .arrow-link {
    display: none;
    position: absolute;
    right: -1px;
    top: rem-calc(12);

    &.mdc-icon-button {
      right: 0;
      top: 0;
      color: $secondary;
      font-size: rem-calc(16);
    }

    &:before {
      @include icon-styles();
      font-size: rem-calc(14);
      color: $secondary;
      content: icon-char(angle-down);
      transform-origin: 50% 50%;
    }

    // active state
    &.active {
      margin-top: 1px;
      transform: rotate(180deg);
    }
  }

  // modification for cards view
  &.cards {
    tbody tr {
      padding-bottom: rem-calc(15);

      @include responsive_max(sm) {
        border: none;
        border-radius: rem-calc(8);
        margin-bottom: rem-calc(10);
        padding-top: rem-calc(15);
        box-shadow: 0 0 10px $color-gray-30;
      }

      &.primary-card {
        border: 2px solid $color-green;

        .tooltip-text {
          color: $color-green;
          border: none;
          max-width: rem-calc(225);
          white-space: normal;
        }
      }

      img.card-disabled {
        filter: grayscale(100%);
      }
    }
  }

  // modifications for UserSettings view
  &.table--cards {
    @include responsive_max(sm) {
      tbody tr {
        padding: rem-calc(15) 0;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 rem-calc(10) $color-gray-30;
        border: none;
        margin-bottom: rem-calc(10);
      }

      td {
        &:first-child {
          font-size: 22px;
          padding-bottom: rem-calc(10);
          color: $primary;
        }

        &:before {
          font-size: rem-calc(20);
          padding-right: rem-calc(8);
        }

        display: flex;
        align-items: center;
        padding: 0 rem-calc(15) rem-calc(10);
      }
    }

    @include responsive_min(sm) {
      td {
        display: table-cell;
        vertical-align: middle;

        button {
          display: block;
        }

        &:before {
          content: none;
        }
      }

    }

    .icon-mail:before {
      font-size: rem-calc(16);
      padding-right: rem-calc(8);
    }

    .icon-phone:before {
      font-size: rem-calc(18);
      padding-right: rem-calc(10);
    }
  }

  @include responsive_max(sm) {
    tbody.table--card-body {
      tr {
        padding: rem-calc(15) 0;
        display: flex;
        position: relative;
        flex-direction: column;
        box-shadow: 0 0 rem-calc(10) $color-gray-30;
        border: none;
        margin-bottom: rem-calc(10);
      }

      td {
        padding: 0 rem-calc(15) rem-calc(5);

        &:first-child() {
          font-size: 22px;
          color: $primary;
          padding-right: rem-calc(120);
        }
      }

      .text-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        font-size: rem-calc(22);
        color: $secondary;
      }

      .open .text-right {
        top: rem-calc(15);
        transform: none;
      }

      .table--card-body-info {
        color: $color-gray-80;
      }

      .show-open {
        display: none;
      }

      .open .show-open {
        display: block;
      }

      .icon:before {
        padding-right: rem-calc(5);
      }
    }
  }

  // modification, so show table format in md
  &--scrollable {
    overflow-x: auto;
  }

  &--scrollable-desktop {
    @include responsive_min(sm) {
      overflow-x: auto;
    }
  }

  &.contacts {
    a {
      @include theme() {
        color: t($t-color-link);
      }
    }
  }

  .clickable {
    cursor: pointer;
  }
}