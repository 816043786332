@import "../helpers/variables";
@import "../helpers/functions";
$info-list-bg: $color-blue-30;

.info-list {

  // list item
  &__item {
    
    @include theme() {
      background-color: t($t-color-background);
    }
    display: flex;
    flex-wrap: wrap;
    padding: rem-calc(30) rem-calc(20) rem-calc(28) 0;
    font-size: rem-calc(18);
    line-height: 1.6667em; // 30px
    align-items: center;
    margin-bottom: rem-calc(5);

    @include responsive_min(sm) {
      flex-wrap: nowrap;
    }

    &--message {
      margin: rem-calc(15) rem-calc(30) rem-calc(-28);
      padding: rem-calc(20) 0;
      background-color: $color-white;

      @include responsive_min(sm) {
        margin-left: rem-calc(-15);
        margin-right: rem-calc(-15);
        padding: rem-calc(30) 0;
      }
    }
  }

  // list item title (with/without icons)
  .title {
    font: {
      size: rem-calc(18);
    }
    line-height: 1.444em; // 26px

    @include responsive_min(sm) {
      font-size: rem-calc(20);
      line-height: 1.5em; // 30px
    }

    .icon {
      float: left;
      margin: 0 rem-calc(15) 0 0;
      font-size: rem-calc(32);
      line-height: inherit;
    }
  }

  .wrap {
    margin-left: rem-calc(47);

    @include responsive_max(sm) {
      margin-top: rem-calc(10);
    }

    p {
      margin: rem-calc(10) 0;

      .icon {
        margin-right: rem-calc(8);
        line-height: inherit;
      }
    }

    a {

      &:not(:last-child) {
        margin-right: rem-calc(28);
      }
    }
  }

  a {
    @include theme() {
      color: t($t-color-link);
    }
    text-decoration: none;
    transition: color .15s;

    &:hover,
    &:focus {
      color: $primary;
    }

    &.link {

      @include responsive_min(sm) {
        margin-right: rem-calc(30);
      }
    }
  }

  // column
  .col {

    @include responsive_min(sm) {
      padding: 0 rem-calc(15);
    }

    &:first-child {
      flex: 1 100%;

      @include responsive_max(sm) {
        padding-left: 0;
      }

      @include responsive_min(sm) {
        flex: 1 auto;
      }
    }

    &:not(:first-child) {
      text-align: center;

      @include responsive_max(sm) {
        flex: 1 100%;
        padding-top: rem-calc(15);

        .link {
          float: left;
          margin: 0 0 rem-calc(15) rem-calc(47);
        }
      }
    }
  }

  &:empty {
    display: none;
  }
}

.w-315 {
  width: rem-calc(315);
}