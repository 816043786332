@import "../helpers/functions";
@import "../helpers/variables";

$padding: rem-calc(5);

.inline-tooltip {
    position: relative;

    i {
        display: inline;
    }

    .tooltip-left {
        padding-right: $padding;
    }

    .tooltip-right {
        padding-left: $padding;
    }

    .tooltip-info::before {
        color: $color-gray-10;
    }

    .tooltip-alert::before {
        color: $color-red;
    }

    i::after {
        content: attr(data-tooltip);
        display: inline-block;
        position: absolute;
        transform: scale(0);
        padding: rem-calc(10);
        bottom: 100%;
        width: rem-calc(200);
        font-size: rem-calc(14);
        z-index: 10;
        background-color: $color-primary;
        border-radius: 6px;
        color: $color-blue-10;
        line-height: rem-calc(16);
    }

    i:hover::after {
        transform: scale(1);
    }
}