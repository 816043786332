$icon-size: rem-calc(90);
$icon-size-mobile: rem-calc(64);
$icon-font-size: rem-calc(56);
$icon-font-size-mobile: rem-calc(34);

.contribution-card {
    height: 100%;
    
    &__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-grow: 1;

        &__title {
            margin-top: rem-calc(8);
            color: $color-green;
            font-weight: 600;
        }

        &__data {
            margin-top: rem-calc(16);
            color: $color-primary;
            text-align: center;
        }
    }

    &__icon-wrapper {
        border-radius: 50%;
        background-color: $color-green;
    }

    &__icon {
        display: flex;
        position: relative;
        width: $icon-size;
        height: $icon-size;
        justify-content: center;
        align-items: center;
        font-size: $icon-font-size;
    }
}

@media (max-width: map-get($map: $mdc-layout-grid-breakpoints, $key: tablet)) {
    .contribution-card__icon {
        width: $icon-size-mobile;
        height: $icon-size-mobile;
        font-size: $icon-font-size-mobile;
        color: $color-white;
    }

    .contribution-card__inner {
        flex-direction: row;
        justify-content: flex-start;
    }

    .contribution-card__icon-wrapper {
        margin-right: rem-calc(16);
    }

    .contribution-card__content__data {
        margin-top: 0;
        font-size: rem-calc(28);
        text-align: center;
    }

    .contribution-card__content {
        align-items: flex-start;
    }

    .contribution-card__content__title {
        margin-top: 0;
        text-transform: uppercase;
    }
}