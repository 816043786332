@import "@material/layout-grid/mdc-layout-grid";
.mdc-layout-grid {

  &__cell {
    &--address-form {
      margin-bottom: rem-calc(5);
    }
  }

  @for $col from 1 through 12 {
    $size: "desktop";
    & .mdc-layout-grid__inner--#{$size}-#{$col} {
      @media (min-width: map-get($mdc-layout-grid-breakpoints, $size)) {
        $mdc-layout-grid-columns: (
          desktop: $col
        ) !global;
        @include mdc-layout-grid-inner(
          $size,
          map-get($map: $mdc-layout-grid-default-margin, $key: desktop),
          map-get($map: $mdc-layout-grid-default-gutter, $key: desktop)
        );
      }
    }
  }
}

@include responsive_min(sm) {
  .mdc-layout-grid {
    &__cell {
      &--address-form {
        margin-bottom: rem-calc(10);
      }
    }
  }
}