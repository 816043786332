.mdc-theme {

  // alert color
  &--alert {
    color: $color-red;
  }

  // done color
  &--done {
    color: $color-green !important;
  }

  // warning color
  &--warning {
    color: $color-orange;
  }

  // in production color
  &--production {
    color: $color-orange-30;
  }
}
