@keyframes icon-arrow-right--wobble {
    25% {
        transform: rotate(180deg) translateX(rem-calc(5));
    }

    50% {
        transform: rotate(180deg) translateX(0);
    }

    75% {
        transform: rotate(180deg) translateX(rem-calc(5));
    }

    100% {
        transform: rotate(180deg) translateX(0);
    }
}

@keyframes icon-arrow-left--wobble {
    25% {
        transform: translateX(rem-calc(5));
    }

    50% {
        transform: translateX(0);
    }

    75% {
        transform: translateX(rem-calc(5));
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes icon-arrow-up--wobble {
    25% {
        transform: rotate(90deg) translateX(rem-calc(5));
    }

    50% {
        transform: rotate(90deg) translateX(0);
    }

    75% {
        transform: rotate(90deg) translateX(rem-calc(5));
    }

    100% {
        transform: rotate(90deg) translateX(0);
    }
}

@keyframes rotate {
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

.fade-out {
    animation-name: fade-out;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

@keyframes fade-out {
    from {
        opacity: 1;
        max-height: 300px;
    }
    50% {
        opacity: 0.3;
        max-height: 200px;
    }
    to {
        opacity: 0;
        max-height: 0;
        margin: 0;
    }
}

.remove-gap {
    gap: 0 !important;
    margin-bottom: 0;
    transition: gap 0.5s linear 2s, margin-bottom 0.5s linear 2s;
}
