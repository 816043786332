@import "../helpers/variables";

$nav-primary-color: $color-white;
$nav-primary-transition: all 365ms cubic-bezier(0.4, 0, 0.2, 1);

.nav {

    // primary navigation
    &--primary {
        margin-top: rem-calc(29);

        font: {
            size: rem-calc(16);
            weight: 500;
        }

        line-height: 1.556em; // 28px

        @include responsive_min(md) {
            font-size: rem-calc(17);
        }

        @include responsive_min(xlg) {
            font-size: rem-calc(18);
        }

        .nav__item {
            padding-right: rem-calc(22);
        }

        // primary navigation link
        .nav__link {
            display: inline-block;
            color: $nav-primary-color;
            text-decoration: none;
            transition: $nav-primary-transition;
            white-space: nowrap;
            padding: rem-calc(18) 0 rem-calc(18) 0;
            position: relative;
            text-align: center;
            cursor: pointer;

            &:not(:first-child) {
                margin-left: rem-calc(12);

                @include responsive_min(xlg) {
                    margin-left: rem-calc(30);
                }
            }

            &.selected {
                font-weight: 600;

                &::before {
                    border-bottom: 9px solid $color-white;
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    content: "";
                    width: 0;
                    height: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    display: block;
                    bottom: 0;
                }
            }

            &:not(.selected):hover {
                color: $color-blue;
            }
        }
    }

    // secondary navigation
    &--secondary {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: rem-calc(14);

        &>ul {
            @include reset-list();
            display: flex;

            &>li {
                font-weight: 600;
                font-size: rem-calc(12);
                position: relative;
                display: flex;
                padding-left: rem-calc(10);
                padding-right: rem-calc(10);
                align-items: center;

                &:not(:last-child) {
                    &:after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        border-left: 1px solid rgba($color-white, 0.45);
                        content: "";
                    }
                }

                .selected {
                    position: relative;
                    color: $color-primary;

                    // triangle
                    &::before {
                        border-bottom: 9px solid $color-primary;
                        border-left: 9px solid transparent;
                        border-right: 9px solid transparent;
                        content: "";
                        width: 0;
                        height: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        position: absolute;
                        display: block;
                        bottom: -23px;
                    }
                }

                a {
                    transition: color .15s ease-in-out;
                    color: $color-white;
                    padding-left: rem-calc(10);
                    padding-right: rem-calc(10);
                    cursor: pointer;

                    &:hover {
                        color: $color-primary;
                    }
                }
            }
        }

        .nav__link {
            display: flex;
            height: rem-calc(30);
            min-width: inherit;
            font-size: rem-calc(14);
            line-height: 1.429em; // 20px
            color: $nav-primary-color !important;
            transition: $nav-primary-transition;

            // active state
            &:hover,
            &.active {
                @include theme() {
                    color: t($t-color-primary) !important;
                }
            }

            &>.icon {
                font-size: rem-calc(24);
            }

            &--notifications {
                margin-right: rem-calc(20);
                display: flex;
                align-items: center;
                left: rem-calc(8);

                &>.icon {
                    font-size: rem-calc(14);
                }
            }

            &--profile {
                transition: all 0.15s;

                // active state
                &:hover,
                &.active {
                    &.icon-angle-down {
                        transform: rotate(180deg);
                    }
                }

                &>.icon-angle-down {
                    margin-right: 0;
                    font-size: rem-calc(8);
                }

                &>.icon-user-mobile {
                    font-size: rem-calc(16);
                }
            }

            &--language {
                &:not(:last-child) {
                    margin-right: rem-calc(15);
                }
            }

            &--search {
                display: flex;
                align-items: center;
            }
        }

        .mdc-menu {
            &:not(.full-width) {
                border-radius: 0;
                box-shadow: 0 rem-calc(30) rem-calc(60) rgba($color-black, 0.15);

                @include responsive_min(sm) {
                    left: rem-calc(-30);
                    margin-top: rem-calc(10);
                }
            }

            &.full-width {
                @include responsive_min(sm) {
                    width: auto;
                    right: 0;
                    // margin-left: rem-calc(-65);
                    padding-left: rem-calc(65);
                }
            }
        }

        .wrap-search {
            display: none;
            position: absolute;
            top: rem-calc(-15);
            right: 0;
            height: rem-calc(60);
            min-width: rem-calc(628);
            background-color: $color-white;
            align-items: center;

            &.open {
                display: flex;
            }

            .mdc-menu-surface--anchor {
                position: static;
            }

            &>.mdc-icon-button {
                display: flex;
                flex: 0 rem-calc(65);
                height: rem-calc(36);
                border-right: 1px solid $color-gray;
                padding: 0;
                color: $color-gray;
                align-items: center;
                justify-content: center;
                transition: color 0.15s;

                &:hover,
                &:focus {
                    color: $color-blue-10;
                }
            }

            &>div {
                flex: 1 auto;
            }

            .mdc-menu {
                box-shadow: 0 rem-calc(24) rem-calc(48) rgba($color-black, 0.16);
            }
        }
    }

    // sidebar links
    &--links {
        display: none;
        letter-spacing: 0.1px;

        @include responsive_min(sm) {
            display: block;
            width: 100%;
            max-width: rem-calc(200);
        }

        @include responsive_min(lg) {
            max-width: rem-calc(255);
        }

        li {
            &:not(:last-child) {
                margin-bottom: rem-calc(30);
            }

            &:not(.active) {
                a {

                    &:hover,
                    &:focus {
                        color: $primary;
                    }
                }
            }

            // active
            &.active {
                a {
                    font-weight: 700;
                    color: $color-gray-10;
                }
            }

            // marked
            &.marked {
                position: relative;
                padding-left: rem-calc(18);

                &:before {
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 50%;
                    width: rem-calc(8);
                    height: rem-calc(8);
                    margin-top: -#{rem-calc(4)};
                    border-radius: 50%;
                    background-color: $color-red;
                    content: "";
                }
            }
        }

        // link
        a {
            color: $color-blue-10;
            text-decoration: none !important;
            opacity: 1 !important;
            transition: all 0.15s ease-in-out;
        }
    }
}

.hide {
    display: none;
}