@import "../../helpers/variables";

.mdc-button {
  $this: &;
  font-size: rem-calc(16);
  letter-spacing: 0;
  text-transform: none;
  font-weight: 600;

  &:not(.non-transition) {
    transition: all .15s;
  }

  &:before,
  &:after {
    display: none;
  }

  &__icon {
    &.icon-angle-down {
      width: 100%;
    }
  }

  &__label > i {
    // for icons, that are besides text
    padding-right: rem-calc(8);
  }

  // outlined
  &--outlined {
    @include responsive_max(sm) {
      width: 100%;
      height: $mdc-button-height-mobile;
      padding-left: 0;
      padding-right: 0;
    }

    &#{$this}--primary {
      border-color: $primary;
      color: $primary;

      &:hover,
      &:focus {
        background-color: $primary;
        color: $color-white;
      }
    }

    &#{$this}--secondary {

      @include theme() {
        border-color: t($t-color-link);
        color: t($t-color-link);
      }

      &:hover,
      &:focus {
        @include theme() {
          background-color: t($t-color-secondary);
        }

        color: $color-white;
      }
    }

    &#{$this}--dark {
      background-color: $color-white;

      &:hover {
        @include theme() {
          background-color: t($t-color-primary);
        }
        color: $color-white;
      }
    }

    // disabled state
    &:disabled,
    &#{$this}--primary:disabled,
    &#{$this}--secondary:disabled {
      border-color: $color-gray-30;
      color: $color-gray-30;
    }
  }

  // unelevated
  &--unelevated {

    @include responsive_max(sm) {
      width: 100%;
      height: $mdc-button-height-mobile;
      padding-left: 0;
      padding-right: 0;
    }

    // disabled state
    &:disabled,
    &#{$this}--primary:disabled,
    &#{$this}--secondary:disabled {
      background-color: $color-gray-30;
    }
  }

  // text
  &--text {
    height: auto;
    padding-left: 0;
    padding-right: 0;
    background: none;
    font-size: rem-calc(16);
    text-transform: none;
    line-height: inherit;
    vertical-align: middle;

    &:not(.other) {
      display: inline-flex;
    }

    &.other {
      display: inline;

      // icon
      #{$this}__icon {
        // vertical-align: bottom;
        vertical-align: .1em;

        @include responsive_min(sm) {
          // vertical-align: .1em;
        }
      }
    }

    &#{$this}--primary {
      color: $primary;

      &:hover,
      &:focus {
        color: $color-blue-100;
      }

      // icon
      #{$this}__icon {
        color: inherit;
      }
    }

    // icon
    #{$this}__icon {
      width: auto;
      height: auto;
      margin-right: rem-calc(10);
      font-size: rem-calc(14);
      line-height: 1;
      vertical-align: middle;

      @include responsive_min(sm) {
        font-size: rem-calc(16);
      }

      &.large {
        font-size: rem-calc(24);
      }
    }

    // label
    #{$this}__label {
      vertical-align: middle;
    }
  }

  &--text-overlayed {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.right {
      right: 0;
    }
  }

  &--bold {

    // label
    #{$this}__label {
      font-weight: 700;
    }
  }

  &--modal {
    #{$this}__label {
      font-weight: 700;
      color: $color-blue-10 !important;
    }
  }

  &--inline {
    display: inline;
  }

  // link modification
  &--link {
    padding-left: 0;
    padding-right: 0;
    font-size: rem-calc(18);
    text-transform: none;
    font-weight: 400;
    letter-spacing: 0;
    transition: all .3s;

    &:hover {
      .mdc-button__icon {
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;

        &.icon-arrow-left {
          animation-name: icon-arrow-left--wobble;

          &.rotate {
            animation-name: icon-arrow-right--wobble;
          }

          &.rotate90 {
            animation-name: icon-arrow-up--wobble;
          }
        }
      }
    }

    @include responsive_max(sm) {

      &:last-child {
        width: auto;
        align-self: flex-start;
      }
    }

    // label
    .mdc-button__label {
      font-weight: 400;
    }

    &-logout {
      & > span > div {
        font-weight: bold;
        color: $color-primary !important;

        & > i {
          padding-left: rem-calc(10);
          color: $color-primary;
          transform: scale(1.25);
        }
      }
    }

    // icon
    .mdc-button__icon {
      width: auto;
      height: rem-calc(15);
      margin-right: rem-calc(10);
      font-size: rem-calc(16);
      line-height: 1;

      &.icon-arrow-left {
        @include responsive_max(sm) {
          height: auto;
          font-size: rem-calc(14);
          line-height: rem-calc(27);
        }
      }

      &.rotate {
        transform: rotate(180deg);
      }

      &.rotate90 {
        transform: rotate(90deg);
      }

      &.small {
        font-size: rem-calc(14);
      }

      &.right {
        margin-right: 0;
        margin-left: rem-calc(10);
      }
    }

    &-arrow {

      .mdc-button__icon {
        @extend .icon;
        @extend .icon-arrow-left;

        @include responsive_max(sm) {
          height: auto;
          font-size: rem-calc(14);
          line-height: rem-calc(27);
        }
      }

      &.small {

        .mdc-button__icon {
          font-size: rem-calc(14);
        }
      }

      &-left {

        .mdc-button__icon {
          float: left;
        }
      }

      &-right {

        .mdc-button__icon {
          float: right;
          transform: rotate(180deg);
        }
      }
    }

    &.mdc-theme--secondary {

      &:hover,
      &:focus {
        color: $primary !important;

        &:before,
        &:after {
          background: none;
        }
      }
    }

    &.mdc-theme--primary {

      &:hover,
      &:focus {
        opacity: 0.8;

        &:before,
        &:after {
          background: none;
        }
      }
    }
  }

  &--small {
    font-size: rem-calc(17);
  }

  &.mdc-theme--inverse {
    background: $secondary;
    color: $color-white;

    &.active {
      background: $primary;
    }
  }

  &.mdc-theme--unimportant {
    background: $color-gray-20;
    color: $primary;

    &.active {
      background: $color-gray-80;
    }
  }

  &.mdc-theme--tiny {
    font-size: rem-calc(14);
    height: rem-calc(40);
    padding: 0 rem-calc(10);
    border-radius: rem-calc(5);
    width: auto;
  }

  &-tab {
    font-size: rem-calc(20);

    @include theme() {
      color: t($t-color-primary);
    }

    padding: rem-calc(10);
    border: rem-calc(1) solid $primary;
    cursor: pointer;
    border-radius: 5px;

    &.active {
      @include theme() {
        background: t($t-color-background-darker);
      }
    }
  }

  &-tab-underlined {
    cursor: pointer;

    @include theme() {
      color: t($t-color-link);
    }

    border-bottom: 2px solid transparent;
    padding: rem-calc(15) 0 rem-calc(10) 0;
    margin-right: rem-calc(15);

    &.active {
      font-weight: bold;

      @include theme() {
        color: t($t-color-primary);
        border-bottom: 2px solid t($t-color-primary);
      }
    }
  }

  &--tab-overview,
  &--tab {
    border-bottom: 2px solid $color-gray-20;
    height: auto;
    font-size: 1rem;
    text-transform: none;
    line-height: inherit;
    vertical-align: middle;
    margin: 0;
    border-radius: 0;

    &:not(:disabled) {
      color: $color-blue-10;
    }

    &.active {
      font-weight: bold;
      color: $color-primary;
      border-bottom: 2px solid $color-primary;
    }

    &.small {
      padding: 0;
      min-width: auto;
      line-height: rem-calc(28);
    }
  }

  &-toggle {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: rem-calc(60);
    height: rem-calc(34);
    border-radius: rem-calc(34);
    background-color: $color-gray-80;
    user-select: none;

    &:before {
      position: absolute;
      content: "";
      height: rem-calc(26);
      width: rem-calc(26);
      left: rem-calc(4);
      bottom: rem-calc(4);
      transition: .3s;
      background-color: $color-white;
      border-radius: rem-calc(13);
    }

    &.active {
      @include theme() {
        background-color: t($t-color-link);
      }

      &:before {
        transform: translateX(26px);
      }
    }
  }

  &--wide {
    width: 100%;
  }

  &--orange,
  &--orange.mdc-button--unelevated {
    background-color: $color-orange;

    .mdc-button__label {
      color: $primary;
    }
  }

  &--red,
  &--red.mdc-button--unelevated {
    background-color: $color-red;

    .mdc-button__label {
      color: $color-white;
    }
  }

  &--green,
  &--green.mdc-button--unelevated {
    background-color: $color-green;

    .mdc-button__label {
      color: $color-white;
    }
  }

  &--compact {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    height: rem-calc(44);
    text-transform: none;
  }

  &--thin {
    height: auto;
    font-size: rem-calc(12);
    line-height: rem-calc(16);
    border-radius: rem-calc(4);
    padding: rem-calc(8) rem-calc(16);

    &.mdc-button--outlined {
      border-width: 1px;
      padding: rem-calc(8 - 1) rem-calc(16 - 1);
    }

    &.mdc-button--secondary,
    &.mdc-theme--secondary {
      color: $color-white;

      @include theme() {
        background-color: t($t-color-secondary);
      }

      &.mdc-button--outlined {
        background-color: $color-white;

        @include theme() {
          border-color: t($t-color-secondary);
        }
      }
    }

    &.mdc-button--inline {
      padding: rem-calc(4) rem-calc(4);
    }
  }

  &--tiny {
    font-size: rem-calc(12);
    line-height: rem-calc(14);
    height: auto;
    padding: rem-calc(5);
    width: auto;
  }

  &--menu-link {
    height: auto;
    padding-left: 0;
    padding-right: 0;
    background: none;
    font-size: rem-calc(16);
    text-transform: none;
    line-height: inherit;
    vertical-align: middle;
    display: inline-flex;

    span {
      color: $color-white;
    }
  }
}

.mdc-toggle-container {
  display: flex;
  align-items: center;
  padding: rem-calc(15) rem-calc(10);

  @include theme() {
    background-color: t($t-color-background);
  }

  label {
    padding-left: rem-calc(10);
    user-select: none;
    cursor: pointer;
  }
}

.disabled-btn {
  color: $color-gray-80 !important;
  border-color: #999 !important;
  opacity: 0.5;
}

@include responsive_max(sm) {
  .mdc-button {
    $this: &;

    &:disabled,
    &#{$this}--primary:disabled,
    &#{$this}--secondary:disabled {
      background-color: $color-gray-30;
    }

    &--link {
      font-size: rem-calc(16);
    }
  }

  .mdc-toggle-container-mobile {
    display: grid;
    grid-auto-flow: column;
  }
}