$bg-color: #F7F9FA;
$border-color: #E3E5E6;

$mobile-padding-x: 24;

.energy-details {

    background-color: $bg-color;
    padding: rem-calc(45);

    &__title {
        align-self: center;
    }

    &__locations {

        &-wrapper {
            width: 50%;
            margin-left: auto;
        }

        .mdc-select {
            &__selected-text {
                color: $color-primary !important;
                font-weight: 700;
            }
        }
    }

    &__disclaimer {
        font-size: rem-calc(12);
        line-height: rem-calc(14);
        color: $color-gray-50;
        font-style: italic;

        &:before {
            content: '*';
            margin-right: rem-calc(4);
        }
    }


    @include responsive_max(lg) {
        &__locations-wrapper {
            width: 2/3*100%;
        }

        // On small screens, stretch hthe gray area to edges of screen (24 is grid margin)
        .mdc-layout-grid & {
            margin-left: rem-calc(-24);
            margin-right: rem-calc(-24);
        }
    }

    @include responsive_max(sm) {
        padding: rem-calc(30) rem-calc($mobile-padding-x);

        &__title {
            align-self: flex-start;
        }

        &__locations {
            &-wrapper {
                margin-left: rem-calc(-$mobile-padding-x);
                margin-right: rem-calc(-$mobile-padding-x);
                width: calc(100% + #{rem-calc($mobile-padding-x * 2)});
            }


            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-bottom: 1px solid $border-color !important;
                border-top: 1px solid $border-color !important;
                border-left: none;
                border-right: none;
                background-color: transparent !important;
            }

            .mdc-select {
                &__selected-text {
                    padding-left: rem-calc(24);
                }
            }
        }
    }

    .energy-graph {
        .chart {
            background-color: $color-white;
        }

        .chart__container {
            display: flex;
            flex-direction: column;
            height: 350px;

            &-inner {
                flex-grow: 1;
            }
        }

        @include responsive_max(lg) {

            .chart__container {
                overflow: auto;
                padding: rem-calc(16) 0;

                &-inner {
                    min-width: 1080px;
                }
            }
        }

        @include responsive_max(sm) {

            .chart__container {
                margin-left: rem-calc(-$mobile-padding-x);
                margin-right: rem-calc(-$mobile-padding-x);
            }
        }
    }

    .energy-table {
        display: flex;
        flex-direction: column;

        &__toggle {
            background-color: #E7EDF6;
            padding: rem-calc(9) rem-calc(30);
            color: $color-gray-50;
            font-size: rem-calc(12);
            font-weight: 700;
            cursor: pointer;

            .icon {
                font-size: rem-calc(8);
                margin-left: rem-calc(16);
                vertical-align: middle;

                @include theme() {
                    color: t($t-color-secondary);
                }
            }
        }

        background-color: $color-white;

        .table__container {
            padding: rem-calc(18) rem-calc(24) rem-calc(8);
        }

        .table__buttons-container {
            padding: rem-calc(16) rem-calc(16) 0;
            display: flex;
            justify-content: flex-end;

            .mdc-button {
                font-size: rem-calc(16);
            }
        }

        @include responsive_max(sm) {
            margin-left: rem-calc(-$mobile-padding-x);
            margin-right: rem-calc(-$mobile-padding-x);

            .table__container {

                overflow: auto;
                padding: 0;
                margin: rem-calc(18) 0 rem-calc(8);

                // &-inner {
                //     min-width: 1080px;
                // }
            }

            .table__buttons-container {
                flex-direction: column;
            }

            table th {
                position: relative;
                top: auto;
            }
        }
    }

}