@import "../helpers/_functions";
@import "../helpers/_variables";

$padding-x: 16;
$padding-y: 12;
$line-height:16;
$height: $line-height+($padding-y * 2);

.station-filter {
    padding: rem-calc($padding-y) rem-calc($padding-x);
    line-height: rem-calc($line-height);
    height: rem-calc($height);
    border-radius: rem-calc($height / 2);

    font-size: rem-calc(14);
    text-transform: none;

    display: inline-flex;
    align-items: center;

    &,
    &.mdc-button {
        background-color: $secondary;
        color: $color-white;

        &.active {
            background: $primary;
        }

        i {
            margin-right: rem-calc(4);
            width: rem-calc($line-height);
            height: rem-calc($line-height);
            font-size: rem-calc($line-height);
        }
    }
}