@import "../helpers/variables";
@import "../helpers/functions";
.contract {
    &-padded-label {

        padding-right: rem-calc(10);
    }

    &-icon {
        @include theme() {
            color: t($t-color-link);
        }
    }

    &-tab-container {
        display: flex;
        align-items: center;
        font-size: rem-calc(14);

        &__item{
            display: flex;
            align-items: center;
            font-size: rem-calc(14);
        }

        @include responsive_max(sm) {
            &.right {
                flex-direction: column;
                align-items: flex-end !important;
                margin-top: rem-calc(15);
            }

            &__item{
                display: flex;
                align-items: center;
            }
        }
    }

    &-contact-info {
        font-size: rem-calc(14);
    }
    &-product-title {
        font-size: rem-calc(16);
        font-weight: bold;
    }
    &-rohesarts-logo img {
        height: rem-calc(140);
    }
    &-green-energy-logo-small {
        cursor: pointer;
    }
    &-green-energy-logo-small img {
        height: rem-calc(30);
    }
    &-bank-logo {
        font-size: rem-calc(64);
        transform: translate(0, 10%);

        @include responsive_min(sm) {
            transform: translate(0, 20%);
        }
    }
}

.contract-list {
    &__actions {
        line-height: 1;

        .mdc-button {
            text-transform: none;
            white-space: nowrap;
            color: $secondary;
            padding: 0;
            justify-content: flex-start;
            height: auto;
            line-height: rem-calc(24);

            @include theme() {
                color: t($t-color-info);
            }
        }
    }
}

.location-penalty {
    font-weight: bold;
    color: $color-red;
}