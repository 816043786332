@import "../helpers/variables";
@import "../helpers/functions";

.fields-group {

  // list
  &__list {
    $this: &;

    &--bordered {

      &.wide {

        @include responsive_max(sm) {
          margin-left: rem-calc(-32);
          margin-right: rem-calc(-32);
          justify-content: space-between;
        }
      }

      #{$this}-item {
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid #d2ecfa;
        padding: rem-calc(20) 0;
        align-items: center;

        @include responsive_min(sm) {
          padding: rem-calc(15);
          flex-direction: row;
          justify-content: space-between;
        }

        &.narrow {
          padding: rem-calc(15) rem-calc(32) rem-calc(15) rem-calc(32);

          @include responsive_max(sm) {
            align-items: stretch;
          }

          @include responsive_min(sm) {
            padding: rem-calc(15) rem-calc(45) rem-calc(13) rem-calc(30);
          }
        }

        &:first-child {
          border-top: 2px solid #d2ecfa;
        }

        &>*:first-child {
          width: 100%;
          margin-bottom: rem-calc(15);

          @include responsive_min(sm) {
            margin-top: rem-calc(15);
          }

          @include responsive_min(lg) {
            max-width: 60%;
          }
        }

        &>*:last-child {

          @include responsive_min(md) {
            margin-left: rem-calc(30);
          }
        }

        .mdc-checkbox {
          align-self: flex-start;
        }

        .mdc-text-field {

          @include responsive_min(sm) {
            width: rem-calc(200);
          }

          &+span {
            margin-left: rem-calc(15);
            align-self: center;
          }
        }

        .label {
          margin-bottom: 5px;
          font-weight: bold !important;
          letter-spacing: 0;

          @include responsive_min(sm) {
            margin: auto 0;
          }
        }
      }
    }

    &--text {

      #{$this}-item {
        display: flex;
        align-items: flex-start;

        @include theme() {
          background-color: t($t-color-background);
        }

        padding: rem-calc(10);
        margin-bottom: rem-calc(5);

        // form field
        .mdc-form-field {
          margin-top: rem-calc(6);
          margin-right: rem-calc(15);
        }

        .override-mdc-form-field {
          margin-top: rem-calc(0) !important
        }

        // form field text
        .text {
          display: flex;
          cursor: pointer;
          align-self: center;
          flex-wrap: wrap;

          &>* {
            display: flex;
          }

          &.disabled {
            color: $color-gray-80;
          }
        }

        // form field text primary
        .primary-text {
          font-weight: bold;
          padding-right: rem-calc(20);

          small {
            font-weight: 400;
          }
        }
      }
    }
  }
}

.mdc-notched-outline {
  .mdc-notched-outline {
    &__leading {
      border-radius: 4px 0 0 40px;
    }

    &__trailing {
      border-radius: 0 4px 40px 0;
    }
  }
}

.mdc-element-pre .mdc-notched-outline .mdc-notched-outline__trailing {
  border-radius: 0;
}

.mdc-element-post .mdc-notched-outline .mdc-notched-outline__leading {
  border-radius: 0;
}

.field-label {
  font-size: rem-calc(14);
  font-weight: 700;
  line-height: rem-calc(26);
  letter-spacing: 0.3px;
  color: #222 !important;
}