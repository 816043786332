@import "../helpers/functions";
@import "../base/typography";

.tabs {
  &__nav {
    display: flex;
    flex-direction: row;
    flex: 1 0 100%;

    &__item {
      flex: 1 0 0;
      display: flex;

      .mdc-button__label {
        align-self: flex-end;
      }
    }
  }

  &__content {
    display: block;

    .hidden {
      display: none;
    }
  }
}

.tabs-new {
  &__nav {
    display: grid;
    grid-auto-flow: column;
    background-color: #F2F4F7;
    border-radius: 16px;
    padding: 4px;
    align-items: center;
    margin-bottom: 10px;

    &.desktop {
      width: 30%;
      margin: 0 auto;
    }

    &__item {
      text-align: center;

      button {
        height: unset;
        color: rgba(23, 32, 87, 1);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 8px;
        width: 100%;
        font-family: $font-commissioner;
      }

      .active {
        background-color: rgba(255, 255, 255, 1);
        box-shadow:
          0px 2px 4px 0px rgba(0, 55, 153, 0.05),
          1px 2px 8px 0px rgba(52, 64, 84, 0.08);
        border-radius: 12px;
        border: 0.5px solid rgba(242, 244, 247, 1);
      }
    }
  }
}