.mdc-dialog {
    $self: &;
    &#{&}--scrollable,
    &#{&}--info,
    &#{&}--wide {
        
        & #{$self}__content {
            padding-top: 0;
        }
    }

    #{$self}__title {
        border: none;
        #{$self}__button {
            margin: rem-calc(8);
            &--close {
                &:hover,
                &:focus,
                &:active {
                    color: $primary;
                    .icon {
                        color: $primary;
                    }
                }
                .icon {
                    font-size: rem-calc(25);
                    height: auto;
                    width: auto;
                }
            }
        }
    }
}
