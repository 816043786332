@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;600;700&display=swap');

@font-face {
  font-family: AdieuBold;
  src:
          url('../../fonts/adieu/Adieu-Bold.woff2') format('woff2'),
          url('../../fonts/adieu/Adieu-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
