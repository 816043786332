@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
@import "../helpers/functions";
@import "../helpers/variables";

.carousel {
  &__wrapper {
    position: relative;
    &--full-height { height: 100%; }
  }

  &__header {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }

  &__controls {
    display: flex;
    gap: 16px;
  }

  &__control_btn {
    width: 36px;
    height: 36px;
    background: white;
    color: $primary;
    border-radius: 50%;
    padding: 0;
    font-size: rem-calc(12);
    i.icon { height: rem-calc(12) }

    &--pause {
      font-size: rem-calc(18);
      i.icon { height: rem-calc(18) }
    }
  }

  &__paging {
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    padding: 8px 24px;

    &-dot {
      cursor: pointer;
      max-width: 26px;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
    }

    li { flex: 1; max-width: 26px; }
  }

  li:has(~ .slick-active) .carousel__paging-dot {
    background-color: white;
  }

  .slick-active .carousel__paging-dot {
    box-shadow: 0 0 2px 2px white;
  }

  .slick-track { display: flex; }

  &--full-height {
    height: 100%;
    .slick-list, .slick-track, .slick-slide > div { height: 100%; }
  }
}
