@import "../helpers/functions";
@import "../helpers/variables";

.chart {
    display: flex;
    flex-direction: column;

    &__legend {
        padding-bottom: rem-calc(32);
        padding-left: rem-calc(16);

        &__item {
            $item: &;

            display: inline-flex;
            align-items: center;
            padding-right: rem-calc(30);
            font-size: rem-calc(13);
            line-height: rem-calc(16);
            cursor: pointer;

            &__color {
                display: inline-block;
                width: rem-calc(18);
                height: rem-calc(10);
                border-radius: rem-calc(5);
                margin-right: rem-calc(10);
            }

            &--disabled {
                #{$item}__text {
                    // text-decoration: line-through;
                    color: $color-gray-80;
                }
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        height: 350px;

        &-inner {
            position: relative;
            flex-grow: 1;
        }
    }
}

@include responsive_max(sm) {
    .chart {
        &__legend {
            display: inline-flex;
            flex-direction: column;
            align-self: flex-start;
            align-items: flex-start;
            padding-left: 0;
            padding-bottom: rem-calc(16);

            &__item {
                padding-right: 0;
                padding-bottom: rem-calc(8);
            }
        }
    }
}