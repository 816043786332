@charset "UTF-8";
$fonts-path: "../../fonts/";

@font-face {
  font-family: "ui-icons";
  src: url($fonts-path + 'icons/ui-icons.eot');
  src: url($fonts-path + 'icons/ui-icons.eot?#iefix') format('eot'),
    url($fonts-path + 'icons/ui-icons.woff2') format('woff2'),
    url($fonts-path + 'icons/ui-icons.woff') format('woff'),
    url($fonts-path + 'icons/ui-icons.ttf') format('truetype'),
    url($fonts-path + 'icons/ui-icons.svg#ui-icons') format('svg');
}

@mixin icon-styles {
  font-family: "ui-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: inherit;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

.icon {
  display: inline-block;
  line-height: 1;
  vertical-align: 0;

  &.rotate {
    transform: rotate(180deg);
  }

  &.small {
    font-size: rem-calc(14);
  }
}

@function icon-char($filename) {
  $char: "";

  @if $filename==alert-small {
    $char: "\E001";
  }

  @if $filename==alert {
    $char: "\E002";
  }

  @if $filename==angle-down {
    $char: "\E003";
  }

  @if $filename==angle-left {
    $char: "\E004";
  }

  @if $filename==angle-right {
    $char: "\E005";
  }

  @if $filename==arrow-left {
    $char: "\E006";
  }

  @if $filename==bell {
    $char: "\E007";
  }

  @if $filename==burger {
    $char: "\E008";
  }

  @if $filename==calendar {
    $char: "\E009";
  }

  @if $filename==car-wash {
    $char: "\E00A";
  }

  @if $filename==check {
    $char: "\E00B";
  }

  @if $filename==close {
    $char: "\E00C";
  }

  @if $filename==coffe-2 {
    $char: "\E00D";
  }

  @if $filename==coffe {
    $char: "\E00E";
  }

  @if $filename==done-small {
    $char: "\E00F";
  }

  @if $filename==done {
    $char: "\E010";
  }

  @if $filename==electricity-2 {
    $char: "\E011";
  }

  @if $filename==electricity {
    $char: "\E012";
  }

  @if $filename==facebook {
    $char: "\E013";
  }

  @if $filename==folder {
    $char: "\E014";
  }

  @if $filename==gas-2 {
    $char: "\E015";
  }

  @if $filename==gas {
    $char: "\E016";
  }

  @if $filename==gasoline-2 {
    $char: "\E017";
  }

  @if $filename==gasoline {
    $char: "\E018";
  }

  @if $filename==info-fill {
    $char: "\E019";
  }

  @if $filename==info {
    $char: "\E01A";
  }

  @if $filename==instagram {
    $char: "\E01B";
  }

  @if $filename==list {
    $char: "\E01C";
  }

  @if $filename==mail {
    $char: "\E01D";
  }

  @if $filename==menu-vertical {
    $char: "\E01E";
  }

  @if $filename==navigation {
    $char: "\E01F";
  }

  @if $filename==pdf {
    $char: "\E020";
  }

  @if $filename==search {
    $char: "\E021";
  }

  @if $filename==trailer {
    $char: "\E022";
  }

  @if $filename==triangle {
    $char: "\E023";
  }

  @if $filename==user {
    $char: "\E024";
  }

  @if $filename==youtube {
    $char: "\E025";
  }

  @if $filename==co2 {
    $char: "a";
  }

  @if $filename==duration {
    $char: "b";
  }

  @if $filename==location {
    $char: "c";
  }

  @if $filename==members {
    $char: "d";
  }

  @if $filename==trees {
    $char: "e";
  }

  @if $filename==twitter {
    $char: "f";
  }

  @if $filename==linkedin {
    $char: "g";
  }

  @if $filename==arrow-right {
    $char: "h";
  }

  @if $filename==triangle-up {
    $char: "i";
  }

  @if $filename==world {
    $char: "j";
  }

  @if $filename==wallet {
    $char: "k";
  }

  @if $filename==menu {
    $char: "l";
  }

  @if $filename==user-mobile {
    $char: "m";
  }

  @if $filename==active {
    $char: "n";
  }

  @if $filename==card {
    $char: "o";
  }

  @if $filename==calendar-numbered {
    $char: "p";
  }

  @if $filename==phone {
    $char: "q";
  }

  @if $filename==card-2 {
    $char: "r";
  }

  @if $filename==invoices-thin {
    $char: "s";
  }

  @if $filename==services-thin {
    $char: "š";
  }

  @if $filename==dashboard {
    $char: "z";
  }

  @if $filename==card-thin {
    $char: "ž";
  }

  @if $filename==transactions-thin {
    $char: "t";
  }

  @if $filename==navicon {
    $char: "u";
  }

  @if $filename==stamp {
    $char: "v";
  }

  @if $filename==stamp-filled {
    $char: "w";
  }

  @if $filename==calendar-thin {
    $char: "x";
  }

  @if($filename==fuel-pump) {
    $char: "y";
  }

  @if($filename==trailer-rent) {
    $char: "õ";
  }

  @if($filename==arrow-fat) {
    $char: "ä";
  }

  @if($filename==check-fat) {
    $char: "ö";
  }

  @if($filename==trailer-rent-2) {
    $char: "+";
  }

  @if($filename==electricity-full) {
    $char: "-";
  }

  @if($filename==stock-line) {
    $char: "/";
  }

  @if($filename==qr) {
    $char: "*";
  }

  @if($filename==pylon) {
    $char: ",";
  }

  @if $filename==download {
    $char: "\E026";
  }

  @if $filename==wave {
    $char: "\E027";
  }

  @if $filename==write {
    $char: "\E028";
  }

  @if $filename==floppy {
    $char: "\E029";
  }

  @if $filename==coop {
    $char: "\E900";
  }

  @if $filename==lhv {
    $char: "\E901";
  }

  @if $filename==luminor {
    $char: "\E902";
  }

  @if $filename==seb {
    $char: "\E903";
  }

  @if $filename==swed {
    $char: "\E904";
  }

  @if $filename==contract {
    $char: "\E905";
  }

  @if $filename==eye-closed {
    $char: ":";
  }

  @if $filename==eye-open {
    $char: ";";
  }

  @if $filename==user-round {
    $char: "<";
  }

  @if $filename==envelope {
    $char: ">";
  }

  @if $filename==digital-stamp {
    $char: "\E906";
  }

  @if $filename==calculator {
    $char: "\E907";
  }

  @if $filename==map {
    $char: "@";
  }

  @if $filename==bullhorn {
    $char: "|"
  }

  @if $filename==clipboard {
    $char: "~"
  }

  @if $filename==birthday-cake {
    $char: "^"
  }

  @if $filename==wallet-new {
    $char: "\E908";
  }

  @if $filename==bicycle {
    $char: "\E909";
  }

  @if $filename==gift {
    $char: "\E90A";
  }

  @if $filename==cylinder-gas-2 {
    $char: "\E90C";
  }

  @if $filename==trash-can-regular {
    $char: "\E90B";
  }

  @if $filename==trash-can-solid {
    $char: "\E90D";
  }

  @if $filename==Refresh {
    $char: "\E90E";
  }

  @if $filename==pause {
    $char: "\E90F";
  }

  @if $filename==share {
    $char: "\E964";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    }

    @else {
      @include icon-styles;
    }

    content: icon-char($filename);
  }
}

.icon-alert-small {
  @include icon(alert-small);
}

.icon-alert {
  @include icon(alert);
}

.icon-angle-down {
  @include icon(angle-down);
}

.icon-angle-left {
  @include icon(angle-left);
}

.icon-angle-right {
  @include icon(angle-right);
}

.icon-arrow-left {
  @include icon(arrow-left);
}

.icon-bell {
  @include icon(bell);
}

.icon-burger {
  @include icon(burger);
}

.icon-calendar {
  @include icon(calendar);
}

.icon-car-wash {
  @include icon(car-wash);
}

.icon-check {
  @include icon(check);
}

.icon-close {
  @include icon(close);
}

.icon-coffe-2 {
  @include icon(coffe-2);
}

.icon-coffe {
  @include icon(coffe);
}

.icon-done-small {
  @include icon(done-small);
}

.icon-done {
  @include icon(done);
}

.icon-electricity-2 {
  @include icon(electricity-2);
}

.icon-electricity {
  @include icon(electricity);
}

.icon-facebook {
  @include icon(facebook);
}

.icon-folder {
  @include icon(folder);
}

.icon-gas-2 {
  @include icon(gas-2);
}

.icon-gas {
  @include icon(gas);
}

.icon-gasoline-2 {
  @include icon(gasoline-2);
}

.icon-gasoline {
  @include icon(gasoline);
}

.icon-info-fill {
  @include icon(info-fill);
}

.icon-info {
  @include icon(info);
}

.icon-instagram {
  @include icon(instagram);
}

.icon-list {
  @include icon(list);
}

.icon-mail {
  @include icon(mail);
}

.icon-menu-vertical {
  @include icon(menu-vertical);
}

.icon-navigation {
  @include icon(navigation);
}

.icon-pdf {
  @include icon(pdf);
}

.icon-search {
  @include icon(search);
}

.icon-trailer {
  @include icon(trailer);
}

.icon-triangle {
  @include icon(triangle);
}

.icon-user {
  @include icon(user);
}

.icon-youtube {
  @include icon(youtube);
}

@font-face {
  font-family: "ui-icons2";
  src: url($fonts-path + 'icons2/ui-icons-2.eot');
  src: url($fonts-path + 'icons2/ui-icons-2.eot?#iefix') format('embedded-opentype'),
    url($fonts-path + 'icons2/ui-icons-2.woff') format('woff'),
    url($fonts-path + 'icons2/ui-icons-2.ttf') format('truetype'),
    url($fonts-path + 'icons2/ui-icons-2.svg#ui-icons') format('svg');
}

@mixin icon2-styles {
  font-family: "ui-icons2";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: inherit;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon2 {
  @include icon2-styles;
}

@mixin icon2($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon2;
    }

    @else {
      @include icon2-styles;
    }

    content: icon-char($filename);
  }
}

.icon-trees {
  @include icon2(trees);
}

.icon-members {
  @include icon2(members);
}

.icon-location {
  @include icon2(location);
}

.icon-duration {
  @include icon2(duration);
}

.icon-co2 {
  @include icon2(co2);
}

.icon-twitter {
  @include icon2(twitter);
}

.icon-linkedin {
  @include icon2(linkedin);
}

.icon-triangle-up {
  @include icon2(triangle-up);
}

.icon-arrow-right {
  @include icon2(arrow-right);
}

.icon-world {
  @include icon2(world);
}

.icon-wallet {
  @include icon2(wallet);
}

.icon-menu {
  @include icon2(menu);
}

.icon-footernotch {
  @include icon2(footernotch);
}

.icon-user-mobile {
  @include icon2(user-mobile);
}

.icon-active {
  @include icon2(active);
}

.icon-card {
  @include icon2(card);
}

.icon-calendar-numbered {
  @include icon2(calendar-numbered);
}

.icon-phone {
  @include icon2(phone);
}

.icon-card-2 {
  @include icon2(card-2);
}

.icon-services-thin {
  @include icon2(services-thin);
}

.icon-dashboard {
  @include icon2(dashboard);
}

.icon-invoices-thin {
  @include icon2(invoices-thin);
}

.icon-transactions-thin {
  @include icon2(transactions-thin);
}

.icon-card-thin {
  @include icon2(card-thin);
}

.icon-navicon {
  @include icon2(navicon);
}

.icon-stamp {
  @include icon2(stamp);
}

.icon-stamp-filled {
  @include icon2(stamp-filled);
}

.icon-calendar-thin {
  @include icon2(calendar-thin);
}

.icon-fuel-pump {
  @include icon2(fuel-pump);
}

.icon-trailer-rent {
  @include icon2(trailer-rent);
}

.icon-arrow-fat {
  @include icon2(arrow-fat);
}

.icon-check-fat {
  @include icon2(check-fat);
}

.icon-trailer-rent-2 {
  @include icon2(trailer-rent-2);
}

.icon-electricity-full {
  @include icon2(electricity-full);
}

.icon-stock-line {
  @include icon2(stock-line);
}

.icon-qr {
  @include icon2(qr);
}

.icon-pylon {
  @include icon2(pylon);
}

.icon-download {
  @include icon2(download);
}

.icon-wave {
  @include icon2(wave);
}

.icon-write {
  @include icon2(write);
}

.icon-floppy {
  @include icon2(floppy);
}

.icon-coop {
  @include icon2(coop);
}

.icon-lhv {
  @include icon2(lhv);
}

.icon-luminor {
  @include icon2(luminor);
}

.icon-seb {
  @include icon2(seb);
}

.icon-swed {
  @include icon2(swed);
}

.icon-contract {
  @include icon2(contract);
}

.icon-eye-open {
  @include icon2(eye-open);
}

.icon-eye-closed {
  @include icon2(eye-closed);
}

.icon-user-round {
  @include icon2(user-round);
}

.icon-envelope {
  @include icon2(envelope);
}

.icon-digital-stamp {
  @include icon2(digital-stamp);
}

.icon-calculator {
  @include icon2(calculator);
}

.icon-bullhorn {
  @include icon2(bullhorn);
}

.icon-clipboard {
  @include icon2(clipboard);
}

.icon-birthday-cake {
  @include icon2(birthday-cake);
}

.icon-map {
  @include icon2(map);
}

.icon-wallet-new {
  @include icon2(wallet-new);
}

.icon-bicycle {
  @include icon2(bicycle);
}

.icon-gift {
  @include icon2(gift);
}

.icon-cylinder-gas-2 {
  @include icon2(cylinder-gas-2)
}

.icon-trash-can-regular {
  @include icon2(trash-can-regular);
}

.icon-trash-can-solid {
  @include icon2(trash-can-solid);
}

.icon-refresh {
  @include icon2(Refresh);
}

.icon-pause {
  @include icon2(pause);
}

// Fonts taken from alexela.ee

@font-face {
  font-family: "alexela-icons";
  src: url($fonts-path + 'alexela-icons/alexela-icons.eot');
  src: url($fonts-path + 'alexela-icons/alexela-icons.eot?#iefix') format('embedded-opentype'),
    url($fonts-path + 'alexela-icons/alexela-icons.woff') format('woff'),
    url($fonts-path + 'alexela-icons/alexela-icons.ttf') format('truetype'),
    url($fonts-path + 'alexela-icons/alexela-icons.svg#alexela-icons') format('svg');
}

@mixin alexela-icon-styles {
  font-family: "alexela-icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: inherit;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%alexela-icon {
  @include alexela-icon-styles;
}

@mixin alexela-icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %alexela-icon;
    }

    @else {
      @include alexela-icon-styles;
    }

    content: map-get($alexela-font-map, $filename);
  }
}

$alexela-font-map: ("95": "\E90E",
  "98": "\E90F",
  "diesel":"\E910",
  "adblue": "\E911",
  "cng":"\E912",
  "edk":"\E913",
  "lpg":"\E914",
  "lng":"\E94E",
  "convenience-store":"\E902",
  "cylinder-gas":"\E901",
  "trailer": "\E900",
  "air-water":"\E904",
  "vacuum":"\E906",
  "windshield":"\E90D",
  "diesel-fast-station":"\E90A",
  "insurance":"\E908",
  "wc":"\E903",
  "harbour-station":"\E90C",
  "document-m":"\E947",
  "plus": "\E948",
  "alexela-m": "\E94F",
  "check-s": "\e95d",
  "laadija": "\e90b"
);

.icon-charger {
  @include alexela-icon("laadija");
}

.icon-check-s {
  @include alexela-icon("check-s");
}

.icon-alexela-m {
  @include alexela-icon("alexela-m");
}

.icon-95 {
  @include alexela-icon("95");
}

.icon-98 {
  @include alexela-icon("98");
}

.icon-diesel {
  @include alexela-icon(diesel);
}

.icon-adblue {
  @include alexela-icon(adblue);
}

.icon-cng {
  @include alexela-icon(cng);
}

.icon-edk {
  @include alexela-icon(edk);
}

.icon-lpg {
  @include alexela-icon(lpg);
}

.icon-lng {
  @include alexela-icon(lng);
}

.icon-convenience-store {
  @include alexela-icon(convenience-store);
}

.icon-cylinder-gas {
  @include alexela-icon(cylinder-gas);
}

.icon-trailer-2 {
  @include alexela-icon(trailer);
}

.icon-air-water {
  @include alexela-icon(air-water);
}

.icon-vacuum {
  @include alexela-icon(vacuum);
}

.icon-windshield {
  @include alexela-icon(windshield);
}

.icon-diesel-fast-station {
  @include alexela-icon(diesel-fast-station);
}

.icon-insurance {
  @include alexela-icon(insurance);
}

.icon-wc {
  @include alexela-icon(wc);
}

.icon-harbour-station {
  @include alexela-icon(harbour-station);
}

.icon-document-m {
  @include alexela-icon(document-m);
}

.icon-plus {
  @include alexela-icon(plus);
}

@font-face {
    font-family: 'basketballEuro';
    src: url($fonts-path + "basketball-icons/basketballEuro.ttf") format("truetype"),
        url($fonts-path + "basketball-icons/basketballEuro.eot") format("embedded-opentype"),
        url($fonts-path + "basketball-icons/basketballEuro.woff") format("woff");
}

@mixin basketball-money-icon-styles {
  font-family: "basketBallEuro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: inherit;
  text-decoration: none;
  text-transform: none;
}

%basketball-money-icon {
  @include basketball-money-icon-styles;
}

@mixin basketball-money-icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %basketball-money-icon;
    }

    @else {
      @include basketball-money-icon-styles;
    }

    content: map-get($basketball-money-font-map, $filename);
  }
}

$basketball-money-font-map: ("alexela-iconsbasketballmoney": "\ea01");

.icon-basketball-money {
    @include basketball-money-icon(alexela-iconsbasketballmoney);
}

@font-face {
    font-family: 'basketballEuros';
    src: url($fonts-path + "basketball-icons/basketballEuros.ttf") format("truetype"),
        url($fonts-path + "basketball-icons/basketballEuros.eot") format("embedded-opentype"),
        url($fonts-path + "basketball-icons/basketballEuros.woff") format("woff");
}

@mixin basketball-euros-icon-styles {
  font-family: "basketballEuros";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: inherit;
  text-decoration: none;
  text-transform: none;
}

%basketball-euros-icon {
  @include basketball-euros-icon-styles;
}

@mixin basketball-euros-icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %basketball-euros-icon;
    }

    @else {
      @include basketball-euros-icon-styles;
    }

    content: map-get($basketball-euros-font-map, $filename);
  }
}

$basketball-euros-font-map: ("alexela-iconsbasketball": "\ea01");

.icon-basketball-euros {
    @include basketball-euros-icon(alexela-iconsbasketball);
}
