$discount-item-rotate: skewY(-2deg);

$discount-item-color: $primary;
$discount-item-summary-color: $color-white;

$discount-item-border-color: #e2e8eb;

$discount-item-bg: #f7f9fa;
$discount-item-border-width: 2px;
$discount-item-summary-bg: $secondary;
$discount-item-highlighted-bg: #f2fcff;

$discount-chart-overall-badge-size: rem-calc(250);

.discount {
  $self: &;
  width: 100%;

  @include responsive_min(md) {
    max-width: rem-calc(170);
  }

  &__list {
    @include reset-list;
  }

  // list item
  &__item {
    position: relative;
    padding: rem-calc(10) rem-calc(30) rem-calc(15);
    margin-bottom: $discount-item-border-width;
    color: $discount-item-color;
    text-align: center;

    @include responsive_max(md) {

      &:not(&--summary) {
        display: none;
      }
    }

    @include responsive_min(md) {
      display: block !important;
      padding: rem-calc(17) rem-calc(5) rem-calc(21);
    }

    // item background shape
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: $discount-item-border-width solid $discount-item-border-color;
      background-color: $discount-item-bg;
      transform: $discount-item-rotate;
      z-index: -1;
      content: '';
    }

    // last item
    &:last-child {
      margin-bottom: 0;

      &:before {
        border-width: 0;
      }
    }

    // summary item
    &--summary {
      margin-bottom: 0;
      color: $discount-item-summary-color;

      @include responsive_max(md) {
        position: relative;
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(10);
        padding-right: rem-calc(70);
        padding-left: rem-calc(30);
        text-align: left;
      }

      @include responsive_min(md) {
        padding-top: rem-calc(27);
        padding-bottom: rem-calc(29);
      }

      &:before {
        background-color: $discount-item-summary-bg;
        border-width: 0;
      }

      // summary title
      .discount__item__head {
        font-size: rem-calc(16);
        line-height: 1.25em; // 20px

        @include responsive_max(md) {
          margin-bottom: 0;
        }

        @include responsive_min(md) {
          padding: 0 rem-calc(20);
          font-size: rem-calc(20);
          line-height: 1.5em; // 30px
        }
      }

      // item discount value
      .discount__item__info {
        font-size: rem-calc(44);
        line-height: 1.3636em; // 60px
        white-space: nowrap;
      }

      .icon {
        position: absolute;
        right: rem-calc(30);
        top: 50%;
        margin-top: -#{rem-calc(5)};
        transform: translateY(-50%);
        transform-origin: 50% 50%;

        @include responsive_min(md) {
          display: none;
        }
      }

      // active state (mobile toogle)
      &.active {

        .icon {
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }

    // highlighted item
    &--highlighted {

      &:before {
        background-color: $discount-item-highlighted-bg;
      }
    }

    // item title
    &__head {
      display: block;
      margin-bottom: rem-calc(6);
      font-size: rem-calc(16);
      line-height: 1.625em // 26px
    }

    // item discount value
    &__info {
      font-size: rem-calc(30);
      font-weight: 400;
      line-height: 1.2em; // 36px

      .icon {
        font-size: rem-calc(16);
        color: $color-green;
      }
    }
  }

  // row modification
  &-row {

    // head
    @include responsive_max(lg) {

      .mdc-typography--headline3 {
        margin-bottom: rem-calc(22) !important;
        text-align: center;
      }
    }

    // items list
    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      padding: rem-calc(22) 0 rem-calc(28);

      @include responsive_min(lg) {
        padding: 0 rem-calc(25);
      }

      @include responsive_min(lg) {
        padding: 0 rem-calc(45);
        flex-wrap: nowrap;
      }

      &--wide {

        @include responsive_min(lg) {
          padding: 0 rem-calc(30);
        }

        // items list divider line
        &:before {

          @include responsive_min(lg) {
            top: rem-calc(62) !important;
          }
        }

        .discount-row__item {

          @include responsive_min(lg) {
            margin: 0 rem-calc(10);
          }
        }

        .discount-row__item__head {

          @include responsive_min(lg) {
            font-size: rem-calc(14);
            line-height: 1.57143em; // 22px
          }
        }

        .discount-row__item__info .icon {
          background: $color-white;

          @include responsive_min(lg) {
            top: rem-calc(64);
          }
        }
      }

      // items list divider line
      &:before {
        position: absolute;
        left: calc(50% + 14px);
        top: 0;
        width: 2px;
        height: 100%;
        background-color: $secondary;
        content: '';

        @include responsive_min(lg) {
          left: 0;
          top: rem-calc(69);
          width: 100%;
          height: 2px;
        }
      }
    }

    // list item
    &__item {
      position: relative;
      flex: 1 100%;
      margin: 0;

      @include responsive_max(lg) {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: rem-calc(25);
        }
      }

      @include responsive_min(lg) {
        flex: 1;
        margin: 0 rem-calc(15);
        text-align: center;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      // summary item
      &--summary {
        color: $discount-item-color;

        @include responsive_min(lg) {
          margin-right: rem-calc(25);
        }

        // item discount value
        .discount-row__item__info {
          font-size: rem-calc(32);
          color: $discount-item-color;

          @include responsive_max(lg) {
            line-height: rem-calc(38);
          }

          @include responsive_min(lg) {
            font-size: rem-calc(54);
          }
        }
      }

      // highlighted item
      &--highlighted {
        .discount-row__item__info {
          color: $color-green;

          .icon {

            @include responsive_min(lg) {
              top: rem-calc(72);
            }
          }
        }
      }

      // item title
      &__head {
        display: block;
        font-size: rem-calc(14);
        line-height: 1.57143em; // 22px
        color: $primary;

        @include responsive_max(lg) {
          width: 50%;
          padding-right: rem-calc(5);
          text-align: right;

          br {
            display: none;
          }
        }

        @include responsive_min(lg) {
          margin-bottom: rem-calc(40);
          font-size: rem-calc(16);
          line-height: 1.625em; // 26px
        }
      }

      // item discount value
      &__info {
        display: block;
        font-size: rem-calc(24);
        font-weight: 400;
        line-height: rem-calc(32);
        white-space: nowrap;
        color: $secondary;
        background-color: $discount-item-bg;

        @include responsive_max(lg) {
          width: 50%;
          padding-left: rem-calc(36);
        }

        @include responsive_min(lg) {
          font-size: rem-calc(30);
          line-height: rem-calc(60);
        }

        .icon {
          position: absolute;
          left: calc(50% + 16px);
          top: 50%;
          padding: rem-calc(4);
          background-color: #f7f9fa;
          font-size: rem-calc(14);
          color: $secondary;
          transform: translate3d(-50%, -50%, 0);
          transform-origin: 50% 50%;

          @include responsive_max(lg) {

            &:not(.icon-check) {
              font-size: rem-calc(10);
              transform: translate3d(-50%, -50%, 0) rotate(-90deg);
            }
          }

          @include responsive_min(lg) {
            left: 50%;
            top: rem-calc(72);
            transform: translate3d(-50%, -50%, 0);
          }

          &-check {
            font-size: rem-calc(16);
            color: $color-green;
          }
        }
      }
    }
  }

  &__wrapper {
    position: relative;
    margin: rem-calc(20) auto;

    #{$self}__chart {
      grid-gap: 0;
      display: flex;
      flex-flow: row wrap;
      width: 100%;

      .mdc-layout-grid__cell {
        display: flex;
        width: 100%;

        &:first-child .item {
          border-top: none;
        }

        &:last-child .item {
          border-bottom: none;
        }

        .item {
          border: rem-calc(1) solid $secondary;
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          text-align: left;
          min-height: rem-calc(100);
          padding: rem-calc(20);

          &__badge {
            flex: 1 1 0;

            .badge {
              margin: 0 auto;
            }
          }

          &__content {
            flex: 3 3 0;

            .title {
              color: $primary;
              margin: rem-calc(10) auto;
            }

            .body {
              margin: rem-calc(15) auto;

              &.address {
                color: $secondary;
                font-size: rem-calc(16);
                font-weight: 400;

                &:hover,
                &.active {
                  color: $primary;
                }
              }
            }
          }

          &__progress {
            width: 100%;
            display: flex;
            align-items: center;

            .icon {
              flex: 1 1 0;

              .benefits__item__icon {
                border-color: $color-blue-20;
                color: $secondary;
                margin: 0 auto;
              }
            }

            .progress {
              flex: 3 3 0;
            }
          }

          &:first-child {
            border-left: none;
          }

          &:nth-child(odd) {
            padding-right: rem-calc(120);
          }

          &:nth-child(even) {
            padding-left: rem-calc(120);
          }

          &:last-child {
            border-right: none;
          }

        }
      }
    }

    #{$self}__overall {
      height: $discount-chart-overall-badge-size;
      width: $discount-chart-overall-badge-size;
      background-color: $color-white;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 rem-calc(30) rgba(0, 0, 0, 0.1);

      .info {
        position: relative;
        height: 100%;
        padding: rem-calc(15);

        .title,
        .total {
          height: 50%;
          display: flex;
          flex-direction: row;
          text-align: center;
          justify-content: center;
          color: $primary;
        }

        .title {
          align-items: flex-end;
          padding: rem-calc(40) rem-calc(20) rem-calc(10) rem-calc(20);
        }

        .total {
          align-items: flex-start;
        }
      }

      .chart {
        padding: rem-calc(15);
      }
    }

    &--creditcard {
      @include responsive_min(lg) {
        &.mdc-layout-grid__cell {
          margin-top: rem-calc(50);
        }

        padding-top: $discount-chart-overall-badge-size;
      }

      #{$self}__overall {
        transform: translate(-50%, 0);
        top: 0;
      }
    }

    #{$self}__creditcard {
      margin: rem-calc(20) auto 0;
      padding: 0 rem-calc(15);
      max-width: rem-calc(700);
    }
  }
}

@include responsive_max(lg) {
  .discount {
    $self: &;

    &__wrapper {
      border: none;

      .mdc-typography {
        &--headline5 {
          font-size: rem-calc(18);
          line-height: rem-calc(24);
        }

        &--headline6 {
          font-weight: 700;
        }

        &--body1 {
          font-size: rem-calc(14);
          line-height: rem-calc(18);
        }
      }

      #{$self}__chart {
        &__total {

          border: 2px solid $color-primary;
          border-radius: rem-calc(8);
          padding: rem-calc(20) rem-calc(28);

          color: $color-primary;
          font-size: rem-calc(18);
          line-height: rem-calc(24);
          font-weight: bold;

          display: flex;
          align-items: center;

          .icon {
            font-size: rem-calc(24);
            padding-right: rem-calc(12);
          }

          .text {
            @include tighten-letters;
          }

          @media (max-width: 360px) {
            padding: rem-calc(20) rem-calc(8);
            font-size: rem-calc(16);
            justify-content: center;
          }
        }

        .mdc-layout-grid__cell {
          flex-direction: column;
          align-self: flex-end;

          .item {
            flex-wrap: nowrap;
            padding: rem-calc(21) !important;
            border: 1px solid $color-gray-20 !important;
            border-radius: rem-calc(8);

            margin-top: rem-calc(8);

            &__badge {
              flex: 0;
            }

            &__content {
              padding-left: rem-calc(24);

              .title {
                margin: 0;
              }

              .body {
                margin: 0;
                margin-top: rem-calc(4);

                &.address {
                  font-size: rem-calc(14);
                  line-height: rem-calc(18);
                  font-weight: bold;

                  @include tighten-letters;
                }
              }

              .mdc-button {
                margin-top: rem-calc(8);
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
                width: auto;
              }
            }

            &__progress {
              .buffer {
                background-color: $color-primary;
              }

              .icon {
                display: none;

                .benefits__item__icon {
                  margin: 0 auto;
                }
              }

              .mdc-chip {
                display: inline-flex;
              }
            }
          }

          &.summary {
            order: -1;

            .discount__overall {
              transform: none;
              left: 0;
            }
          }
        }
      }

      #{$self}__overall {
        display: none;

        .chart.doughnut .sector {

          &,
          &:before {
            border: none !important;
          }
        }
      }

      #{$self}__creditcard {
        margin: rem-calc(20) auto;
      }
    }
  }
}

@include responsive_min(lg) {
  .discount__chart .mdc-layout-grid__cell .item {

    &--energy {
      .mdc-button {
        color: $secondary;
        font-size: rem-calc(16);
        font-weight: 400;

        &:hover,
        &.active {
          color: $primary;
        }
      }
    }

    &__progress .mdc-chip {
      display: none;
    }
  }
}