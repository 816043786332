$mdc-radio-touch-area: 32px;
$mdc-radio-ui-size: 32px;
$mdc-radio-ui-pct: percentage($mdc-radio-ui-size / $mdc-radio-touch-area);
$mdc-radio-baseline-theme-color: $primary;
$mdc-radio-unchecked-color: $color-gray;

.mdc-radio {

  // inner circle
  &__inner-circle {
    border-width: 8px !important;
    border-radius: 50%;
  }

  // outer circle
  &__outer-circle {
    background-color: $color-white;
  }

  // hide finger circle
  &__background {

    &:before {
      display: none;
    }
  }

  // checked state
  &__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    transform: scale(1) !important;
  }

  // secondary modification
  &--secondary {

    .mdc-radio__inner-circle {
      background-color: $secondary;
    }

    .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
      border-color: $color-white !important;
    }
  }

  // label
  @at-root .mdc-form-field & {

    & + label {
      padding-left: rem-calc(15);
      font-size: rem-calc(16);
      line-height: 1.625em; // 26px
      font-weight: 700;

      @include responsive_min(sm) {
        font-size: rem-calc(18);
        line-height: rem-calc(32);
      }

      a {
        color: #2499d6;
        text-decoration: none;
      }
    }
  }

  @at-root .mdc-form-field.lighter & {

    & + label {
      font-weight: 400;
    }
  }
}
