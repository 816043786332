// clearfix
@mixin clearfix {

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

// truncate text
@mixin truncate-text($width: 100%, $white-space: nowrap, $overflow: hidden, $text-overflow: ellipsis) {
  width: $width;
  white-space: $white-space;
  overflow: $overflow;
  text-overflow: $text-overflow;
}

// reset list style
@mixin reset-list($margin: 0, $padding: 0, $list-style-type: none) {
  margin: $margin;
  padding: $padding;
  list-style-type: $list-style-type;
}

// placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// add custom styles for the scrollbda
@mixin style-scrollbar {
  &::-webkit-scrollbar {
    width: rem-calc(5);
    height: rem-calc(5);
    border-radius: rem-calc(0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem-calc(0);
    background: $color-gray-80;
  }

  &::-webkit-scrollbar-track {
    border-radius: rem-calc(0);
    background: $color-gray-30;
  }
}

// angle edge
// $angle: The angle in degrees: 1 - 45
// $angle-position-y: The Y position of the angle: top | bottom | both
// $angle-position-x: The X position of the angle: left | right
// $angle-position-bottom-x: The X position of the bottom angle if using 'both' for $angle-position-y: left | right
// $fallback: Create a fallback for older browsers: true | false
// $fallback-color: Fallback colour for older browsers: Hex color
@mixin angle-edge($angle, $angle-position-y, $angle-position-x, $angle-position-bottom-x: '', $fallback: true, $fallback-color: #fff) {

  position: relative;
  overflow: hidden;

  // Converts degrees to VW, 100vw = 45deg using this technique
  @if $angle>45 {
    $angle: 0;
    @error 'Invalid angle, it must be between 1-45';
  }

  @if $angle < 46 {
    $angle: convertDegToVW($angle);
  }

  @if $angle-position-bottom-x=='' {
    $angle-position-bottom-x: $angle-position-x;
  }

  $angle-calc-top: calc(0% + #{$angle}vw);
  $angle-calc-bottom: calc(100% - #{$angle}vw);

  $clip-path-top: 0 0,
  100% 0;
  $clip-path-bottom: 100% 100%,
  0 100%;

  $border-width-top: '';
  $border-width-bottom: '';
  $border-color-top: '';
  $border-color-bottom: '';


  @if $angle-position-y=='top'or $angle-position-y=='both' {

    @if $angle-position-x=='left' {
      $clip-path-top: 0 $angle-calc-top, 100% 0;

      $border-width-top: #{$angle + 1}vw 100vw 0 0;
    }

    @if $angle-position-x=='right' {
      $clip-path-top: 0 0, 100% $angle-calc-top;

      $border-width-top: #{$angle + 1}vw 0 0 100vw;
    }

    $border-color-top: $fallback-color transparent transparent transparent;
  }


  @if $angle-position-y=='bottom'or $angle-position-y=='both' {

    @if $angle-position-y=='both'and $angle-position-x !=$angle-position-bottom-x {
      $angle-position-x: $angle-position-bottom-x;
    }

    @if $angle-position-x=='left' {
      $clip-path-bottom: 100% 100%, 0 $angle-calc-bottom;

      $border-width-bottom: 0 100vw #{$angle + 1}vw 0;
    }

    @if $angle-position-x=='right' {
      $clip-path-bottom: 100% $angle-calc-bottom, 0 100%;

      $border-width-bottom: 0 0 #{$angle + 1}vw 100vw;
    }

    $border-color-bottom: transparent transparent $fallback-color transparent;
  }

  $clip-path: polygon($clip-path-top, $clip-path-bottom);
  clip-path: $clip-path;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 10;
    display: block;
    border-style: solid;
  }

  @if $angle-position-y=='top'or $angle-position-y=='both' {
    &::before {
      top: 0;
      border-width: $border-width-top;
      border-color: $border-color-top;
    }
  }

  @if $angle-position-y=='bottom'or $angle-position-y=='both' {
    &::after {
      bottom: 0;
      border-width: $border-width-bottom;
      border-color: $border-color-bottom;
    }
  }


  // Fallback for clip-path with solid colours
  @if $fallback {

    @supports (clip-path: $clip-path) {

      &::before,
      &::after {
        display: none;
      }

    }

  }

}

// Converts degrees to VW, 100vw = 45deg using this technique
@function convertDegToVW($angle) {
  @return round($angle*2.22);
}


@mixin scrollbox($bg-color, $shadow-color) {
  $transparent: rgba(0, 0, 0, 0);

  overflow: auto;
  background:
    linear-gradient(to right, $bg-color 10%, $transparent),
    linear-gradient(to right, $transparent, $bg-color 85%) 0 100%,
    linear-gradient(to right, $shadow-color, $transparent 10%),
    linear-gradient(to left, $shadow-color, $transparent 10%);
  background-attachment: local, local, scroll, scroll;
}

@mixin tighten-letters() {
  letter-spacing: rem-calc(-0.5);
}

@mixin no-tap-box() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin generate-margins($location, $sizes) {
  $map: ("bottom":"b",
      "top":"t",
      "left":"l",
      "right":"r"
    );

  $l: map-get($map, $location);

  @if $l {
    @each $size in $sizes {
      .m#{$l}-#{$size} {
        margin-#{$location}: rem-calc($size) !important;
      }

      .m#{$l}-tablet-#{$size} {
        @include responsive_between(sm, lg) {
          margin-#{$location}: rem-calc($size) !important;
        }
      }

      .m#{$l}-mobile-#{$size} {
        @include responsive_max(sm) {
          margin-#{$location}: rem-calc($size) !important;
        }
      }

      .m#{$l}-mobile-up-#{$size} {
        @include responsive_min(sm) {
          margin-#{$location}: rem-calc($size) !important;
        }
      }
    }

    .m#{$l}-mobile-clear {
      @include responsive_max(sm) {
        margin-#{$location}: 0 !important;
      }
    }

    .m#{$l}-tablet-clear {
      @include responsive_between(sm, lg) {
        margin-#{$location}: 0 !important;
      }
    }

    .m#{$l}-desktop-clear {
      @include responsive_min(lg) {
        margin-#{$location}: 0 !important;
      }
    }
  }
}