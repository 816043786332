@import "../helpers/mixins";
@import "../helpers/functions";
@import "../helpers/variables";

.digital-sign {
    &--button-group {
        display: flex;

        @include responsive_min(md) {
            align-items: center;
            justify-content: end;
        }

        @include responsive_max(md) {
            flex-direction: row;
            justify-content: center;
        }

        @include responsive_max(sm) {
            flex-direction: column;
        }
    }

    &--preview-button {
        margin-right: rem-calc(24);

        @include responsive_max(sm) {
            width: 100%;
            margin-right: 0;
            margin-bottom: rem-calc(16);
        }
    }
}

.digital-sign-minimal-btn {
    width: max-content;

    &--icon {
        color: $color-red;
    }
}