$benefits-item-color: $color-gray-10;
$benefits-item-head-color: $secondary;
$benefits-item-head-count-color: $primary;
$benefits-item-border-color: $secondary;
$benefits-item-icon-color: $secondary;

$benefits-item-tooltip-size: 32;

.benefits {
  margin-top: rem-calc(45);
  margin-bottom: rem-calc(60);

  &__list {
    @include reset-list();
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;

    @include responsive_min(lg) {
      flex-wrap: nowrap;
    }

    &--wide {

      @include responsive_min(sm) {
        margin-right: rem-calc(-30);
      }

      .benefits__item {
        flex: 1 50%;
        text-align: center;

        @include responsive_min(sm) {
          flex: 1;
          text-align: left;
        }
      }
    }
  }

  // list item
  &__item {
    display: flex;
    flex: 1 100%;
    position: relative;
    color: $benefits-item-color;

    @include responsive_max(lg) {

      @at-root .benefits__list:not(.benefits__list--wide) & {
        position: relative;
        flex-wrap: wrap;
        min-height: rem-calc(90);
        padding-left: rem-calc(105);

        &:not(:last-child) {
          margin-bottom: rem-calc(30);
        }

        &:last-child {
          min-height: inherit;
        }

        &.small {
          min-height: rem-calc(60);

          .benefits__item__head {
            margin-bottom: auto;
          }
        }

        &__info {
          max-width: none;
          font-size: rem-calc(12);
          line-height: 1.5em;
        }

        &__head {
          margin-bottom: rem-calc(9);

          small {
            margin-bottom: rem-calc(3);
            font-size: rem-calc(14);
          }

          b {
            font-size: rem-calc(24);
          }
        }

        &__icon {
          position: absolute;
          top: 0;
          left: 0;

          &--circle {
            left: rem-calc(15);
          }
        }

        .mdc-button--link {
          font-size: rem-calc(14);
        }
      }
    }

    @include responsive_max(sm) {

      @at-root .benefits__list:not(.benefits__list--wide) & {

        &.small {

          .benefits__item__head {
            margin-top: auto;
          }
        }
      }
    }

    @at-root .benefits__list--wide & {
      align-items: center;

      @include responsive_max(sm) {
        flex-direction: column;
      }
    }

    @include responsive_min(sm) {
      flex: 1 auto;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0 rem-calc(23);
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding: 0;
    }

    .mdc-button {
      white-space: nowrap;
    }

    // item icon
    &__icon {
      display: block;
      flex-shrink: 0;
      border-radius: 50%;
      text-align: center;
      position: relative;

      @include responsive_min(sm) {
        margin-right: rem-calc(15);
      }

      &--circle {
        width: rem-calc(60);
        height: rem-calc(60);
        border: 2px solid $benefits-item-border-color;
        line-height: rem-calc(56);
        color: $primary;

        @at-root .benefits__list--wide & {

          @include responsive_max(sm) {
            border: 0 none;
          }
        }
      }

      &--circle-sector {
        position: relative;
        width: rem-calc(90);
        height: rem-calc(90);
        line-height: rem-calc(90);
        color: $benefits-item-icon-color;

        // radial diagram
        .radial-graph {
          position: absolute;
          top: 0;
          left: 0;

          @at-root .benefits__list--wide & {

            @include responsive_max(sm) {
              display: none;
            }
          }
        }
      }

      .icon {
        font-size: rem-calc(24);
        line-height: inherit;
        z-index: 1;
      }

      .benefits-item__tooltip {
        position: absolute;
        width: rem-calc($benefits-item-tooltip-size);
        height: rem-calc($benefits-item-tooltip-size);
        top: rem-calc(-7);
        right: rem-calc(-7);

        font-size: rem-calc(10);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        background-color: $color-pink;
        color: $color-white;
        border: 2px solid $color-white;

        .tooltip__wrapper {
          position: relative;
        }

        .tooltip-text__content {
          $top: 50%;
          $left: calc(-50% - 16px);

          line-height: 1;
          top: initial;
          left: initial;
          right: initial;
          transform: translate($left, $top);

          text-align: left;
        }
      }
    }

    // item title
    &__head {
      display: block;
      font-weight: 600;
      white-space: nowrap;

      @include responsive_min(sm) {
        padding-right: rem-calc(30);
        align-self: center;
      }

      small {
        display: block;
        margin-bottom: rem-calc(6);
        font-size: rem-calc(16);
        font-weight: 400;
        line-height: 1.25em; // 20px
        text-transform: uppercase;
        color: $benefits-item-head-color;
      }

      b {
        font-size: rem-calc(30);
        font-weight: 400;
        line-height: 1em; // 20px
        color: $benefits-item-head-count-color;
      }
    }

    // item benefits value
    &__info {
      max-width: rem-calc(140);
      font-size: rem-calc(16);
      line-height: 1.375em; // 22px
      margin: auto;
    }
  }
}