@import "../../helpers/variables";

.drawer-desktop {
    top: 0;
    background-color: $color-primary !important;
    width: auto !important;
    padding-left: rem-calc(15);

    &--title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: rem-calc(35);

        &>img {
            padding-right: rem-calc(110);
            width: 330px;
        }
    }

    .close-icon {
        color: $color-white;
        font-size: rem-calc(24) !important;
        width: 24px !important;
        height: 24px !important;
    }

    &--content {
        padding-left: rem-calc(80);

        &>li {
            padding-bottom: rem-calc(40);
        }

        &>li>a {
            color: $color-white;
            font-weight: bold;
            font-size: rem-calc(18);

            &:hover {
                color: $color-blue;
            }
        }
    }
}