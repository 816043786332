@import "../helpers/_functions";
@import "../helpers/_variables";


$qr-overlay-padding: 48;
$qr-info-icon-size: 28;

.card-qr-overlay {
    $self: &;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    overflow: auto;

    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    align-items: center;

    &--code {
        background-color: $color-primary;
        color: $color-white;
        padding: rem-calc($qr-overlay-padding);
    }

    &--instructions {
        background-color: $color-white;
        padding: rem-calc(24);

        #{$self}__title {
            align-self: flex-start;
            text-transform: none;
            font-weight: bold;
            letter-spacing: rem-calc(-0.5);
        }

        #{$self}__image-wrapper {
            flex-grow: 0;
        }

        #{$self}__text {
            font-size: rem-calc(14);
            line-height: rem-calc(18);
            width: 100%;
        }
    }

    &__info {
        position: absolute;
        display: flex;

        right: rem-calc($qr-overlay-padding - ($qr-info-icon-size / 2));
        top: rem-calc($qr-overlay-padding - ($qr-info-icon-size / 2));
        font-size: rem-calc($qr-info-icon-size);
        height: rem-calc($qr-info-icon-size);
        width: rem-calc($qr-info-icon-size);
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
            max-width: rem-calc(140);
        }

        .number {
            font-size: rem-calc(14);
            line-height: rem-calc(32);
            font-weight: bold;
            text-align: center;
            margin-bottom: rem-calc(48);
        }
    }

    &__image-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;

        img {
            width: 100%;
            height: auto;
            max-width: 45vh;
            max-height: 100%;
        }

        .qr-loader {
            display: flex;
            position: relative;
            flex: 1;
            align-items: center;
            max-width: 50vh;

            &:after {
                content: '';
                display: block;
                padding-top: 100%;
            }
        }
    }

    &__footer {
        margin-bottom: rem-calc(18);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    &__close-button {
        background-color: $color-blue-10;
        height: rem-calc(48);
        min-height: rem-calc(48);
        width: rem-calc(48);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
    }
}