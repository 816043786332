.fuel-plus-electricity {
  &-banner * {
    z-index: 0;
  }

  &-banner {
    padding: 68% 0 0 0; // 68% is current ratio of banner image, if image is changed, ratio might need to be changed too
    margin-bottom: rem-calc(20);
    background-size: cover;

    &:before,
    &:after {
      height: 6%;
    }

    &--fuelling {
      background-image: url('../../images/fuel-plus-electricity-banner.jpg');
    }
  }
  &__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    img {
      width: 100%;
      height: auto;
      max-width: 20vh;
      max-height: 100%;
    }

    .qr-loader {
      display: flex;
      position: relative;
      flex: 1;
      align-items: center;
      max-width: 50vh;

      &:after {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
  }
}