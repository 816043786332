$message-bg-alert: #faf0f0;
$message-bg-done: #effaeb;
$message-bg-warn: #fcf3e3;
$message-color: $color-gray-10;
$message-link-color: $color-blue-10;

.message {
  position: relative;
  font-size: rem-calc(16);
  line-height: 1.625em; // 26px
  color: $message-color;
  border-radius: rem-calc(8);
  
  display: flex;
  justify-content: center;

  @include theme() {
    background-color: t($t-color-background-info);
  }
  
  $this: &;

  &:not(.message--wide) {
    min-height: rem-calc(116);
    padding: rem-calc(99) rem-calc(20) rem-calc(20);
    text-align: center;

    @include responsive_min(sm) {
      padding: rem-calc(10) rem-calc(30) rem-calc(10) rem-calc(124);
      line-height: 1.667em; // 30px
      text-align: left;
    }
  }

  @include responsive_min(sm) {
    padding: rem-calc(10) rem-calc(30) rem-calc(10) rem-calc(124);
    line-height: 1.667em; // 30px
    text-align: left;
  }

  &.hide {
    display: none !important;

    &.open {
      display: flex !important;
    }
  }

  &.small {
    min-height: rem-calc(56);

    @include responsive_min(sm) {
      padding-left: rem-calc(56);
      &>.icon {
        top: rem-calc(15);
        left: rem-calc(15);
        font-size: rem-calc(25);
      }
    }
  }

  &.smaller {
    min-height: rem-calc(110);
  }

  // message icon
  & > .icon {
    position: absolute;
    top: rem-calc(20);
    left: 50%;
    font-size: rem-calc(60);
    line-height: 1;
    
    @include theme() {
      color: t($t-color-info);
    }

    @include responsive_max(sm) {
      transform: translateX(-50%);
    }

    @include responsive_min(sm) {
      left: rem-calc(32);
      top: rem-calc(30);
    }

    &.align {

      @include responsive_min(sm) {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  // add margin for message block
  &.up {

    @include responsive_min(sm) {
      margin-top: rem-calc(45);
    }
  }

  &.down {
    margin-bottom: rem-calc(20);

    @include responsive_min(sm) {
      margin-bottom: rem-calc(45);
    }
  }

  // alert message
  &.message--alert {
    background-color: $message-bg-alert;

    &>.icon {
      color: #db4d3d;
    }
  }

  &.message--alert-neutral {
    &>.icon {
      color: #db4d3d;
    }
  }

  // done message
  &.message--done {
    background-color: $message-bg-done;

    &>.icon {
      color: $color-green;
    }
  }

  // done message
  &.message--warn {
    background-color: $message-bg-warn;

    &>.icon {
      // font-size: rem-calc(52);
      color: $color-orange;
    }
  }

  // added minus margins
  &.outer {
    margin-left: rem-calc(-32);
    margin-right: rem-calc(-32);

    @include responsive_max(sm) {

      &>.icon {
        top: rem-calc(20);
      }
    }
  }

  // vertical align all content
  &.align {
    align-items: center;

    .message__head {
      flex: 1 auto;
      margin: auto 0;
    }
  }

  // message with action buttons (close etc.)
  &--action {
    padding-right: 15%;
  }

  // message head
  &__head {

    &:not(:last-child) {
      margin-bottom: rem-calc(15);

      @include responsive_min(sm) {
        margin-bottom: rem-calc(10);
      }
    }

    .message__link {
      vertical-align: initial;

      &.mdc-button--text.mdc-button--primary {

        @include responsive_max(sm) {
          clear: left;
          margin-top: rem-calc(15);
          font-size: rem-calc(14);

          .icon {
            margin-right: rem-calc(6);
            font-size: rem-calc(10);
          }
        }
      }
    }
  }

  // message description
  &__description {

    p:not(:last-child) {
      margin-bottom: rem-calc(20);
    }

    a {
      color: $color-blue-10;
      text-decoration: none;
      transition: color .15s;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  // message action buttons
  &__action {
    position: absolute;
    top: rem-calc(30);
    right: rem-calc(30);

    &.mdc-theme--secondary {
      transition: color .15s;

      &:hover,
      &:focus {
        color: $primary !important;
      }
    }

    .icon {
      font-size: rem-calc(22);
    }
  }

  // wide modification for mobile
  &--wide {

    @include responsive_max(sm) {
      margin-left: rem-calc(-24);
      margin-right: rem-calc(-24);
      padding: rem-calc(67) rem-calc(24) rem-calc(24);

      &.align {
        display: block;
      }

      // icon
      &>.icon {
        margin: 0;
        top: rem-calc(20);
        left: rem-calc(24);
        font-size: rem-calc(32) !important;
        line-height: 1;
        transform: none;

        &-alert {
          font-size: rem-calc(28) !important;
        }
      }

      // message head block
      #{$this}__head {
        margin-top: 0 !important;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      // message action buttons
      #{$this}__action {
        top: rem-calc(24);
        right: rem-calc(24);

        .icon {
          font-size: rem-calc(22);
        }
      }
    }
  }

  // New modification for mobile, smaller than others
  &--inline {
    min-height: auto !important;
    padding: rem-calc(16) !important;
    text-align: start !important;

    &#{$this}--notice {
      background-color: $color-blue-50;
    }

    #{$this}__description {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      vertical-align: middle;

      &:first-of-type {
        display: inline-block;

        p {
          font-weight: bold;
        }
      }

      .mdc-button--text {
        vertical-align: bottom;
      }
    }

    .icon {
      position: relative;
      top: unset;
      left: unset;
      transform: none;
      vertical-align: middle;
      font-size: rem-calc(24);
      line-height: 1;
      margin-right: rem-calc(8);
    }
  }

  &.column-direction {
    flex-direction: column;
  }
}