

$sector-padding: rem-calc(1);
$sector-outer-padding: rem-calc(3);
$sector-border-width: rem-calc(4);
$sector-done-border-width: rem-calc(8);

.chart {
  margin: 0 rem-calc(-24);
  padding: rem-calc(30) rem-calc(24);

  @include responsive_min(sm) {
    margin: 0;
    padding: rem-calc(45) rem-calc(45) rem-calc(60);
  }

  &:not(:last-child) {
    margin-bottom: rem-calc(30);

    @include responsive_min(sm) {
      margin-bottom: rem-calc(45);
    }
  }

  // top section
  &-top {
    display: flex;
    flex-direction: column;
    margin-bottom: rem-calc(30);

    @include responsive_min(sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem-calc(45);
    }

    &__title {

      @include responsive_max(sm) {
        margin-bottom: rem-calc(18);
        font-size: rem-calc(20) !important;
      }
    }
  }

  // content section
  &-content {

    @include responsive_max(sm) {
      margin-right: rem-calc(-24);
      overflow: hidden;
    }

    .diagram {
      margin-bottom: rem-calc(30);
      background-color: $color-white;
      box-shadow: 0px 2px 6px rgba($color-black, 0.04);

      .table-overflow__inner {
        @include responsive_max(sm) {
          padding: rem-calc(26) 0 rem-calc(20) rem-calc(20);
        }
      }

      @include responsive_min(sm) {
        margin-bottom: rem-calc(45);
        padding: rem-calc(30) rem-calc(22) rem-calc(20) rem-calc(20);
      }

      .legend {
        margin: 0 0 rem-calc(50) rem-calc(10);
        font-size: rem-calc(13);
        line-height: rem-calc(16);
        color: #4d4f5c;

        @include responsive_min(sm) {
          margin-left: rem-calc(30);
        }

        ul {
          @include reset-list();
          overflow: hidden;
        }

        li {
          float: left;
          margin-right: rem-calc(30);
          cursor: pointer;

          &.hidden {
            text-decoration: line-through;
          }
        }

        span {
          float: left;
          width: rem-calc(18);
          height: rem-calc(10);
          margin-right: rem-calc(10);
          margin-top: rem-calc(3);
          border-radius: rem-calc(5);
          background-color: #8f8bff;
        }
      }

      img {
        max-width: 100%;
      }

      canvas {

        @include responsive_max(sm) {
          width: auto;
          height: rem-calc(285);
        }

        @include responsive_min(sm) {
          width: 100% !important;
        }
      }
    }

    .accordion {
      margin-left: 0;

      @include responsive_min(sm) {
        margin-left: rem-calc(9);
      }

      @include responsive_max(sm) {
        margin-right: 0;

        &__item__intro {
          padding-right: 0;
        }
      }
    }

    @include responsive_max(sm) {

      .table {

        &-overflow {
          margin: 0;
        }

        th {
          padding-top: rem-calc(14); 
          padding-bottom: rem-calc(14);
          line-height: rem-calc(17);
        }

        tbody {

          tr:first-child {
            border-top: 0 none;
          }
        }
      }
    }
  }

  &__links {
    @include reset-list($margin: 0 rem-calc(-24));
    border-top: 1px solid #e3e5e6;
    border-bottom: 1px solid #e3e5e6;
    background: none;

    @include responsive_min(sm) {
      width: auto;
      margin: 1px 0 0;
      border: 0 none;
    }

    &:after {

      @include responsive_max(sm) {
        top: rem-calc(14);
        right: rem-calc(23);
      }
    }

    & > li {

      &:after {
        display: none;
      }

      @include responsive_min(sm) {
        float: left;

        &:not(:first-child) {
          margin-left: rem-calc(31);
        }
      }

      &.active {

        & > a {
          position: relative;
          color: $primary;

          @include responsive_min(sm) {

            &:after {
              position: absolute;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $primary;
              content: '';
            }
          }
        }
      }

      & > a {
        display: block;
        padding: rem-calc(15) rem-calc(24);
        font: {
          size: rem-calc(15);
          weight: 400 !important;
        }
        line-height: 1.625em; // 26px
        color: $color-blue-10;
        text-decoration: none !important;
        transition: color .15s;

        @include responsive_min(sm) {
          display: inline-block;
          padding: 0;
          font-size: rem-calc(18);
          line-height: 1.667em; // 30px

          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }
    }
  }

  &.doughnut {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row wrap;
      margin: 0;
      padding: 0;

      .sector {
          display: none;
          width: 50%;
          height: 50%;
          padding: $sector-padding;

          &:before {
              display: block;
              content: "";
              position: relative;
              height: 100%;
              width: 100%;
              border: $sector-border-width solid $color-gray;
          }

          &:nth-child(-n + 4) {
              display: block;
          }

          &:first-child {
              padding: $sector-outer-padding $sector-padding $sector-padding $sector-outer-padding;
              &:before {
                  border-right: none;
                  border-bottom: none;
                  border-top-left-radius: 100%;
              }
          }

          &:nth-child(2) {
              padding: $sector-outer-padding $sector-outer-padding $sector-padding $sector-padding;
              &:before {
                  border-left: none;
                  border-bottom: none;
                  border-top-right-radius: 100%;
              }
          }

          &:nth-child(3) {
              padding: $sector-padding $sector-padding $sector-outer-padding $sector-outer-padding;
              &:before {
                  border-top: none;
                  border-right: none;
                  border-bottom-left-radius: 100%;
              }
          }

          &:nth-child(4) {
              padding: $sector-padding $sector-outer-padding $sector-outer-padding $sector-padding;
              &:before {
                  border-top: none;
                  border-left: none;
                  border-bottom-right-radius: 100%;
              }
          }

          &.done {
              padding: $sector-padding;
              &:before {
                  border-width: $sector-done-border-width;
                  border-color: $color-green;
              }
          }
      }
  }
}
.table-overflow__inner {
  overflow: auto;
}
@include responsive_max(lg) {
  .chart {
    &.doughnut {
      .sector {
        padding: 0 !important;
        flex: 1 1 0;
        border-right: rem-calc(2) solid $color-white;

        &:last-child {
          border-right: none;
        }

        &:before {
          border: none !important;
          border-top: $sector-border-width solid $color-gray !important;
          border-radius: 0 !important;
        }

        &.done {
          &:before {
            border-color: $color-green !important;
          }
        }
      }
    }
  }
}