.table-wrap {
  overflow: auto;
  margin-bottom: rem-calc(30);
}

%header {
  width: 100%;
  font-weight: 600;

  @include theme() {
    background-color: t($t-color-background);
  }

  >* {
    @include theme() {
      border-bottom: 1px solid t($t-color-link);
    }
  }
}

%header-cell {

  &.orderable {
    cursor: pointer;
    user-select: none;
  }

  &.active:after {
    content: "";
    position: absolute;
    top: 25px;
    width: 0;
    height: 0;
    margin-left: 5px;

    @include theme() {
      border-bottom: 7px solid t($t-color-primary);
    }

    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }

  .descending & {
    &.active:after {
      border-bottom: none;

      @include theme() {
        border-top: 7px solid t($t-color-primary);
      }
    }
  }
}

%cell {
  position: relative;
  padding: rem-calc(16) 0 rem-calc(16) rem-calc(24);

  &:last-of-type {
    padding-right: rem-calc(24);
  }
}

%row {
  border-bottom: 1px solid $color-gray-30;

  &.no-padding>* {
    padding: 0;
  }

  &.no-border {
    border: none;
  }
}

%expanded-row {

  background-color: $color-gray-30;

  >*:last-of-type {
    padding-right: 0;
  }

}


%footer {
  width: 100%;

  @include theme() {
    background-color: t($t-color-background);
  }

  >*:first-of-type() {
    @include theme() {
      border-top: rem-calc(1.5) solid t($t-color-link);
    }
  }
}

.table-list {
  $self: &;

  width: 100%;
  white-space: nowrap;
  text-align: left;
  line-height: rem-calc(24);
  font-size: rem-calc(16);

  @include responsive_max(sm) {
    td,
    td:last-of-type {
      padding: 0.25rem 0 0.25rem 0.25rem;
      font-size: rem-calc(14);
    }
  }

  thead {
    @extend %header;
  }

  th,
  td {
    @extend %cell;
  }

  tr {
    @extend %row;
  }

  th {
    @extend %header-cell;
  }

  .bold {
    font-weight: 600;
  }

  tfoot {
    @extend %footer;
  }

  &__expanded-row {
    @extend %expanded-row;
  }

  &--nested {
    display: table;
    width: 100%;

    #{$self}__header {
      display: table-header-group;
      font-weight: 600;

      @include theme() {
        background-color: t($t-color-background-darker);
        border-bottom: 1px solid t($t-color-link);
      }
    }

    #{$self}__row {
      display: table-row;

      @include theme() {
        background-color: t($t-color-background);
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    #{$self}__cell {
      display: table-cell;
      padding: rem-calc(8);
    }
  }
}


.table {
  display: table;

  .thead {
    display: table-header-group;

    @extend %header;
  }

  .tbody {
    display: table-row-group;
  }

  .tfoot {
    display: table-footer-group;
    @extend %footer;
  }

  .tr {
    display: table-row;
    @extend %row;
  }

  .th,
  .td {
    display: table-cell;
    @extend %cell;
  }

  .th {
    @extend %header-cell;
  }

  .colspan {
    max-width: 1px;
    overflow: visible;
  }
}

.status-label {
  font-size: rem-calc(12);
  padding: rem-calc(4);
  color: $color-white;
  border-radius: rem-calc(4);
  font-weight: 600;
  white-space: nowrap;

  &--Active {
    background-color: $color-green-10;
  }

  &--Ok {
    background-color: $color-green-10;
  }

  &--Archived {
    background-color: $color-gray-80;
  }

  &--InProgress {
    background-color: $color-orange-30;
  }

  &--InProduction {
    background-color: $color-orange-30;
  }

  &--Closed {
    background-color: $color-red;
  }

  &--ForHomeEvUse {
    background-color: $color-orange-30;
  }

  &--ClosedStoplist {
    background-color: $color-red-100;
  }

  &--Inactive {
    background-color: $color-white;
    color: $color-gray-90;
    border: 1px solid;
    border-color: $color-gray-90;
  }
}

.expanded-item-cell {
  display: flex;
  align-items: center;
  padding: rem-calc(10) rem-calc(25);

  @include responsive_max(sm) {
    font-size: rem-calc(14);
    white-space: break-spaces;
  }

  &__title {
    @include responsive_max(sm) {
      margin-right: rem-calc(10);
    }
  }

  & > div {
    width: 50%;
  }
}

.expandable-icon-cell {
  text-align: right;
}

.border-bottom {
  border-bottom: 1px solid;
  border-color: $color-gray-20;
}

.filter-list {
  padding: rem-calc(40);
  border-radius: rem-calc(10);

  @include theme() {
    border: 1px solid t($t-color-primary);
  }
}

// this is a hack, sorry
.large-address-label {
  @include responsive_max(sm) {
    margin-top: rem-calc(85) !important;
  }
}