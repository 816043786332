@import "floating-label";
@import "@material/floating-label/mixins";

.mdc-notched-outline {
    &.mdc-notched-outline--notched {
        .mdc-notched-outline__notch {
            border-top: 1px solid;
        }
    }
    .mdc-floating-label {
        white-space: normal;
        &.mdc-floating-label--shake{
            animation-name: mdc-floating-label-shake-float-above--horizontal-shake-only;
            @include mdc-floating-label-shake-keyframes(-horizontal-shake-only, 0%, 0%, 1);
        }
    }
}