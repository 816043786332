.energy-widget {
    padding: rem-calc(21);
    border: 1px solid $color-gray-20;
    border-radius: rem-calc(8);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    &__title {
        font-weight: bold;
        margin-bottom: rem-calc(8);

        @include theme() {
            color: t($t-color-primary);
        }
    }

    &__icon {
        font-size: rem-calc(24);
        padding-right: rem-calc(12);
        line-height: rem-calc(32) !important;

        @include theme() {
            color: t($t-color-primary);
        }
    }

    &__time {
        color: $color-gray-50;
        font-size: rem-calc(14);
    }

    &__data {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    &__item {
        display: flex;
        flex-direction: column;
        color: $color-gray-50;
        flex: 1 0 0;
        align-items: center;

        // &__subtitle {}

        &:last-of-type {
            padding-left: rem-calc(8);
        }

        &__value {
            font-weight: 600;
            font-size: rem-calc(32);
            line-height: rem-calc(48);
            letter-spacing: rem-calc(-0.5);

            @include theme() {
                color: t($t-color-secondary);
            }
        }

        &__unit {
            font-size: rem-calc(14);
        }
    }

    @media (max-width:500px) {
        &__data {
            flex-direction: column;
        }

        &__item:last-of-type {
            padding-left: 0;
            padding-top: rem-calc(8);

        }
        &__item:not(:last-child) {
            margin-bottom: rem-calc(10);
        }
    }

}
