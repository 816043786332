@import "../helpers/_variables";

$alert-bg: $color-red-110;
$alert-icon: $color-red-20;
$warning-bg: $color-orange-60;
$warning-icon: $color-orange-10;


.notification-icon {
    @include responsive_max(sm) {
        line-height: 1;

        @include theme() {
            color: t($t-color-primary);
        }

        .marked:after {
            width: 0.75rem !important;
            height: 0.75rem !important;
        }
    }

    @include responsive_min(sm) {
        .marked:after {

            width: rem-calc(10) !important;
            height: rem-calc(10) !important;
            top: 2px !important;
            right: -5px !important;

            @include theme() {
                border: 2px solid t($t-color-secondary);
            }
        }
    }
}

.notifications-panel {
    width: rem-calc(366);
    max-width: rem-calc(366);
    max-height: rem-calc(600);
    position: relative;


    &__header {
        padding: rem-calc(16);

        @include theme() {
            border-bottom: 1px solid t($t-color-link);
        }
    }

    &__content {
        padding: 0;
        max-height: rem-calc(450);
        overflow-y: auto;

        &--no-items {
            padding: rem-calc(16)
        }
    }

    &__footer {
        padding: rem-calc(16);
        border-top: 1px solid #e5e5e5;
        display: flex;
        justify-content: space-between;
    }

    &:before {
        content: '';
        position: absolute;
        right: 9px;
        top: -9px;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid white;
        z-index: 110;
    }

    .notification-item {
        margin: 0 !important;
        border-radius: 0;
        border: none;

        &:not(:last-of-type) {
            border-bottom: 1px solid darken($color-gray-30, 4%);
        }

        &--unread:after {
            content: '';
            width: rem-calc(7);
            height: rem-calc(7);
            border-radius: 50%;
            display: block;
            position: absolute;
            top: rem-calc(8);
            right: rem-calc(8);

            @include theme() {
                background-color: t($t-color-secondary);
            }
        }


        &__read-status {
            display: none;
        }

        &--clickable {
            cursor: pointer;
        }
    }


    &--fullpage {

        max-height: 100%;
        max-width: 100%;
        width: 100%;

        &:before {
            right: 78px;
        }
    }
}

.notification-item {
    $self: &;

    padding: rem-calc(16);
    position: relative;
    display: flex;
    align-items: flex-start;
    border-radius: rem-calc(4);

    border: 1px solid $color-gray-30;

    &:not(:last-of-type) {
        margin-bottom: rem-calc(8);
    }


    &__icon {
        position: relative;

        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        width: rem-calc(40);
        height: rem-calc(40);

        .icon {
            font-size: rem-calc(22);
        }

        @at-root #{$self}--Unknown & {
            background-color: $color-gray-20;
        }

        @at-root #{$self}--Test & {
            background-color: $color-gray-10;
            color: $color-white;
        }

        @at-root #{$self}--NewInvoice &,
        #{$self}--NewOccInvoice & {

            @include theme() {
                background-color: t($t-color-primary);
                color: $color-white;
            }
        }

        @at-root #{$self}--Community & {
            background-color: $color-green;
        }

        @at-root #{$self}--LoyaltyProgram & {
            background-color: $color-pink-10;
        }

        @at-root #{$self}--Marketing & {
            @include theme() {
                background-color: t($t-color-secondary);
            }
        }

        @at-root #{$self}--FamilyGroup & {
            @include theme() {
                background-color: t($t-color-secondary);
            }
        }
    }

    &__content {
        flex: 1 1 100%;
        padding-left: rem-calc(16);
    }

    &__action {
        flex: 1 1 0;
        align-self: center;
        padding-left: rem-calc(16);
        white-space: nowrap;
    }

    &__text {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
    }

    &__title {
        font-weight: 600;
    }

    &__date {
        font-size: rem-calc(14);
        line-height: rem-calc(16);
        color: $color-gray-80;
    }


    &__read-status {
        display: flex;
        border-left: 1px solid $color-gray-30;
        margin-left: rem-calc(16);
        color: $color-gray-80;
    }

    &--alert {
        background-color: $alert-bg;

        #{$self}__icon {
            color: $alert-icon;

            .icon:before {
                font-weight: bold;
            }
        }
    }

    &--warning {
        background-color: $warning-bg;

        #{$self}__icon {
            color: $warning-icon;

            .icon:before {
                font-weight: bold;
            }
        }
    }

    &--unread {

        #{$self}__read-status {

            @include no-tap-box;

            @include theme() {

                &,
                &.mdc-icon-button {
                    color: t($t-color-secondary);
                }

                .icon {

                    &:hover,
                    &:focus,
                    &:active {
                        &:before {
                            text-shadow: 0 0 5px t($t-color-secondary);
                        }
                    }
                }
            }
        }
    }

    &--has-no-action {
        #{$self}__text {
            font-style: italic;
        }
    }

    @include responsive_max(sm) {
        &__text {
            font-size: rem-calc(14);
            line-height: rem-calc(22);
        }

        &__date {
            font-size: rem-calc(12);
            line-height: rem-calc(14);
        }

        &__action {
            white-space: normal;
        }

        &__read-status {
            .mdc-icon-button {
                width: rem-calc(24);
                height: rem-calc(24);
                padding: 0;
            }
        }
    }
}