@import "../helpers/variables";
@import "../helpers/functions";

.help-and-contacts {
    p {
        margin-bottom: 5px;
    }

    img {
        margin-bottom: 10px;
    }
}

.help-contact {
    border-bottom: 1px solid;
}

.phone-wrapper {
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 35%;

    &>img {
        margin-left: auto;
        margin-right: auto;
    }

    &>p {
        margin-top: 5px;
    }
}

.email-wrapper {
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 60%;

    &>img {
        margin-left: auto;
        margin-right: auto;
    }

    &>p {
        margin-top: 5px;
    }

}

.info-wrapper {
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 80%;
    width: 325px;
}

.contact-block {
    display: flex;
    flex-direction: column;
    padding: rem-calc(16);
    border-radius: rem-calc(8);

    @include theme() {
        background-color: t($t-color-background);
    }

    &:not(:first-child) {
        margin-top: rem-calc(16);
    }

    &__title {
        flex: 1 0 100%;
    }

    &__actions {
        padding-top: rem-calc(16);
        display: flex;
    }

    &__action {
        display: flex;
        flex: 1 0 50%;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        &__icon {
            font-size: rem-calc(48);

            &.icon-envelope {
                font-size: rem-calc(40);
            }
        }
    }
}