@import "../../helpers/variables";
@import "../../base/typography";

div.mdc-menu {

  &.mdc-select__menu {
    border-radius: 0;

    @include theme() {
      border: 2px solid t($t-color-secondary);
    }

    box-shadow: none;

    .mdc-list {
      padding: 0;
      font-size: rem-calc(16);
      line-height: 1.625em;
      letter-spacing: 0;
      color: $color-gray-10;

      &-item {
        height: rem-calc(48);
        padding: 0 rem-calc(16);
        color: inherit;

        @include responsive_min(sm) {
          height: rem-calc(60);
        }

        &:before,
        &:after {
          display: none;
        }

        &--activated,
        &:hover,
        &:focus {
          background-color: #f5f5f5;
          color: $color-gray-10 !important;
        }

        .flag {
          float: left;
          margin-right: rem-calc(8);
          height: auto;
          box-shadow: 0px 1px 2px rgba($color-black, .25);

          @include responsive_max(sm) {
            max-width: rem-calc(24);
          }
        }

        &__text {

          // fix for FF
          &:focus {
            outline: none;
          }
        }

        &--field {
          border-top: 1px solid #d8d9e2;
          border-bottom: 1px solid #d8d9e2;
          padding: 0 !important;
          background-color: $color-white !important;

          .mdc-text-field {
            margin-top: 0;

            &.mdc-text-field--with-trailing-icon .mdc-text-field__input {
              padding-right: 16px;
              padding-left: 54px !important;
            }

            .mdc-text-field__icon.icon-search {
              left: rem-calc(18);
              right: auto;
              color: $color-gray-10;
            }

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
              border-color: $color-white !important;
            }
          }
        }
      }
    }
  }

  &.mdc-menu--custom {
    border-radius: 0;
    box-shadow: 0 rem-calc(24) rem-calc(48) rgba($color-black, .16);

    .mdc-list {
      padding: rem-calc(10) 0;
      letter-spacing: 0;
      color: #2499d6;

      @include responsive_min(sm) {
        padding: rem-calc(20) 0;
      }

      &-item {
        height: rem-calc(30);
        padding: 0 rem-calc(18);
        color: inherit !important;

        &:hover,
        &:focus {
          color: $primary !important;
        }

        &:before,
        &:after {
          display: none;
        }

        &:not(:last-child) {
          margin-bottom: rem-calc(10);
        }

        &--activated {
          color: $primary !important;
        }

        &__text {

          // fix for FF
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  &.mdc-menu--notifications {
    overflow: visible;

    //   @include responsive_min(sm) {
    //     width: rem-calc(320);
    //   }
  }

  &.mdc-menu--links {
    width: 100vw;
    border-radius: 0;
    box-shadow: 0 rem-calc(30) rem-calc(60) rgba($color-black, .15);

    @include responsive_max(sm) {
      position: static;
      top: auto;
      left: auto;
      max-width: none;
      width: auto;
      box-shadow: none;
    }

    @include responsive_min(sm) {
      width: rem-calc(200);
    }

    &.wide {
      margin-left: rem-calc(-24);
      margin-right: rem-calc(-24);

      @include responsive_min(sm) {
        margin: 0;
      }
    }
  }

  &-surface {

    &--open,
    &.open {
      display: block;
      opacity: 1;
    }

    .mdc-text-field~& {
      @extend .mdc-menu--custom;
      top: 100%;
    }

    .mdc-select>& {
      @extend .mdc-select__menu;
      left: 0;
      top: rem-calc(48);
      margin-top: -2px;

      @include responsive_min(sm) {
        top: rem-calc(60);
      }
    }

    @include responsive_max(md) {
      max-width: 100%;
    }
  }

  &.full-width {
    width: 100%;
    left: 0;
  }

  // primary menu in the header
  &--main {

    @include responsive_min(sm) {
      margin-top: rem-calc(20);
    }

    .mdc-list {
      display: flex;
      flex-direction: column;
      padding: 0;

      @include responsive_min(sm) {
        flex-direction: row;
      }
    }

    .mdc-menu {
      margin-right: rem-calc(-60);

      @include responsive_min(sm) {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: rem-calc(160);
        margin: 0;
        padding: rem-calc(28) 0;
        box-shadow: 0 rem-calc(8) rem-calc(48) rgba($color-black, .32);
        background-color: $color-white;
        z-index: 103;
      }

      .mdc-list {
        width: 100%;
        padding: rem-calc(19) rem-calc(60) rem-calc(7) 0;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;

        @include responsive_min(sm) {
          border-bottom: 0 none;
          padding: 0;
          align-items: stretch;
          text-align: left;
        }

        .mdc-list-item {
          display: block;
          margin: 0;
          padding: rem-calc(8) rem-calc(26) rem-calc(8) 0;
          font-size: rem-calc(16);
          line-height: 1.875em;
          color: $color-blue-10;

          @include responsive_min(sm) {
            padding: rem-calc(8) rem-calc(48) rem-calc(8) rem-calc(58);
            line-height: 1.66667;
          }

          &:hover {
            color: $color-white;

            @include responsive_min(sm) {
              background-color: $secondary;
            }
          }

          &:before {
            display: block;
            left: auto;
            right: 0;
            top: rem-calc(10);
            width: auto;
            height: auto;
            background: none;
            @extend .icon-triangle;
            font-size: rem-calc(10);
            line-height: inherit;
            opacity: 1;
            transform: rotate(90deg);

            @include responsive_max(sm) {
              color: $secondary !important;
            }

            @include responsive_min(sm) {
              top: rem-calc(12);
              right: auto;
              left: rem-calc(31);
              transform: rotate(-90deg);
            }
          }
        }
      }
    }

    // primary navigation link
    .mdc-list-item {
      height: auto;
      text-decoration: none;
      transition: all .15s;
      overflow: visible;

      &:before,
      &:after {
        display: none;
      }

      &.mdc-list-submenu {

        @include responsive_max(sm) {

          &.active {
            margin-bottom: rem-calc(19);

            .mdc-menu {
              display: block;
            }

            .mdc-list {
              right: 0;
              opacity: 1;
            }
          }

          .mdc-list {
            position: relative;
            right: -100px;
            opacity: 0;
            transition: all 2s 1s;
          }
        }

        @include responsive_min(sm) {

          &:hover {

            .mdc-menu {
              display: block;
            }
          }
        }

        .mdc-menu {
          display: none;
        }

        // align to right
        &:nth-last-child(2) {

          .mdc-menu {

            @include responsive_min(sm) {
              @include responsive_max(xlg) {
                left: auto;
                right: 0;
              }
            }
          }
        }
      }
    }

    &>.mdc-list {
      margin: 6px 4px rem-calc(16) rem-calc(40);

      @include responsive_min(sm) {
        margin: 0 rem-calc(-10);
      }

      @include responsive_min(xlg) {
        margin: 0 rem-calc(-15);
      }

      &>.mdc-list-item {
        margin: 0;
        padding: rem-calc(8) rem-calc(60) rem-calc(8) 0;
        flex-direction: column;
        align-items: flex-end;

        line-height: 1.556em; // 28px
        color: $color-blue-10;

        @include responsive_min(sm) {
          padding: rem-calc(15) rem-calc(10);
          font-size: rem-calc(16);
          white-space: nowrap;
          flex-direction: row;
          align-items: center;
          color: $color-white;
        }

        @include responsive_min(xlg) {
          padding-left: rem-calc(15);
          padding-right: rem-calc(15);
        }

        &.mdc-list-submenu {
          border-radius: 0;

          .arrow {
            display: block;
            position: absolute;
            right: 0;
            top: 12px;

            &:before {
              @extend .icon-angle-down;
              font-size: rem-calc(12);
              line-height: inherit;
            }

            @include responsive_min(sm) {
              display: none;
            }
          }

          &.active {
            border-bottom: 1px solid rgba(88, 187, 239, 0.25);

            @include responsive_min(sm) {
              border-bottom: 0 none;
            }

            .arrow {
              transform: rotate(180deg);
            }
          }

          &:not(:last-child) {

            &.active {
              border-bottom: 1px solid rgba(88, 187, 239, 0.25);

              @include responsive_min(sm) {
                border-bottom: 0 none;
              }
            }
          }
        }

        // active state
        @include responsive_max(sm) {
          &.active {
            color: $color-white;
          }
        }

        @include responsive_min(sm) {

          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }
    }
  }

  // Profile dropdown menu
  &--profile {

    .mdc-list {

      padding: rem-calc(8) 0;

      .mdc-button {
        width: 100%;

        &__label {
          flex-grow: 1;
        }
      }

      .mdc-list-item,
      .language-item {

        font-size: rem-calc(16);
        padding: rem-calc(8) rem-calc(32);
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        height: rem-calc(40);

        @include theme() {
          color: t($t-color-secondary);
        }

        &:hover,
        &.active {
          color: $color-primary;
        }
      }
    }
  }
}

.mdc-list-submenu--no-children {
  display: block;
  color: $color-blue-10;
  font-size: rem-calc(18);
  padding: rem-calc(16) 0 rem-calc(16) rem-calc(25);

  &.active {
    color: $primary;
  }
}

.menu-active {
  color: $color-white;
}