@import "../helpers/variables";
@import "../helpers/functions";
@import "../base/typography";
@import "../helpers/mixins";

.service-access-item {
    display: flex;
}

.show-credentials-btn {
    margin-top: auto;
    margin-left: rem-calc(10);
}