@import "../helpers/variables";
@import "../helpers/functions";

.information {
  margin-bottom: rem-calc(45);

  @include responsive_min(md) {
    margin-bottom: rem-calc(90);
  }

  &.clear {
    margin-bottom: 0;
  }

  &.up {
    margin-bottom: rem-calc(60);

    @include responsive_min(md) {
      margin-bottom: rem-calc(45);
    }
  }

  &.upper {
    margin-bottom: rem-calc(31);

    @include responsive_min(md) {
      margin-bottom: rem-calc(40);
    }
  }

  &.down {
    @include responsive_min(md) {
      margin-top: rem-calc(15);
    }
  }

  &__head {
    margin-bottom: rem-calc(30);
    font-size: rem-calc(28);

    @include responsive_max(md) {
      line-height: 1.286em; // 36px
    }

    @include responsive_min(md) {
      font-size: rem-calc(44);
    }

    &.upper {
      margin-bottom: rem-calc(15);
    }

    &--secondary {
      font-size: rem-calc(28);
      line-height: 1.286em; // 36px

      @include responsive_min(md) {
        font-size: rem-calc(36);
        line-height: 1.333em; // 48px
      }
    }

    &.inline {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include responsive_min(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .mdc-button--text {
        order: -1;

        @include responsive_max(sm) {
          margin-bottom: rem-calc(40);
        }

        @include responsive_min(sm) {
          order: 2;
        }
      }
    }
  }

  &__description {
    
    font-size: rem-calc(16);
    line-height: 1.625em; // 26px

    &.lighter {
      color: $color-gray-80 !important;
    }

    &.larger {
      letter-spacing: 0;

      p:not(:last-child) {
        margin-bottom: 1.625em;

        @include responsive_min(sm) {
          margin-bottom: rem-calc(30);
        }
      }
    }

    &.medium {
      font-size: rem-calc(14);
      line-height: 1.445em;

      p:not(:last-child) {
        margin-bottom: rem-calc(15);
      }
    }

    p.head {
      margin-top: rem-calc(30);
      font-weight: bold;

      .mdc-button {
        height: auto;
      }
    }

    a {
      color: $color-blue-10;
      text-decoration: none;
      transition: color .15s;

      &:hover,
      &:focus {
        color: $primary;
      }

      &[href^="tel"] {
        color: inherit;
      }
    }
  }

  &--title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
  }

  &-icon {
    @include theme() {
      color: t($t-color-link);
    }
    font-weight: bold;
    cursor: pointer;
  }
}

@include responsive_min(sm) {
  .information {
    &--title {
      margin-top: 0;
      flex-direction: column;
    }
  }
}
