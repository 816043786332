//
// Font faces, stacks and sizes.
//
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100..900&display=swap');

$base-font-size:   16; // The font size set on the root html element.
$base-line-height: 1.5; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 2    * $base-font-size;
$h2-font-size: 1.75 * $base-font-size;
$h3-font-size: 1.5  * $base-font-size;
$h4-font-size: 1.3  * $base-font-size;
$h5-font-size: 1    * $base-font-size;
$h6-font-size: 0.85 * $base-font-size;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$font-primary: 'Open Sans', sans-serif;
$font-monospace: 'Roboto Mono', monospace;
$font-secondary: 'Montserrat', sans-serif;
$font-commissioner: 'Commissioner', sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family:     $font-primary; // The font family set on the html element.
$base-font-family-ru:  $font-primary;
$base-font-family-monospace: $font-monospace;
$base-font-family-secondary: $font-secondary;

// Override material.io font
$mdc-typography-font-family: $font-primary;
