@import "../helpers/functions";
@import "../helpers/variables";
@import "../components/form-fields-list";

.edkr-privacy-text {
    font-size: rem-calc(14);

    & > a {
        color: $color-blue-10;
    }
}