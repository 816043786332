@import "../helpers/variables";
@import "../helpers/functions";

.ev-contract {
    color: $primary;
}

.additional-services-wrapper {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: rem-calc(15);
    }
}

.additional-service-item {
    display: flex;
    padding: rem-calc(5) 0;

    & label {
        font-size: rem-calc(14) !important;
    }

    .payment-interval-wrapper {
        display: flex;
        flex-direction: row;
        padding-left:  rem-calc(30);
        align-items: center;

        &--title {
            font-weight: bold;
            margin-right: rem-calc(10);
            font-size: rem-calc(14);
        }

        & label {
            font-weight: normal;
        }

        .mdc-radio {
            width: 24px;
            height: 24px;
        }
    }
}

input[type=radio]:disabled:checked + .mdc-radio__background > .mdc-radio__inner-circle {
    border-color: $color-gray-90;
}

.contract-end-date-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.purchase-type-wrapper {
    display: flex;
    align-items: flex-end;
    height: 100%;
    padding: rem-calc(10);
    .purchase-type-content{
        display: flex;
        flex-direction: row;
    }
}

.button-wrapper {
    display: flex;
}

.align-self-end {
    align-self: end;
}

.icon-red {
    color: $color-red;
}

.ev-charger-item {
    font-size: rem-calc(18);
    margin-top: 20px;
}

.ev-charger-products {
    padding-left: 0px;
    margin-bottom: 40px;
}
