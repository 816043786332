@import "../helpers/variables";
@import "../helpers/functions";
.product {

    &__additional-info {
        text-align: left;
    }

    &__additional-info__text {
        font-size: rem-calc(13);
        padding: rem-calc(4) rem-calc(8);
        line-height: 1.4;
        color: $color-gray-50;

        &__item{
            display: block;
        }
    }
}