@import "../helpers/_functions";
@import "../helpers/_variables";

$gradient: linear-gradient(0deg, rgba(88,187,239,0.5) 0%, rgba(88,187,239,0.8) 40%, rgba(88,187,239,1) 100%);

.dashboard-tile-wrapper {
    display: flex;
    position: relative;
    height: 100%;

    &:after {
        content: '';
        display: block;
        padding-top: 100%;
    }
}

.dashboard-tile {
    padding: rem-calc(12);
    border-radius: rem-calc(8);
    width: 100%;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: $color-white;
    font-weight: bold;

    background-color: $secondary;
    background-size: cover;
    background-position: center;

    &--fuel {
        background-image: url('../../images/dashboard/fuel.png');
    }

    &--community {
        background-image: url('../../images/dashboard/community.jpg');
    }

    &--light_bulb {
        background-image: url('../../images/dashboard/electricity.png');
    }

    &--trailer {
        background-image: url('../../images/dashboard/trailer.png');
    }

    &--charger {
        background-image: $gradient, url('../../images/dashboard/ev-charging.png');
    }

    &--price-alert {
        background-image: url('../../images/dashboard/price-alert.jpg');
    }

    &--refuel {
        background-image: url('../../images/dashboard/fueling.png');
    }

    &--stamp {
        background-image: url('../../images/dashboard/stamp.jpg');
    }

    &--digital-stamps {
        background-image: url('../../images/dashboard/digital-stamps.png');
    }

    &--shop {
        background-image: $gradient, url('../../images/dashboard/shop.png');
    }

    &--cylinder-gas {
        background-image: $gradient, url('../../images/dashboard/cylindergas.jpg');
    }

    &--stations {
        background-image: $gradient, url('../../images/dashboard/stations.jpg');
    }

    &--family-group {
        background-image: url('../../images/dashboard/family-group.jpg');
    }

    &__title {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
    }

    &--new {
        &:after {
            content: '';
            background-color: $color-pink;
            border-radius: 50%;
            height: rem-calc(16);
            width: rem-calc(16);
            display: inline-flex;
            position: absolute;
            right: rem-calc(12);
            top: rem-calc(15);
        }

        .dashboard-tile__title {
            margin-right: rem-calc(20);
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__icon {
        font-size: rem-calc(36);
        display: inline-flex;
        align-self: flex-end;
        padding-left: rem-calc(4);
        padding-bottom: rem-calc(4);
    }

    &__value {
        font-size: rem-calc(48);
        line-height: rem-calc(40);
        padding-bottom: rem-calc(4);
    }

    &__arrow {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        display: flex;
        align-items: center;

        .icon {
            margin-left: rem-calc(10);
            margin-right: rem-calc(3);

            &--no-text {
                font-size: rem-calc(28);
                line-height: rem-calc(28);
            }
        }
    }

    @media (max-width: 400px) {
        &__value {
            font-size: rem-calc(36);
            line-height: rem-calc(40);
            padding-bottom: 0;
        }

        &__arrow {
            .text {
                display: none;
            }

            .icon {
                font-size: rem-calc(20);
                line-height: rem-calc(20);
            }
        }
    }

    @media (max-width: 360px) {

        &--new:after {
            top: rem-calc(12);
        }

        &__title {
            font-size: rem-calc(14);
            line-height: rem-calc(16);
            max-height: rem-calc(2 * 16);
            overflow: hidden;
        }

        &__icon {
            font-size: rem-calc(28);
        }
    }
}