.multi-select-selection {
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    padding: 10px;
    margin-top: 1.625rem;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        border-color: #222;
    }
}

.multi-select-content {
    display: flex;
    flex-wrap: wrap;
}

.multi-select-tag {
    display: flex;
    background-color: lightgray;
    border-radius: 4px;
    padding: 0 10px;
    margin-bottom: .1rem;
    cursor: default;

    &:not(:last-child) {
        margin-right: .1rem;
    }
}

.rotate {
    transform: rotate(180deg);
}

.dropdown-arrow {
    margin-left: auto;
    flex-grow: 0;
}

.tag-remove-btn {
    margin-left: 3px;
    cursor: pointer;
}