@import "../helpers/functions";
@import "../helpers/variables";

.family-group {
    &-tooltip {
        display: flex;
        align-items: center;
    }

    &-tooltip-link-button {
        text-decoration: underline;
        font-weight: bold;
        padding: 0;
    }

    &-icon {
        align-self: flex-start;
    }

    & .delete {
        color: $color-red;

        &::before {
            margin-right: rem-calc(6);
        }
    }

    .icon-close::before {
        margin-right: rem-calc(8);
        color: $color-red-20;
    }

    & .icon-user::before {
        color: $primary;
    }

    &-button {
        width: 100%;
    }

    &--input-delete-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & .right {
            justify-content: right;
        }
    }

    &--input-group {
        box-shadow: 0 0 rem-calc(10) $color-gray-20;
        border-radius: 4px;
        padding: rem-calc(10);

        & > div {
            padding-bottom: 16px;
        }
    }

    &--member {
        box-shadow: 0 0 rem-calc(10) $color-gray-20;
        border-radius: 4px;
        margin-bottom: rem-calc(10);

        & span {
            font-size: rem-calc(14);
            line-height: rem-calc(16);
        }
    }

    &--member-pending {
        color: $color-gray-80;
        font-style: italic;

        &-mobile-text {
            display: block;
            font-weight: 600;
        }
    }
}