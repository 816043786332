@import "../helpers/variables";
@import "../helpers/functions";


.stations {
    &-map-container {
        position: relative;
        height: 700px;

        @include responsive_between(sm, md) {
            height: 650px;
        }

        @include responsive_max(sm) {
            height: 560px;
        }
    }


    &__filter-toggles {
        display: flex;
        flex-direction: row;

        .mdc-button {
            $line-height: 24;

            flex: 1 1 0;
            text-transform: none;
            font-size: rem-calc(16);
            line-height: rem-calc($line-height);

            border: 2px solid $secondary;
            color: $secondary;

            &.active,
            &:hover {
                background-color: $primary;
                border-color: $primary;
                color: $color-white;

                &+* {
                    border-left: none;
                }
            }

            &:first-child {
                border-right: none;
            }

            i {
                height: rem-calc($line-height);
                width: rem-calc($line-height);
                font-size: rem-calc($line-height);

                &.icon-angle-down {
                    height: rem-calc($line-height / 2);
                    font-size: rem-calc($line-height / 2);

                    // &.rotate {
                    //     margin-left: 16px;
                    //     margin-right: -12px;
                    // }
                }
            }
        }
    }

    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &:nth-of-type(2) {
            padding-top: rem-calc(12);
            border-top: 1px solid $color-gray-20;
        }

        .station-filter {
            margin: 0 rem-calc(6) rem-calc(12);
        }
    }

    &__tabs {
        .mdc-button {
            margin: 0;
        }
    }

    &-list {

        .station-info {
            padding: rem-calc(16) rem-calc(24);

            &__header {
                display: flex;
                justify-content: space-between;

                &--toggle {
                    cursor: pointer;
                }

                .toggle {
                    min-width: auto;
                    padding: 0 0 0 rem-calc(12);

                    i {
                        color: $secondary;
                        margin: 0;
                    }
                }
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid $color-gray-20;
            }

            &--open {
                box-shadow: 4px 0 0 0 $secondary inset;
            }

            @include responsive_min(md) {
                flex-direction: row;
                flex-wrap: wrap;

                &__header {
                    flex: 1 0 100%;
                    order: 1;
                }

                &__directions {
                    flex: 1 1 0;
                    order: 3;

                    background: none;
                    margin: 0;
                    padding: 0;

                    &__title {
                        display: none;
                    }

                    &__links {
                        flex: 1 0 0;
                        justify-content: flex-end;
                        align-self: center;
                    }

                    &__link {
                        margin-left: rem-calc(48);
                    }
                }

                &__icons {
                    flex: 1 0 50%;
                    order: 2;
                    padding: 0;
                    justify-content: center;

                    .row {
                        margin: 0;
                    }
                }

                &__links {
                    order: 4;
                    justify-content: center;
                    align-self: flex-start;
                    padding: 0 0 0 rem-calc(48);

                    .mdc-button {
                        height: rem-calc(60);
                        border: 2px solid $secondary;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}