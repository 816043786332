.react-datepicker {
    $self: &;
    border: none !important;

    &__month-container {
        border: none;
        width: rem-calc(300);

        #{$self}__header {
            background-color: $color-white;
            border: none;
            margin: 0.4rem;
            .action {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: rem-calc(15) auto;

                .mdc-icon-button {
                    flex: 0 0 rem-calc(48);
                    @include theme() {
                        color: t($t-color-link);
                    }
                    text-align: center;
                    padding: rem-calc(12);
                    min-width: unset !important;
                    margin-left: 0;

                    &:before,
                    &:after {
                        display: none;
                    }

                    &:first-child {
                        margin-right: -1.19047%;
                    }

                    &:last-child {
                        margin-left: -1.19047%;
                    }

                    &:focus,
                    &::-moz-focus-inner {
                        border: 0;
                        outline: none;
                    }
                }

                .mdc-select {
                    margin: 0 1.19047%;

                    &__selected-text {
                        min-width: inherit;
                    }

                    &.year {
                        width: 28.33333%;
                    }

                    &.month {
                        width: 40.55555%;
                    }
                }
            }

            #{$self}__day-names {
                display: flex;
                border-bottom: 2px solid transparentize($color-gray-10, 0.8);
                #{$self}__day-name {
                    flex: 0 14.28571%;
                    color: $color-gray-10;
                    text-align: center;
                    display: inline-block;
                    width: 100%;
                    height: rem-calc(44);
                    font-size: rem-calc(18);
                    line-height: rem-calc(44);
                }
            }
        }

        #{$self}__month {
            #{$self}__week {
                display: flex;
                flex-wrap: wrap;
                padding: 0;

                #{$self}__day {
                    border-radius: 0;
                    flex: 0 14.28571%;
                    @include theme() {
                        color: t($t-color-link);
                    }
                    text-align: center;
                    display: inline-block;
                    width: 100%;
                    height: rem-calc(44);
                    font-size: rem-calc(18);
                    line-height: rem-calc(44);
                    cursor: pointer;

                    &:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled):hover,
                    &:not(.react-datepicker__day--selected):not(.react-datepicker__day--disabled):focus {
                        @include theme() {
                            background-color: t($t-color-background-darker);
                        }
                    }

                    &.react-datepicker__day--selected {
                        @include theme() {
                            background-color: t($t-color-secondary);
                        }
                        color: $color-white;
                    }

                    &.react-datepicker__day--today:not(.react-datepicker__day--selected) {
                        @include theme() {
                            background-color: t($t-color-background-darker);
                        }
                        font-weight: normal;
                    }

                    &.react-datepicker__day--disabled {
                        color: $color-gray;
                    }

                    &.react-datepicker__day--outside-month {
                        opacity: 0.4;
                    }

                    &.pull {
                        &-1 {
                            margin-left: 14.28572%;
                        }

                        &-2 {
                            margin-left: 28.57142%;
                        }

                        &-3 {
                            margin-left: 42.85713%;
                        }

                        &-4 {
                            margin-left: 57.14284%;
                        }

                        &-5 {
                            margin-left: 71.42855%;
                        }

                        &-6 {
                            margin-left: 85.71426%;
                        }
                    }
                }
            }
        }
    }
}

.datepicker-wrapper {
    position: relative;
    .datepicker {
        position: absolute;
        display: none;
        flex-direction: column;
        box-shadow: 0 rem-calc(30) rem-calc(60) rgba($color-black, 0.15);
        background-color: $color-white;
        z-index: 99;
        border-radius: 0;
        max-height: none !important;
        min-width: rem-calc(300);
        overflow: visible;

        &.open {
            display: flex !important;
        }
    }
}

.datepicker-range {
    $delimiter-margin: 7;
    $delimiter-width: 10;
    display: block;

    .datepicker-wrapper {
        width: calc(50% - #{$delimiter-width / 2}px - #{$delimiter-margin}px);
        display: inline-block;

        //safari, second datepicker without label is pushed upwards
        &:nth-child(3) {
            margin-top: rem-calc(26);
        }
    }

    .delimiter {
        max-width: rem-calc($delimiter-width);
        display: inline-block;
        margin: 0 rem-calc($delimiter-margin);
    }
}

@include responsive_min(sm) {
    .react-datepicker {
        $self: &;
        &__month-container {
            width: rem-calc(420);

            #{$self}__header {
                .action {
                    .mdc-select {
                        &.year {
                            width: 30.47619%;
                        }
                        &.month {
                            width: 42.85714%;
                        }
                    }
                }

                #{$self}__day-names {
                    #{$self}__day-name {
                        flex: 0 rem-calc(52);
                    }
                }
            }

            #{$self}__month {
                #{$self}__week {
                    #{$self}__day {
                        height: rem-calc(52);
                        line-height: rem-calc(52);
                        flex: 0 rem-calc(52);
                    }
                }
            }
        }
    }
}

@include responsive_max(sm) {
    .datepicker-wrapper {
        .datepicker {
            min-width: rem-calc(300);
        }
        &:last-child:not(:first-child) {
            .datepicker {
                right: 0;
            }
        }
    }

    .react-datepicker {
        $self: &;

        #{$self}__month-container {
            #{$self}__header {
                .action {
                    .mdc-select {
                        max-width: rem-calc(110);
                        &__dropdown-icon {
                            right: rem-calc(10);
                        }
                        &__selected-text {
                            padding-right: rem-calc(40);
                        }
                    }
                }
            }

            #{$self}__month {
                #{$self}__week {
                    flex-wrap: nowrap;
                }
            }
        }
    }
}
