/**
 * Colors.
 */

 $primary:   #172057;
 $secondary: #58bbef;

 $color-primary: #172057;
 $color-black: #000;
 $color-white: #fff;
 $color-gray: #ccc;
 $color-gray-10: #222;
 $color-gray-20: #ddd;
 $color-gray-30: #eee;
 $color-gray-50: #777;
 $color-gray-80: #999;
 $color-gray-90: #343A40;
 $color-green: #65bc46;
 $color-green-10: #53B569;
 $color-green-50: #CEF0C2;
 $color-green-100: #effaeb;
 $color-red: #db4d3d;
 $color-red-10: #db4d3d;
 $color-red-20: #DC3545;
 $color-red-100: #e96969;
 $color-red-110: #FCEBED;
 $color-blue: $secondary;
 $color-blue-10: #2499d6;
 $color-blue-20: #d2ecfa;
 $color-blue-30: #f2fcff;
 $color-blue-40: #cceafa;
 $color-blue-50: #eef8fd;
 $color-blue-100: #0f1539;
 $color-orange: #f29e0c;
 $color-pink: #ec008c;
 $color-pink-10: #EE7FC1;
 $color-orange-10:#FFC107;
 $color-orange-30: #f59a23;
 $color-orange-40:#ffbb00;
 $color-orange-50: #FFE4B5;
 $color-orange-60: #FFF9E7;
 $image-logo: "../../images/logo-white.svg";

 $body-color: $color-gray-10;
 $body-bg: $color-white;

$mdc-layout-grid-breakpoints: (
        desktop: 1024px,
        tablet: 767px,
        phone: 0px
) !default;

$screen_min_xs: 640px;
$screen_min_sm: 768px;
$screen_min_md: 992px;
$screen_min_lg: 1024px;
$screen_min_xlg: 1200px;
$screen_min_xxlg: 1400px;

$screen_max_xs: 639.9px;
$screen_max_sm: 767.9px;
$screen_max_md: 991.9px;
$screen_max_lg: 1023.9px;
$screen_max_xlg: 1199.9px;
$screen_max_xxlg: 1399.9px;

@mixin responsive_min($class) {
  @if $class == xs {
    @media (min-width: $screen_min_xs) { @content; }
  }

  @else if $class == sm {
    @media (min-width: $screen_min_sm) { @content; }
  }

  @else if $class == md {
    @media (min-width: $screen_min_md) { @content; }
  }

  @else if $class == lg {
    @media (min-width: $screen_min_lg) { @content; }
  }

  @else if $class == xlg {
    @media (min-width: $screen_min_xlg) { @content; }
  }
  @else if $class == xxlg {
    @media (min-width: $screen_min_xxlg) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xlg, xxlg";
  }
}

@mixin responsive_max($class) {
  @if $class == xs {
    @media (max-width: $screen_max_xs) { @content; }
  }

  @else if $class == sm {
    @media (max-width: $screen_max_sm) { @content; }
  }

  @else if $class == md {
    @media (max-width: $screen_max_md) { @content; }
  }

  @else if $class == lg {
    @media (max-width: $screen_max_lg) { @content; }
  }

  @else if $class == xlg {
    @media (max-width: $screen_max_xlg) { @content; }
  }
  @else if $class == xxlg {
    @media (min-width: $screen_max_xxlg) { @content; }
  }
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xlg, xxlg";
  }
}

$screen-min-map: (
  "xs": $screen_min_xs, 
  "sm": $screen_min_sm,
  "md": $screen_min_md, 
  "lg": $screen_min_lg, 
  "xlg": $screen_min_xlg, 
  "xxlg": $screen_min_xxlg
);

$screen-max-map: (
  "xs": $screen_max_xs, 
  "sm": $screen_max_sm,
  "md": $screen_max_md, 
  "lg": $screen_max_lg, 
  "xlg": $screen_max_xlg, 
  "xxlg": $screen_max_xxlg
);

@mixin responsive_between($class-min, $class-max) {
  $min-breakpoint: map-get($screen-min-map, $class-min);
  $max-breakpoint: map-get($screen-max-map, $class-max);
  
  @if $min-breakpoint and $max-breakpoint {
    @media (min-width: $min-breakpoint) and (max-width: $max-breakpoint) { @content; }
  }
}

$themes: (
  default: (
    color-primary: $primary,
    color-secondary: $secondary,
    color-secondary-2: $primary,
    color-link: $color-blue-10,
    color-footer-block-links: $secondary,
    color-background: $color-blue-30,
    color-background-darker: $color-blue-40,
    color-background-info: $color-blue-30,
    color-background-green: $color-green-100,
    color-info: $secondary,
    image-logo: $image-logo
  )
);

$t-color-primary: 'color-primary';
$t-color-secondary: 'color-secondary';
$t-color-link: 'color-link';
$t-color-footer-block-links: 'color-footer-block-links';
$t-color-background: 'color-background';
$t-color-background-green: 'color-background-green';
$t-color-background-darker: 'color-background-darker';
$t-color-background-info: 'color-background-info';
$t-color-info: 'color-info';
$t-color-secondary-2: 'color-secondary-2';
$t-image-logo: 'image-logo';

@mixin theme() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}