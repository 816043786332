@import "../helpers/variables";
@import "../helpers/functions";
@import "../components/icons";
@import "../base/typography";

$margin-right: rem-calc(10);
$padding: rem-calc(24);
$padding-history: rem-calc(16);
$border-radius: 6px;
$icon-size: rem-calc(60);
$check-icon-size: rem-calc(40);
$check-icon-position: -15px;
$border-radius: 5px;
$box-shadow: lightgray 1.95px 1.95px 2.6px;
$history-container-height: 999px;

.digital-stamps-bonus {
    color: $color-primary;

    @include responsive_max(md) {
        padding: 0;
    }

    &--total {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $font-secondary;
        font-size: rem-calc(24);
        font-weight: 600;

        &-main {
            &--count {
                font-size: rem-calc(56);
                margin-right: rem-calc(10);
            }
        }
    }

    &--text {
        &-bonus {
            padding-left: rem-calc(8);
            font-size: rem-calc(24);
            font-weight: 600;
            font-family: $font-secondary;
            margin-bottom: rem-calc(16);
        }

        &-disclaimer {
            padding-left: rem-calc(8);
            font-size: rem-calc(16);
            color: $color-gray-10;
            margin-bottom: rem-calc(28);

            @include responsive_max(md) {
                text-align: left;
            }
        }

        &-discount, &-app-disclaimer {
            padding-left: rem-calc(8);
            font-size: rem-calc(18);
            font-weight: 600;
            margin-top: rem-calc(16);

            @include responsive_max(md) {
                text-align: left;
            }
        }
    }

    &--indicator {
        @include responsive_max(md) {
            text-align: center;
        }

        &-cell {
            display: flex;
            justify-content: flex-start;
            margin: rem-calc(8) 0;
        }

        &-cell-container {
            @include responsive_max(md) {
                display: flex;
                justify-content: center;
            }

            @include responsive_max(xs) {
                display: block;
            }
        }
    }

    &--separator {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            border-left: 2px solid $color-gray-20;
            transform: translate(-50%);
        }

        @include responsive_max(md) {
            border-top: 1px solid $color-gray-20;
        }
    }
}

.digital-stamps-converter {
    background-color: white;

    .mdc-button {
        width: 100%;
    }

    &--button {
        padding-top: rem-calc(16);

        &>button {
            box-shadow: $box-shadow;
        }
    }

    &--dialog {
        .mdc-button {
            width: 100%;
        }

        .mdc-dialog__button--dismiss {
            width: auto;
        }

        .mdc-dialog__surface {
            max-width: rem-calc(900);

            @include responsive_max(xs) {
                max-width: calc(100vw - 32px);
            }
        }
    }
}

.digital-stamps-loyalty {
    padding-left: $margin-right;
    border-left: 5px solid $color-blue-20;

    &--name {
        &-main {
            font-weight: bold;
            font-family: $font-secondary;
            font-size: rem-calc(20);
            margin-bottom: rem-calc(10);
        }

        &-sub {
            font-family: $font-secondary;
            margin-top: rem-calc(5);

            &>span {
                margin-left: rem-calc(10);
            }

            &>i {
                text-align: center;
                width: rem-calc(20);
            }

            & * {
                vertical-align: middle;
            }

            @include responsive_max(xs) {
                span:nth-child(4) {
                    display: block;
                    margin-left: rem-calc(30);
                }
            }
        }
    }
}

.digital-stamps-card {
    background-color: $color-blue-50;
    border-radius: $border-radius;
    position: relative;
    height: 100%;
    padding: 0 !important;

    .container-padding {
        padding-left: $padding;
        padding-right: $padding;
        grid-gap: 0;
    }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        padding-top: rem-calc(32);

        &>div {
            display: flex;
            border-radius: 50%;
            height: $icon-size;
            width: $icon-size;
            justify-content: center;
            align-items: center;
            text-align: center;

            &.card {
                background-color: $color-blue-100;
            }

            &.electricity {
                background-color: $color-blue-10;
            }

            &.trees {
                background-color: $color-green-10;
            }

            &.disabled {
                background-color: $color-gray;
            }

            &.stamp>i {
                font-size: rem-calc(50);
                color: $color-blue-100;
            }

            &>i {
                display: inline-block;
                font-size: rem-calc(20);
                color: $color-white;
            }
        }
    }

    .title {
        text-align: center;
        font-family: $font-secondary;
        color: $color-primary;
        font-weight: 600;
        font-size: rem-calc(24);
        padding: rem-calc(8);
        height: rem-calc(90);
    }

    .description {
        font-size: rem-calc(16);
        text-align: center;
        padding-top: rem-calc(24);
        height: rem-calc(120);
        display: flex;
        align-items: center;
    }

    .separator {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            background-color: $color-gray-20;
            height: 1px;
        }
    }

    .convert-box-header {
        font-size: rem-calc(15);
        position: relative;
        padding-top: rem-calc(24);
        padding-bottom: rem-calc(4);
        text-align: center;

        i {
            display: inline;
            margin-left: rem-calc(4);
        }

        // tooltip
        i::after {
            content: attr(data-tooltip);
            display: inline-block;
            position: absolute;
            transform: scale(0);
            padding: rem-calc(10);
            bottom: 75%;
            right: 0;
            width: rem-calc(200);
            font-size: rem-calc(14);
            z-index: 10;
            background-color: $color-primary;
            border-radius: 6px;
            color: $color-blue-10;
            line-height: rem-calc(16);
        }

        i:hover::after {
            transform: scale(1);
        }
    }

    .super-bold-text {
        text-align: center;

        span {
            font-size: rem-calc(48);
            font-weight: bold;
        }
    }

    .sum-description {
        text-align: center;
        font-size: rem-calc(16);
        height: rem-calc(48);
    }

    div.mdc-select:not(.mdc-select--code):not(.mdc-select--no-label) {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .mdc-select.mdc-select--outlined {
        margin-bottom: 0 !important;
    }

    .child-item {
        height: rem-calc(90);
    }
}

.digital-stamps-history {
    border-radius: $border-radius;

    &--title {
        padding: $padding-history;
        position: relative;
        cursor: pointer;
        background-color: $color-blue-50;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &>h2 {
            font-family: $font-secondary;
            color: $color-primary;
            font-weight: 600;
            font-size: rem-calc(22);
        }

        &-mobile {
            text-align: center;
        }

        &>i {
            width: rem-calc(25);
            height: rem-calc(25);
            font-size: rem-calc(20);
            transition: transform 0.5s;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &>.open {
            transform: rotate(90deg);
            transform-origin: center;
        }
    }

    &--heading {
        padding: $padding-history;
        border-bottom: 1px solid $color-gray-20;

        &-item {
            font-family: $base-font-family;
            font-weight: 600;
        }
    }

    &--row {
        padding: $padding-history;

        &.mobile {
            border-bottom: 1px solid $color-gray-20;
            font-size: rem-calc(14);
        }
    }

    &--container {
        overflow: hidden;
        max-height: 0;
        transition: max-height .5s ease-in-out;
        border-top: 1px solid $color-blue;

        &.open {
            max-height: $history-container-height;
        }
    }

    &--scrollable {
        overflow-y: scroll;
        max-height: $history-container-height;

        &.noscroll {
            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &::-webkit-scrollbar {
            width: 0.25rem;
        }

        // TODO: TBD if this should be here or not?
        // &::-webkit-scrollbar-track {
        //     background-color: $color-gray-20;
        // }

        &::-webkit-scrollbar-thumb {
            background-color: $color-blue-20;
            box-shadow: inset 0 0 2px $color-blue;
            border-radius: 6px;
        }
    }
}

.digital-stamp-bonus-cell {
    padding: rem-calc(8);
    color: $color-white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .checkmark {
        position: absolute;
        width: rem-calc(24);
        height: rem-calc(24);
        background-color: $color-white;
        border-radius: 50%;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &>i {
            color: $color-green;
        }

        @include responsive_max(md) {
            width: rem-calc(20);
            height: rem-calc(20);

            &>i {
                font-size: rem-calc(10);
            }
        }
    }

    &--inner {
        border-radius: 3px;
        border: 2px solid $color-green;
        width: rem-calc(85);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include responsive_max(md) {
            width: rem-calc(44);

            &>i {
                font-size: rem-calc(20);
            }
        }

        &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }


        &:not(.active) i {
            color: $color-green;
        }

        &.active {
            background-color: $color-green;
        }
    }

    &.counter {
        border: none;
        color: $color-gray-80;
        margin-left: rem-calc(20);
        font-size: rem-calc(40);
        font-weight: 500;
        font-family: $font-secondary;

        @include responsive_max(md) {
            font-size: rem-calc(24);
            margin-left: 0;
        }
    }

    .max-stamps {
        color: $color-green;
    }
}

.digital-stamp-advert {
    color: $color-primary;
    font-weight: 600;
    font-family: $font-secondary;
}