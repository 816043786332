@import "../helpers/variables";
@import "../helpers/functions";

$padding: rem-calc(12);

.contract-info {
    grid-gap: 0 !important;
    padding-top: $padding;

    &--item {
        display: flex;
        justify-content: space-between;
        border: 1px solid $color-gray-30;
        border-radius: 5px;
        margin-top: rem-calc(10);
        padding: $padding;
        cursor: pointer;

        &--child {
            padding: $padding;
            border-bottom: 1px solid $color-gray-30;

            a {
                color: $secondary;
            }
        }

        .icon {
            font-size: rem-calc(12);
        }
    }

    .title {
        font-weight: 600;
    }
}