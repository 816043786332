@import "./_variables";

// fix ios phone color
a[href^=tel] {
  color: inherit !important;
}

a[href^="mailto:"] {
  @include theme() {
    color: t($t-color-primary);
  }
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.align-center {
  margin-left: auto;
  margin-right: auto;

}

.align-top {
  margin-bottom: auto !important;
}

.align-left {

  &:not(.align-left--desktop) {
    margin-right: auto !important;
  }

  @include responsive_min(sm) {
    margin-right: auto !important;
  }
}

.align-right {

  &:not(.align-right--desktop) {
    margin-left: auto !important;
  }

  @include responsive_min(sm) {
    margin-left: auto !important;
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.right {
  float: right;

  &--mobile {

    @include responsive_max(sm) {
      float: right;
    }
  }
}

.left {
  float: left;

  &--mobile {

    @include responsive_max(sm) {
      float: left;
    }
  }
}

.clear {
  clear: both;
}

.nowrap {

  &:not(.nowrap--desktop) {
    white-space: nowrap !important;
  }

  @include responsive_min(sm) {
    white-space: nowrap !important;
  }
}

// remove outline in FF
select::-moz-focus-inner {
  outline: none !important;
}

select:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 #000 !important;
}

.text-italic {
  font-style: italic;
}

// text-group
.text-group {
  margin-bottom: rem-calc(45);

  @include responsive_min(sm) {
    margin-bottom: rem-calc(60);
  }

  p {
    margin-bottom: rem-calc(30);
  }

  .mdc-button--link {
    height: auto;
  }
}

// layouts background
.l-blue-light {
  background-color: #f7f9fa;
}

// outer paddings

.mb-tablet-20 {
  @include responsive_max(lg) {
    margin-bottom: rem-calc(20) !important;
  }
}

.mt-tablet-20 {
  @include responsive_max(lg) {
    margin-top: rem-calc(20) !important;
  }
}

.mt-up-5 {

  @include responsive_min(sm) {
    margin-top: rem-calc(-5);
  }
}

.mt-up-10 {

  @include responsive_min(sm) {
    margin-top: rem-calc(-10);
  }
}


.ml-45 {
  @include responsive_min(sm) {
    margin-left: rem-calc(45) !important;
  }
}

.ml-10 {
  margin-left: rem-calc(10);
}

@include generate-margins("top", 8 12 16 24 26 28 32 36 48);
@include generate-margins("bottom", 8 12 16 24 28 32 36 48);
@include generate-margins("left", 8 12 16 24 28 32 36 48);
@include generate-margins("right", 8 12 16 24 28 32 36 48);

@include generate-margins("top", 5 10 15 20 25 30 35 40 45 50 60 65 90 150);
@include generate-margins("bottom", 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 90 180 220);
@include generate-margins("left", 5 10 15);
@include generate-margins("right", 5 10 15 20 25 30 45);

.p-0 {
  padding: 0 !important;
}

.p-10 {
  padding: rem-calc(10);
}

.p-15 {
  padding: rem-calc(15);
}

.p-24 {
  padding: rem-calc(24);
}

.p-45 {
  padding: rem-calc(45);
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-10 {
  padding-top: rem-calc(10) !important;
}

.pt-15 {
  padding-top: rem-calc(15) !important;
}

.pt-25 {
  padding-top: rem-calc(25) !important;
}

.pt-30 {
  padding-top: rem-calc(30) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-10 {
  padding-bottom: rem-calc(10) !important;
}

.pb-30 {
  padding-bottom: rem-calc(30) !important;
}


.p-mobile-4 {
  @include responsive_max(sm) {
    padding: rem-calc(4);
  }
}

.p-mobile-20 {
  @include responsive_max(sm) {
    padding: rem-calc(20);
  }
}

.p-mobile-25 {
  @include responsive_max(sm) {
    padding: rem-calc(25);
  }
}

.pt-20 {

  @include responsive_min(sm) {
    padding-top: rem-calc(20) !important;
  }
}

.pl-15 {
  padding-left: rem-calc(15) !important;
}

.pr-10 {
  padding-right: rem-calc(10) !important;
}

.pr-24 {
  padding-right: rem-calc(24);
}

.mt-clear {
  margin-top: 0 !important;
}

.mb-mobile-clear {

  @include responsive_max(sm) {
    margin-bottom: 0 !important;
  }
}

.pr-mobile-clear {

  @include responsive_max(sm) {
    padding-right: 0 !important;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.mr-tablet-clear {

  @include responsive_max(md) {
    margin-right: 0 !important;
  }
}

.clearfix {
  @include clearfix;
}

.hidden {
  display: none !important;
}

@include responsive_max(sm) {

  .hidden-mobile {
    display: none !important;
  }
}

@include responsive_max(md) {

  .hidden-tablet {
    display: none !important;
  }
}

@include responsive_min(sm) {

  .hidden-desktop {
    display: none !important;
  }
}

@each $size in (xs, sm, md, lg, xlg, xxlg) {
  @include responsive_min($size) {
    .hidden-above-#{$size} {
      display: none !important;
    }
  }

  @include responsive_max($size) {
    .hidden-below-#{$size} {
      display: none !important;
    }
  }
}


// flexbox
.d-flex {
  display: flex;

  @include responsive_max(sm) {
    &:not(.d-flex--inline) {
      flex-direction: column;
    }

    &.align-items-center {
      align-items: flex-start;
    }
  }

  &>.w-50 {

    @include responsive_min(sm) {
      width: 50%;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }
}

.flex-fill {
  flex: 1 1 0;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-column {
  flex-direction: column;
}

.align-items-center {

  &:not(.align-items-center--desktop) {
    align-items: center;
  }

  @include responsive_min(sm) {
    align-items: center;
  }
}

.align-items-end {

  &:not(.align-items-end--desktop) {
    align-items: flex-end;
  }

  @include responsive_min(sm) {
    align-items: flex-end;
  }
}

.align-items-start {
  align-items: flex-start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.v-align-top {
  vertical-align: top;
}

// payment methods list
.payment-methods {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: rem-calc(30);
  margin-bottom: rem-calc(30);

  @include responsive_min(sm) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 4px;
  }

  &__title {
    flex: 1 100%;
    margin-bottom: rem-calc(30);
    margin-left: -4px;
    font-weight: 600 !important;
    color: $color-gray-10 !important;

    @include responsive_max(sm) {
      display: none;
    }
  }

  .field {
    cursor: pointer;

    .mdc-radio {
      display: none;
    }

    label {
      padding-left: 0;
    }

    &.active {

      label {
        box-shadow: 0 0 0 2px $color-white, 0 0 0 4px $secondary;
      }
    }

    &:not(:last-child) {
      margin-bottom: rem-calc(15);

      @include responsive_min(sm) {
        margin: 0 rem-calc(20) 0 0;
      }
    }
  }
}

// form field custom modification
.mdc-form-field {

  // inline modification
  &.inline {

    @include responsive_max(sm) {
      width: 100%;
    }

    &:not(:last-child) {
      margin: 0 0 rem-calc(15);

      @include responsive_min(sm) {
        margin: 0 rem-calc(30) 0 0;
      }
    }
  }
}

// default state (hide field groups)
.toggle-field-group {
  display: none;
}

.wrapper {

  &--relative {
    position: relative;
  }
}

// grouping fields (for the tel number field)
.fields-group {
  display: flex;

  &>* {

    &.mdc-menu-surface--anchor {
      flex: 0 0 rem-calc(113);

      @include responsive_min(sm) {
        flex: 0 0 rem-calc(138);
      }

      .mdc-select {
        float: left; // fix for ie >=11
      }

      .mdc-menu-surface--open {
        display: block;
        min-width: 100%;
        white-space: nowrap;
      }
    }

    &.mdc-text-field {
      flex: 1 auto;

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        transition: none;
      }

      .mdc-notched-outline__leading {
        border-left: 0 none;
      }

      &:not(.mdc-text-field--focused) input:hover {

        &~.mdc-notched-outline {
          .mdc-notched-outline__leading {
            border-left: 2px solid;
          }
        }
      }
    }
  }
}

// language navigation
.nav-language {
  @include clearfix;

  a {
    float: left;
    border-radius: 50%;
    border: rem-calc(4) solid transparent;
    overflow: hidden;
    transition: border-color .15s;

    &:not(:last-child) {
      margin-right: rem-calc(12);
    }

    &:hover:not(.active),
    &:focus:not(.active) {
      border-color: rgba($color-white, .7);
    }

    // border for the active nav item
    &.active {
      border-color: $color-white;
    }

    span {
      display: block;
    }

    &.flag-ee {

      span {
        @include sprite($flag-ee);
      }
    }

    &.flag-gb {

      span {
        @include sprite($flag-gb);
      }
    }

    &.flag-ru {

      span {
        @include sprite($flag-ru);
      }
    }
  }
}

// card code textfield
.card-code {
  position: relative;

  @include responsive_max(lg) {
    max-width: rem-calc(390);
  }

  @include responsive_min(lg) {
    margin-left: rem-calc(45);
  }

  &--wrapper {
    display: flex;
    flex-direction: column;

    @include responsive_min(lg) {
      flex-direction: row;
    }

    .cell {

      &:last-child {
        order: -1;

        @include responsive_min(lg) {
          order: 1;
        }
      }
    }
  }

  &__action {
    max-width: rem-calc(390);
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(35);
    @include clearfix;

    @include responsive_max(sm) {
      text-align: center;
    }

    @include responsive_min(sm) {
      @include responsive_max(lg) {
        float: left;
      }
    }

    @include responsive_min(lg) {
      margin-bottom: 0;
      max-width: none;
    }

    .mdc-button {

      @include responsive_max(sm) {
        float: none;
      }

      @include responsive_min(sm) {
        @include responsive_max(lg) {
          float: left;
        }
      }

      @include responsive_min(lg) {

        &:not(.left) {
          float: right;
        }
      }

      &.mdc-button--text {

        @include responsive_min(sm) {
          height: rem-calc(60)
        }
      }

      &:not(:first-child) {

        @include responsive_min(sm) {
          @include responsive_max(lg) {
            margin-left: rem-calc(45);
          }
        }

        @include responsive_min(lg) {
          margin-right: rem-calc(45);
        }
      }
    }

    &.fluid {

      .mdc-button {

        @include responsive_min(sm) {
          @include responsive_max(lg) {
            float: left;
            margin: 0 !important;

            &:not(:last-child) {
              margin-right: rem-calc(20) !important;
            }
          }
        }
      }
    }
  }

  img {
    max-width: 100%;

    @include responsive_min(sm) {
      max-width: none;
    }
  }

  .mdc-text-field {
    position: absolute;
    right: rem-calc(12);
    bottom: rem-calc(24);
    max-width: rem-calc(80);

    @include responsive_min(sm) {
      right: rem-calc(40);
      bottom: rem-calc(40);
      max-width: rem-calc(90);
    }

    &__input {
      text-align: center;
    }
  }
}

.pointer {
  cursor: pointer;
}

.link-disabled {
  pointer-events: none;
}

.max-w-200 {
  max-width: 200px;
}

.w-full {
  width: 100%;
}

.w-mobile-100 {
  @include responsive_max(md) {
    width: 100%;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.white-space-normal {
  white-space: normal;
}

.white-space-pre-line {
  white-space: pre-line;
}

.word-wrap {
  word-break: break-word;
}

.checkmark-green::before {
  content: '\2713';
  display: inline-block;
  color: $color-green;
  padding-left: rem-calc(5);
  font-weight: bold;
}

.grid-gap-small {
  gap: rem-calc(16);
}

.grid-no-gap {
  gap: 0 !important;
}

.text-bold {
  font-weight: bold;
}

.d-table {
  display: table;

  .d-tr {
    display: table-row;
  }

  .d-td {
    display: table-cell;
  }
}

.pre {
  padding: rem-calc(8);
  border: 1px solid grey;
  border-radius: rem-calc(4);
  font-family: monospace;
  white-space: break-spaces;
}

.text-line-through {
  text-decoration: line-through;
}

.max-view-height {
  height: 100vh;
}