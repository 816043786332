@import "../helpers/_variables";
@import "../helpers/_mixins";

$height: 48;
$border-height: 2;
$inner-height: $height - (2 * $border-height);

.file-field {
    @include theme() {
        border: rem-calc($border-height) solid t($t-color-primary) !important;

    }

    &--invalid {
        @include theme() {
            border-color: var(--mdc-theme-error, $color-red) !important;
        }
    }

    border-radius: rem-calc(4);
    display: flex;
    flex-direction: row;
    position: relative;
    color: white;
    height: rem-calc($height);

    &__input-wrapper {
        position: relative;
        cursor: pointer;
        display: flex;
        flex: 1 0 0;
        height: rem-calc($inner-height);
        background-color: $color-primary;

        input {
            margin: 0;
            filter: alpha(opacity=0);
            opacity: 0;
            min-width: rem-calc(16);
            flex: 1 1 100%;
            width: 100%;
        }
    }

    &__custom-input {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        // z-index: 5;
        height: rem-calc($inner-height);
        padding: rem-calc(8) rem-calc(16);
        line-height: rem-calc(28);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: white;

        @include truncate-text();
    }

    &__clear {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        // z-index: 6;
        display: block;
        content: "";
        height: rem-calc($inner-height) !important;
        padding: rem-calc(($inner-height - 24) / 2) !important;

        flex: 0 0 rem-calc($inner-height) !important;
        color: $color-white !important;
        border-left: 1px solid $color-gray-20 !important;

        @include theme() {
            background-color: t($t-color-primary) !important;
        }
    }
}