*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: $base-font-size;
}

body {
  background-color: $body-bg;
  font-family: $base-font-family;
  font-size: rem-calc($base-font-size);
  font-weight: 400;
  color: $body-color;
  line-height: $base-line-height;
  -webkit-text-size-adjust: 100%;

  &:lang(ru) {
    font-family: $base-font-family-ru;
  }
}

em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

// base page wrapper
.tabs__wrapper,
.footer--dummy {
  margin: 0 auto;
}

#wrapper {
  display: flex;
  flex-direction: column;

  // Use 100vh only then, then in wrapper won't be Wrapped campaign opened.
  // Otherwise it will give additional vertical space, which can be scrolled during opened full screen Wrapped campaign.
  &:not(:has(.wrapped)) {
    min-height: 100vh;
  }
}

#wrapper > section:last-of-type {
  flex-grow: 1;
}

img {
  display: block;
}

a:not(.mdc-button) :not(.nav__link) :not(.mdc-top-app-bar__action-item) :not(.tooltip) {

  &:hover,
  &:focus {
    text-decoration: none;
    opacity: 0.8;
  }
}

a {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    @include no-tap-box;
  }
}

ul {
  @include reset-list;
}
