@import "../helpers/_functions";
@import "../helpers/_variables";

@mixin qr($total-size, $icon-size, $border-width) {
    border: rem-calc($border-width) solid $color-primary;
    border-radius: rem-calc(4);
    height: rem-calc($total-size);
    width: rem-calc($total-size);
    font-size: rem-calc($icon-size);
    padding: rem-calc(($total-size - $icon-size - ($border-width * 2)) / 2);
    display: flex;

    .mdc-icon-button__icon {
        height: rem-calc($icon-size);
    }
}

.card-qr-button {

    @include qr(64, 48, 3);
    color: $color-primary;

    // style for the old versin that is used next to bonuscard selection dropdown
    &--bonuscard-selection {

        @include qr(60, 40, 6);

        margin-left: rem-calc(16);
        border-color: $color-blue-10;
        color: $color-black;

        @include responsive_min(lg) {
            display: none;
        }
    }

    &--disabled,
    &:disabled {
        border-color: $color-gray;
        color: $color-gray;
    }
}