$accordion-border-color: #d2ecfa;
$accordion-border-width: 2px;
$accordion-active-icon-color: $secondary;
$accordion-active-border-color: #d2ecfa;
$accordion-active-bg: #f2fcff;

// accordion list
.accordion {
  $this: &;

  @include responsive_max(sm) {
    margin-left: -#{rem-calc(32)};
    margin-right: -#{rem-calc(32)};
  }

  // accordion item
  &__item {
    border-top: $accordion-border-width solid $accordion-border-color;
    padding: rem-calc(14) rem-calc(30) rem-calc(15) rem-calc(32);
    transition: background-color 365ms cubic-bezier(.4, 0, .2, 1);

    @include responsive_min(sm) {
      padding: rem-calc(16) rem-calc(30) rem-calc(20) rem-calc(30);
    }

    &:last-child {
      border-bottom: $accordion-border-width solid $accordion-border-color;
    }

    &.large {
      padding-top: rem-calc(20);
      padding-bottom: rem-calc(20);

      @include responsive_min(sm) {
        padding-top: rem-calc(26);
        padding-bottom: rem-calc(24);
      }
    }

    // active state
    &.active {
      border-top-color: $accordion-active-border-color;
      background-color: $accordion-active-bg;

      &:last-child {
        border-bottom-color: $accordion-active-border-color;
      }

      .accordion__item__head {

        // change color and transform arrow icon
        .icon {
          transform-origin: center center;
          transform: rotate(180deg);
          color: $accordion-active-icon-color;
        }
      }

      .accordion__item__intro {
        display: block;
      }
    }

    // item head
    &__head {
      display: flex;
      min-height: rem-calc(68);
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;

      &.smaller {
        position: relative;
        min-height: rem-calc(60);

        @include responsive_max(sm) {
          flex-direction: column;
        }

        .text {
          width: 100%;
        }

        span.icon {

          @include responsive_max(sm) {
            position: absolute;
            top: rem-calc(22);
            right: rem-calc(8);
          }
        }
      }

      // disabled state
      &.disabled {
        cursor: initial;

        .primary-text,
        .secondary-text {
          color: $color-gray-80
        }
      }

      // head form field
      .field {
        margin-right: rem-calc(15);
      }

      // head text
      .text {
        flex: 1 auto;

        &>* {
          display: block;
        }
      }

      // content before text section
      .before {
        font: {
          size: rem-calc(10);
          weight: 400;
        }

        line-height: 1.4em; // 14px
        color: $secondary;
        text-transform: uppercase;

        @include responsive_min(sm) {
          font-size: rem-calc(16);
          line-height: 1.25em; // 20px
        }
      }

      // head text primary
      .primary-text {
        font-size: rem-calc(16);
        font-weight: bold;
        line-height: 1.625em; // 26px
        color: $color-gray-10;

        @include responsive_min(sm) {
          font-size: rem-calc(18);
          line-height: 1.667em; // 30px
        }

        small {
          font-size: inherit;
          font-weight: 400;
        }
      }

      // form field text secondary
      .secondary-text {
        font-size: rem-calc(14);
        line-height: 1.571em; // 22px
        color: $color-gray-50;

        @include responsive_min(sm) {
          font-size: rem-calc(16);
          line-height: 1.625em; // 26px
        }
      }

      // head arrow icon
      .icon {
        font-size: rem-calc(13);
        color: $secondary;
        transition: transform 365ms cubic-bezier(.4, 0, .2, 1);

        @include responsive_min(sm) {
          margin-right: rem-calc(2);
        }

        &.left {
          margin: 0 0 0 rem-calc(16);
        }

        &.small {
          font-size: rem-calc(7);
        }
      }

      .label {
        display: inline-block;
        padding: rem-calc(1) rem-calc(6);
        font-size: rem-calc(12);
        line-height: 1.667em; // 20px
        font-weight: 700;
        color: $color-white;

        @include responsive_min(sm) {
          @include responsive_max(lg) {
            float: right;
          }
        }

        &--primary {
          background-color: $primary;
        }

        &--secondary {
          background-color: $secondary;
        }

        &--promo {
          background-color: $secondary;
          float: left;
          margin: rem-calc(4) auto;
        }
      }

      .mdc-button {
        margin-left: rem-calc(27);

        @include responsive_min(sm) {
          @include responsive_max(lg) {
            float: right;
            margin-left: 0;
          }
        }
      }

      .action {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        @include responsive_min(sm) {
          width: auto;
          margin-top: 0;
          margin-left: rem-calc(30);
          align-items: flex-end;
        }

        @include responsive_min(lg) {
          display: block;
          margin-right: 0;
        }

        .mdc-button {
          white-space: nowrap;
          @include responsive_max(sm) {
            margin-top: rem-calc(15);
            width: 100%;
          }

          @include responsive_max(lg) {
            margin-left: 0;
          }
        }
      }
    }

    // item intro section
    &__intro {
      display: none;
      padding: rem-calc(20) 0 rem-calc(8);

      &.larger {
        padding: rem-calc(20) 0 rem-calc(9);

        @include responsive_min(sm) {
          padding: rem-calc(30) 0 rem-calc(6);
        }
      }

      .form-field-group__title {
        margin-top: rem-calc(45);
        margin-bottom: rem-calc(30);
      }

      .form-field-group {
        margin-bottom: rem-calc(30);

        &__item {
          margin-bottom: rem-calc(30);
        }
      }

      &--links {
        @include clearfix;

        .mdc-button {
          width: auto;
          float: left;

          @include responsive_max(sm) {
            clear: left;
          }

          &:not(:last-child) {
            margin-bottom: rem-calc(17);

            @include responsive_min(sm) {
              margin-bottom: 0;
              margin-right: rem-calc(30);
            }
          }
        }
      }
    }
  }

  // simple
  &--simple {

    #{$this}__item {
      border: 0 none !important;
      padding: 0;
      background-color: #e7edf6 !important;

      // active state
      &.active {

        #{$this}__item__head {

          .icon {
            margin-top: 2px;
          }
        }
      }

      &__head {
        min-height: inherit;
        padding: rem-calc(10) rem-calc(30) rem-calc(10);

        .title {
          font-size: rem-calc(12);
          font-weight: 700;
          line-height: 1.333em; // 16px
          color: #66667b;
        }

        .icon {
          color: #3498db;
        }
      }

      &__intro {
        padding: rem-calc(18) rem-calc(28) rem-calc(8);
        background-color: $color-white;
        box-shadow: 0px 2px 6px rgba($color-black, 0.04);
      }
    }
  }
}