@import "../helpers/variables";
@import "../helpers/functions";
@import "../base/typography";
@import "../helpers/mixins";

.green-energy {

    &__logo img {
        max-height: rem-calc(300);
        margin: 0 auto;
    }

    &__container {
        margin-top: rem-calc(15);
    }
}