@import "../base/typography";

.summer-campaign {
    $disabled-bg-color: rgba(234, 236, 240, 1);
    $disabled-font-color: rgba(102, 112, 133, 1);

    $font-weight-400: 400;
    $font-weight-500: 500;
    $font-weight-600: 600;
    $font-weight-700: 700;

    $font-size-12: 12px;
    $font-size-14: 14px;
    $font-size-16: 16px;
    $font-size-20: 20px;
    $font-size-24: 24px;
    $font-size-26: 26px;
    $font-size-38: 38px;

    $line-height-20: 20px;
    $line-height-22: 22px;
    $line-height-24: 24px;
    $line-height-32: 32px;
    $line-height-42: 42px;

    $color-primary: rgba(23, 32, 87, 1);
    $color-blue-1: rgba(52, 64, 84, 1);
    $color-blue-2: rgba(29, 41, 57, 1);
    $color-blue-3: rgba(64, 213, 255, 1);
    $color-blue-4: rgba(10, 86, 209, 1);
    $color-gray-1: rgba(242, 244, 247, 1);
    $color-gray-2: rgba(102, 112, 133, 1);
    $color-gray-3: rgba(234, 236, 240, 1);
    $color-brown-1: rgba(194, 112, 80, 1);
    $color-green-1: rgba(192, 255, 211, 1);
    $color-green-2: rgba(60, 238, 174, 1);

    $border-radius-small: 10px;
    $border-radius-regular: 12px;
    $border-radius-large: 16px;

    $padding-regular: 8px;

    $list-item-gap: 12px;

    h2 {
        font-weight: $font-weight-700;
        font-family: $font-commissioner;
        font-size: $font-size-24;
        line-height: $line-height-32;
        text-align: center;
        color: $color-primary;
    }

    b { font-weight: $font-weight-600; }

    ul {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 24px;
    }

    &__terms {
        font-weight: $font-weight-400;
        font-size: $font-size-16;
        line-height: $line-height-24;
        color: $color-blue-1;
    }

    &__prize-item {
        display: flex;
        border-radius: $border-radius-large;
        padding: $padding-regular;
        box-shadow:
            0px 2px 4px 0px rgba(0, 55, 153, 0.05),
            1px 2px 8px 0px rgba(52, 64, 84, 0.08);
        margin-bottom: $list-item-gap;
        cursor: pointer;
        font-family: $font-commissioner;

        img {
            background-color: $color-gray-1;
            border-radius: $border-radius-small;
            width: 10%;
            height: 10%;

            &.tub {
                background: linear-gradient(0deg, $color-brown-1 50%, $color-gray-1 50%);
            }

            &.winner {
                background-color: $color-green-1;
            }

            &.mobile {
                width: 13%;
                height: 13%;
            }
        }

        &__content {
            margin-left: 10px;
            margin-right: 10px;
            width: 100%;
        }

        &__title {
            font-size: $font-size-16;
            font-weight: $font-weight-600;
            color: $color-blue-2;
            line-height: $line-height-24;
        }

        &__sub-title {
            font-size: $font-size-14;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            color: $color-gray-2;
        }

        .icon {
            font-size: $font-size-14;
            width: 24px;
            height: 24px;
            align-content: center;
        }
    }

    &__prize-overview {
        display: grid;
        grid-template: 1fr / 1fr;
        place-items: center;
        font-family: $font-commissioner;

        button {
            margin-left: auto;
            margin-right: 0;
            font-size: $font-size-16;
        }

        &_image {
            img {
                background-color: $color-gray-1;
                border-radius: $border-radius-large;
                width: 100%;
                height: auto;
                margin-top: 24px;

                &.winner {
                    background-color: $color-green-1;
                }

                &.tub {
                    background: linear-gradient(0deg, $color-brown-1 50%, $color-gray-1 50%);
                }

                &.mobile {
                    width: 100%;
                    height: auto;
                }
            }

            p {
                color: $color-blue-1;
                font-weight: $font-weight-400;
                font-size: $font-size-16;
                line-height: $line-height-24;
                margin-top: 24px
            }
        }

        &_badge {
            display: inline-block;
            background-color: $color-green-2;
            border-radius: $border-radius-regular;
            padding: 4px 8px;
            margin-right: auto;
            margin-left: 0;
            font-weight: $font-weight-600;
            font-size: $font-size-16;
            color: $color-blue-2;
            margin-top: 8px;

            &.disabled {
                background-color: $disabled-bg-color;
                color: $disabled-font-color;
            }
        }
    }

    &__wheel {
        position: relative;
        border-radius: $border-radius-regular;
        background-color: $color-primary;

        &.mobile {
            padding: 0 !important;
            background-color: unset;
            margin-top: 0 !important;
        }

        .mdc-layout-grid__inner {
            gap: 0;
        }

        .wheel-badge-mobile, .disclaimer-mobile {
            text-align: center;
            margin-bottom: 10px;

            .disclaimer {
                color: $disabled-font-color;
                font-weight: $font-weight-400;
                font-size: $font-size-12;
                font-family: $font-secondary;
            }
        }

        &_wrapper {
            position: relative;

            .spin-buttons {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 24px;

                &.center {
                    justify-content: center;
                }

                &.mobile {
                    grid-row: 2;
                    justify-content: center;
                    align-items: flex-start;
                    text-align: center;

                    background-color: $color-primary;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 24%;
                    z-index: 100;

                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;

                    &.disabled.spinning {
                        height: 24%;
                    }

                    &.disabled {
                        height: 28%;
                    }
                }

                .time-left {
                    color: $color-gray-3;
                    font-weight: $font-weight-400;
                    font-size: $font-size-14;
                    line-height: $line-height-20;
                    font-family: $font-commissioner;
                    margin-top: 8px;
                }

                .campaign-ended {
                    color: $color-gray-3;
                    font-weight: $font-weight-700;
                    font-size: $font-size-24;
                    line-height: $line-height-32;
                    font-family: $font-commissioner;

                    &__disclaimer {
                        color: $color-gray-3;
                        font-weight: $font-weight-400;
                        font-size: $font-size-14;
                        line-height: $line-height-20;
                        font-family: $font-commissioner;
                    }
                }

                .refresh-button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .icon-angle-right {
                        margin-left: 24px;
                        color: $color-blue-3;
                        opacity: 1;

                        &.disabled {
                            visibility: hidden;
                        }

                        &.loading::before {
                            display: inline-block;
                            animation: fade-out 1s forwards infinite;
                        }

                        @keyframes fade-out {
                            0% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 0;
                            }
                        }
                    }

                    @for $i from 1 through 5 {
                        .icon-angle-right.loading:nth-child(#{$i}n)::before {
                            animation-delay: #{$i * 0.125}s;
                        }
                    }

                    .icon-refresh.loading::before {
                        display: inline-block;
                        animation: rotation 1s linear infinite;
                    }

                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }

                    .arrows {
                        color: white;
                    }

                    button {
                        background-color: $color-blue-3;
                        border-radius: $border-radius-large;
                        color: $color-primary;
                        font-weight: $font-weight-600;
                        font-size: $font-size-20;
                        line-height: $line-height-22;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.disabled {
                            background-color: $disabled-bg-color;
                            color: $disabled-font-color;
                            pointer-events: none;
                            cursor: none;
                        }

                        i {
                            font-size: $font-size-24;
                        }

                        &.mobile {
                            padding: 24px;
                        }
                    }
                }
            }

            .content {
                $z-index-back: 10;
                $z-index-middle: 20;
                $z-index-front: 30;

                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                width: 74%; // change size of wheel on desktop
                z-index: 0; // create new stacking context
                padding: 24px 0 7px 0;

                &.mobile {
                    width: 110%; // wheel size on mobile
                    transform: translateX(-5%);
                    padding-top: 0;

                    img.wheel-pointer-bg,
                    img.wheel-pointer {
                        padding-top: 0;
                    }

                    img.wheel-disabled {
                        height: auto;
                        width: 100%;
                    }
                }

                img.wheel-pointer-bg,
                img.wheel-pointer {
                    width: 20%;
                    position: absolute;
                    top: 1%;
                    padding-top: 24px;
                }

                img.wheel-disabled {
                    position: absolute;
                    z-index: $z-index-front;
                    top: 0;
                    height: 100%;
                }

                img.wheel-pointer {
                    z-index: $z-index-front;
                }

                img.wheel-pointer-bg {
                    z-index: $z-index-back;
                }

                img.circle {
                    --spin-value: 0deg;
                    --spin-time: 5s;

                    width: 100%;
                    transition: all var(--spin-time) ease-out;
                    animation-fill-mode: forwards;
                    animation-timing-function: linear;
                    z-index: $z-index-middle;

                    &.spinning {
                        animation-duration: var(--spin-time);
                        animation-timing-function: cubic-bezier(0.440, -0.205, 0, 1);
                        animation-name: spinning;
                        animation-iteration-count: 1;
                    }

                    @keyframes spinning {
                        from { transform: rotate(0); }
                        to {  transform: rotate(var(--spin-value)); }
                    }
                }
                .disclaimer {
                    color: $color-gray-3;
                    font-weight: $font-weight-400;
                    font-size: $font-size-12;
                    font-family: $font-secondary;
                    padding-bottom: 17px;
                }
            }
        }
    }

    &__prize-modal {
        --bg-image-dark: none;
        --bg-image-light: none;

        .no-win {
            font-weight: $font-weight-700;
            font-size: $font-size-38;
            line-height: $line-height-42;
            text-align: center;
            color: $color-primary;
        }

        .mdc-dialog__surface {
            border-radius: $border-radius-large !important;

            background-image:
                var(--bg-image-dark),
                var(--bg-image-light);
            background-repeat: no-repeat;
            background-size: auto, cover;
        }

        .mdc-dialog__title {
            align-self: flex-end;

            button { all: unset; cursor: pointer; }
        }

        .mdc-dialog__content {
            display: flex;
            flex-direction: column;

            .prize-image {
                width: 40%;
                align-self: center;
                margin-top: 40px;
            }

            .prize-name {
                text-align: center;
                font-size: $font-size-38;
                font-weight: $font-weight-700;
                line-height: $line-height-42;
                color: $color-primary;
                margin-top: 16px;
                font-family: $font-commissioner;

                .money-euro {
                    font-size: $font-size-26;
                }
            }

            .prize-description {
                font-weight: $font-weight-400;
                font-size: $font-size-16;
                line-height: $line-height-24;
                color: $color-blue-2;
                text-align: center;
                margin-top: 16px;
                font-family: $font-commissioner;
            }

            .dialog-button {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 1 1 100%;

                button {
                    color: $color-blue-4;
                    font-weight: $font-weight-500;
                    font-size: $font-size-20;
                    line-height: $line-height-22;
                    font-family: $font-commissioner;
                }
            }
        }
    }
}