$sprite-img: '../../images/sprite.png';
// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$flag-ee-name: 'flag-ee';
$flag-ee-x: 0px;
$flag-ee-y: 0px;
$flag-ee-offset-x: 0px;
$flag-ee-offset-y: 0px;
$flag-ee-width: 48px;
$flag-ee-height: 48px;
$flag-ee-total-width: 101px;
$flag-ee-total-height: 101px;
$flag-ee-image: $sprite-img;
$flag-ee: (0px, 0px, 0px, 0px, 48px, 48px, 101px, 101px, $sprite-img, 'flag-ee', );
$flag-gb-name: 'flag-gb';
$flag-gb-x: 53px;
$flag-gb-y: 0px;
$flag-gb-offset-x: -53px;
$flag-gb-offset-y: 0px;
$flag-gb-width: 48px;
$flag-gb-height: 48px;
$flag-gb-total-width: 101px;
$flag-gb-total-height: 101px;
$flag-gb-image: $sprite-img;
$flag-gb: (53px, 0px, -53px, 0px, 48px, 48px, 101px, 101px, $sprite-img, 'flag-gb', );
$flag-ru-name: 'flag-ru';
$flag-ru-x: 0px;
$flag-ru-y: 53px;
$flag-ru-offset-x: 0px;
$flag-ru-offset-y: -53px;
$flag-ru-width: 48px;
$flag-ru-height: 48px;
$flag-ru-total-width: 101px;
$flag-ru-total-height: 101px;
$flag-ru-image: $sprite-img;
$flag-ru: (0px, 53px, 0px, -53px, 48px, 48px, 101px, 101px, $sprite-img, 'flag-ru', );
$spritesheet-width: 101px;
$spritesheet-height: 101px;
$spritesheet-image: $sprite-img;
$spritesheet-sprites: ($flag-ee, $flag-gb, $flag-ru, );
$spritesheet: (101px, 101px, $sprite-img, $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
