.mdc-icon-button {
  $this: &;

  // cutom icon button
  &#{$this}--custom {
    display: block;
    line-height: 1em;

    #{$this}__icon {
      line-height: inherit;
    }
  }

  &#{$this}--clear,
  & {
    padding: 0;
    height: auto;
    width: auto;

    #{$this}__icon {
      line-height: inherit;

      &.small {
        font-size: rem-calc(21);
      }

      &.marked:after {
        position: absolute;
        right: rem-calc(-4);
        top: rem-calc(-2);
        width: rem-calc(6);
        height: rem-calc(6);
        border-radius: 50%;
        background-color: $color-pink;
        content: '';

        @include responsive_max(sm) {
          width: rem-calc(8);
          height: rem-calc(8);
          
          @include theme() {
            border: 2px solid t($t-color-secondary);
          }
        }

        @include responsive_min(sm) {
          right: -1px;
          top: 7px;
        }
      }
    }

    &:before,
    &:after {
      display: none;
    }
  }

  // remove border in FF
  &::-moz-focus-inner {
    border: 0;
    outline: none;
  }
}

.icon-delete {
  position: absolute;
  transform: translateY(-45%);
  right: rem-calc(-50);
  &::before {
    color: $color-red;
  }
}

.icon-red {
  &::before {
    color: $color-red;
  }
}