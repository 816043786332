.panel, .panel-lv {
  padding: rem-calc(30) rem-calc(20) rem-calc(30);

  margin: 0 rem-calc(10) rem-calc(10) 0;
  text-align: center;
  @include theme() {
    background-color: t($t-color-background);
    a {
      color: t($t-color-link);
    }
  }

  &-container {
    display: flex;
    flex-wrap: wrap;

    @include responsive_max(sm) {
      .panel {
        width: 100%;
      }
      > div {
        margin-right: 0;
      }
    }

    @include responsive_min(sm) {
      .panel {
        width: calc(50% - 5px);
        &-lv {
          width: calc(50% - 10px);
        }
      }
      > div:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include responsive_min(md) {
      .panel {
        width: calc(33.3333% - 7px);
        &-lv {
          width: calc(50% - 10px);
        }
      }
      > div:nth-child(2n) {
        margin-right: rem-calc(10);
      }
      > div:nth-child(3n) {
        margin-right: 0;
      }
    }

    @include responsive_min(xlg) {
      .panel {
        width: calc(25% - 8px);
        &-lv {
          width: calc(50% - 10px);
        }
      }
      > div:nth-child(4n) {
        margin-right: 0;
      }
      > div:nth-child(3n) {
        margin-right: rem-calc(10);
      }
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    color: $primary;
    height: rem-calc(120);
  }

  &__table {
    display: table;
    border-top: 2px solid $color-blue-40;
    width: 100%;
    border-collapse: collapse; 
  }

  &__row {
    display: table-row;
    border-bottom: 2px solid $color-blue-40;
    text-align: left;
    white-space: nowrap;

    > div {
      padding: rem-calc(10);
    }

    > div:last-child {
      text-align: right;
    }
  }

  &__cell {
    display: table-cell;
  }
}

.panel .panel__button {
  margin: rem-calc(30) auto rem-calc(30);
  display: block;
  @include theme() {
    background-color: t($t-color-background);
    border: 2px solid t($t-color-primary);
    color: t($t-color-primary);

    &.active {
      background-color: $color-green;
      color: $color-white;
      border: none;
    }
  }
}

.panel-lv .panel__button {
  margin: rem-calc(30) auto rem-calc(30);
  display: block;
  width: 50%;
  @include theme() {
    background-color: t($t-color-background);
    border: 2px solid t($t-color-primary);
    color: t($t-color-primary);

    &.active {
      background-color: $color-green;
      color: $color-white;
      border: none;
    }
  }
}

.panel--disabled {
  opacity: 0.5;
}