$mdc-dialog-scrim-opacity: .5;

.mdc-dialog {
  z-index: 102 !important;

  // body overlay
  &--overlay {
    overflow: hidden;
    
    .mdc-dialog__scrim {
      -webkit-overflow-scrolling: touch;
      pointer-events: none;
    }
  }

  &--wrapper {
    position: relative;

    .mdc-dialog {
      position: absolute;
      padding-top: rem-calc(45);
      align-items: flex-start;

      @include responsive_min(lg) {
        padding-top: rem-calc(112);
      }
    }

    .mdc-dialog__scrim {
      position: absolute;
    }
  }

  .mdc-dialog__title.mdc-dialog__title--compact {
    padding: 0;
    height: rem-calc(20);
    .mdc-dialog__button.mdc-dialog__button--close {
      right: rem-calc(24);
      top: rem-calc(12);
    }
  }

  .information__head {
    overflow: hidden;
  }

  // content section
  .mdc-dialog__content {
    padding: rem-calc(20) rem-calc(24);

    &--action {
      display: flex;
      flex-direction: column;

      @include responsive_min(sm) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .mdc-button.last {

        @include responsive_max(sm) {
          margin-top: rem-calc(30);
          order: 10;
        }
      }
    }
  }

  // surface section
  .mdc-dialog__surface {
    border-radius: 0 !important;
    box-shadow: 0px rem-calc(36) rem-calc(72) rgba($color-black, .12);
  }

  // button close dialog
  .mdc-dialog__button--close {
    position: absolute;
    top: rem-calc(16);
    right: rem-calc(12);
    min-width: auto;
    height: auto;
    padding: 0;
    background: none !important;
    font-size: rem-calc(16);
    line-height: rem-calc(25); // 25px
    color: $secondary;
    letter-spacing: 0;
    z-index: 1;
    transition: color .15s;

    &.mdc-button--text {
      right: rem-calc(16);
      color: $secondary;

      @include responsive_min(md) {
        top: rem-calc(30);
        right: rem-calc(45);
      }

      &:hover
      &:focus {
        color: $primary;
      }
    }

    @include responsive_max(md) {
      height: auto;
      width: auto;
      padding: 0;
      font-size: rem-calc(16);
    }

    @include responsive_min(md) {
      top: rem-calc(14);
      right: rem-calc(15);
      font-size: rem-calc(22);
      line-height: rem-calc(25); // 25px
    }

    &:before {
      display: block;
      position: static;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      background: none;
      opacity: 1;
      color: inherit;
    }

    &:hover,
    &:focus,
    &:active {
      color: $primary !important;

      &:before {
        opacity: 1 !important;
      }
    }

    &.link {
      top: rem-calc(12);
      color: $color-gray-80;

      @include responsive_min(sm) {
        top: rem-calc(32);
        right: rem-calc(31);
        line-height: rem-calc(25); // 25px
      }
    }
  }

  // language menu
  .menu-links {
    position: absolute;
    top: rem-calc(9);
    right: rem-calc(58);
    font-size: rem-calc(14);
    line-height: 1.572em;
    text-transform: uppercase;

    @include responsive_min(sm) {
      top: rem-calc(30);
      right: rem-calc(90);
      font-size: rem-calc(16);
      line-height: 1.5em;
    }

    a {
      float: left;
      margin-left: rem-calc(15);
      color: $color-blue-10;
      text-decoration: none;
      transition: color .15s;

      &:hover:not(.active),
      &:focus:not(.active) {
        color: $primary;
      }

      &.active {
        color: $color-gray-80;
        cursor: default;
      }
    }
  }

  // dialog modification "wide"
  &.mdc-dialog--wide {

    .mdc-dialog__surface {
      width: rem-calc(1248);
      max-width: calc(100vw - 16px * 2);

      @include responsive_max(sm) {
        max-height: calc(80vh);
      }
    }

    .mdc-dialog__content {
      padding: rem-calc(31) 0;

      @include responsive_min(md) {
        padding: rem-calc(75) 0 rem-calc(70);
      }

      .mdc-layout-grid {
        // max-height: 100%;
        // overflow-y: auto;

        &__inner {
          grid-row-gap: rem-calc(26);
        }
      }
    }

    @include responsive_max(md) {
      .information {
        padding: 0 rem-calc(16);

        &__head {
          margin-right: rem-calc(70);
        }
      }
    }
  }

  &.mdc-dialog--confirm {
    .mdc-dialog__surface {
      width: auto !important;
    }

    .mdc-dialog__content {
      padding: rem-calc(16) !important;
    }

    .mdc-dialog__scrim {
      opacity: 0 !important;
    }
  }

  // dialog modification "simple"
  &.mdc-dialog--simple {

    .mdc-dialog__surface {
      width: calc(100vw - 24px*2);
      max-width: 100%;

      @include responsive_min(md) {
        width: rem-calc(770);
      }

      @include responsive_max(md) {
        @media (orientation: landscape){
          max-height: calc(75vh);
        }
      }
    }

    &.wide {

      // button close dialog
      .mdc-dialog__button--close.link {

        @include responsive_min(md) {
          top: rem-calc(30);
          right: rem-calc(30);
        }
      }

      .mdc-dialog__surface {

        @include responsive_min(lg) {
          width: rem-calc(970);
        }

        @include responsive_max(lg)  {
          @media (orientation: landscape){
            max-height: calc(80vh);
          }
        }
      }

      .mdc-dialog__content {

        @include responsive_min(sm) {
          padding: rem-calc(60) rem-calc(90);
        }
      }
    }

    .mdc-dialog__content {
      margin-top: rem-calc(36);
      margin-bottom: rem-calc(24);
      padding: 0 rem-calc(24) rem-calc(21);

      @include responsive_min(sm) {
        margin: 0;
        padding: rem-calc(60) rem-calc(90) rem-calc(90);
      }

      .mdc-text-field--outlined .mdc-text-field__input {

        @include responsive_min(sm) {
          font-size: rem-calc(18);
        }
      }

      .mdc-button {

        &:first-child:last-child {

          @include responsive_max(sm) {
            margin-top: rem-calc(5);
          }
        }

        & ~ .mdc-button {

          @include responsive_max(sm) {
            margin-top: rem-calc(30);
          }
        }
      }
    }

    @include responsive_max(sm) {
      .information {

        &__head {
          margin-bottom: rem-calc(20) !important;
        }
      }
    }
  }

  // dialog modification "info"
  &.mdc-dialog--info {

    // button close dialog
    .mdc-dialog__button--close.link {
      top: rem-calc(16);
      right: rem-calc(16);
      font-size: rem-calc(22);
      line-height: rem-calc(25);

      @include responsive_min(md) {
        top: rem-calc(72);
        right: rem-calc(56);
      }
    }

    .mdc-dialog__surface {
      width: calc(100vw - 24px*2);
      max-width: 100%;

      @include responsive_min(md) {
        width: rem-calc(770);
      }

      @include responsive_min(lg) {
        width: rem-calc(970);
      }

      @include responsive_max(md) {
        @media (orientation: landscape) {
          max-height: calc(75vh);
        }
      }
    }

    .mdc-dialog__content {
      padding: rem-calc(48) rem-calc(32) rem-calc(42);

      @include responsive_min(sm) {
        padding: rem-calc(60) rem-calc(83) rem-calc(90);
      }
    }
  }

  // dialog modification "info wide"
  &.mdc-dialog--info-wide {

    // button close dialog
    .mdc-dialog__button--close.link {
      top: rem-calc(16);
      right: rem-calc(16);
      font-size: rem-calc(22);
      line-height: rem-calc(25);

      @include responsive_min(md) {
        top: rem-calc(30);
        right: rem-calc(30);
      }
    }

    .mdc-dialog__surface {
      width: calc(100vw - 24px*2);
      max-width: 100%;

      @include responsive_min(md) {
        width: rem-calc(770);
      }

      @include responsive_min(lg) {
        width: rem-calc(970);
      }

      @include responsive_min(xlg) {
        width: rem-calc(1280);
      }
    }

    .mdc-dialog__content {
      padding: rem-calc(48) rem-calc(32) rem-calc(42);

      @include responsive_min(lg) {
        padding: rem-calc(90) rem-calc(160) rem-calc(60);
      }
    }
  }

  &.mdc-dialog--order {

    .mdc-dialog__button--close {
      display: none;
    }

    .mdc-dialog__surface {
      width: calc(100vw - 24px*2);
      max-width: 100%;
      max-height: none;

      @include responsive_min(md) {
        width: rem-calc(810);
      }

      @include responsive_min(lg) {
        width: rem-calc(970);
      }
    }

    .mdc-dialog__content {
      padding: rem-calc(24);
      letter-spacing: 0;

      @include responsive_min(md) {
        padding: rem-calc(30);
      }

      @include responsive_min(lg) {
        padding: rem-calc(60) rem-calc(70);
      }
    }

    .mdc-dialog__scrim {
      background: rgba($color-white, .9);
    }
  }
}
