@import "../helpers/mixins";
@import "../helpers/functions";
@import "../helpers/variables";
@import "../base/typography";


.campaign {
  &-wrap {
    max-width: rem-calc(620);
    margin: 0 auto;
  }

  &-center-aligned {
    @include responsive_min(md) {
      padding-left: 15%;
    }
  }

  &-banner * {
    z-index: 0;
  }

  &-banner {
    padding: 60% 0 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../images/coffeecampaign.png');

    &:before,
    &:after {
      height: 6%;
    }
  }

  &-title {
    color: $color-white
  }

  &-link {
    background: $secondary;
    color: $color-white;
    padding: rem-calc(10) rem-calc(25);
    display: inline-block;
  }


  &-confirmation {
    background: $color-green-100;
    text-align: center;
    padding: rem-calc(100) 0;
    position: relative;

    &:before,
    &:after {
      background: $color-white;
      content: '';
      display: block;
      height: rem-calc(50);
      left: 0;
      position: absolute;
      right: 0;
      transform: skewY(-2deg);
    }

    &:before {
      top: 0;
      transform-origin: 0 0;
    }

    &:after {
      bottom: 0;
      transform-origin: 100% 0;
    }
  }

      &-terms{
        background-color: $color-blue-50;
      }
}

.campaign-section{
  color: $color-primary;
  background-color: $color-blue-50;
  border-radius: rem-calc(10);
  margin: 0 !important;
  padding: 0 !important;
  border: 1px solid $color-blue-20;

  &-stampcount{
    font-family: $font-secondary;
    font-size: rem-calc(24);
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  &-image{
    background-image: url('../../images/coffeecampaign.png');
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: rem-calc(10);
  }

  &-padding {
    padding: rem-calc(24);
  }

  &-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      width: 100%;
      margin: rem-calc(8);
    }
  }
}
