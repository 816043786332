@import "../helpers/variables";
@import "../helpers/functions";

.select-scroll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include responsive_max(sm) {
        .mdc-button {
            height: rem-calc(48);
        }
    }
}