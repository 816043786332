@import "../helpers/mixins";
@import "../helpers/functions";
@import "../helpers/variables";


.contribution {
  &-wrap {
    max-width: rem-calc(620);
    margin: 0 auto;
  }

  &-banner * {
    z-index: 0;
  }

  &-banner-2 {
    padding: 68% 0 0 0; // 68% is current ratio of banner image, if image is changed, ratio might need to be changed too
    margin-bottom: rem-calc(20);
    background-size: cover;

    &:before,
    &:after {
      height: 6%;
    }

    &--et {
      background-image: url('../../images/community_banner_2_et.jpg');
    }

    &--en {
      background-image: url('../../images/community_banner_2_en.jpg');
    }

    &--ru {
      background-image: url('../../images/community_banner_2_ru.jpg');
    }

    &--basketball {
      background-image: url('../../images/basketball-community-banner.jpg');
    }
  }

  &-title {
    color: $color-white
  }

  &-link {
    background: $secondary;
    color: $color-white;
    padding: rem-calc(10) rem-calc(25);
    display: inline-block;
  }

  &-shifted {
    @include responsive_min(xlg) {
      padding-left: rem-calc(52);
    }

    .mdc-form-field .mdc-radio+label {
      font-weight: 400;
    }

    @include responsive_max(sm) {
      .mdc-button {
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

  }

  &-first-step:before,
  &-second-step:before,
  &-third-step:before {
    border-radius: 50%;
    color: $color-white;
    padding: rem-calc(8);
    font-size: rem-calc(13);
    line-height: 1;
    margin-right: rem-calc(25);
    width: rem-calc(28);
    height: rem-calc(28);
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }

  &-first-step:before {
    content: "1";
    background: $color-green;
  }

  &-second-step:before {
    content: "2";
    background: $secondary;
  }

  &-third-step:before {
    content: "3";
    background: $secondary;
  }

  &-confirmation {
    background: $color-green-100;
    text-align: center;
    padding: rem-calc(100) 0;
    position: relative;

    &:before,
    &:after {
      background: $color-white;
      content: '';
      display: block;
      height: rem-calc(50);
      left: 0;
      position: absolute;
      right: 0;
      transform: skewY(-2deg);
    }

    &:before {
      top: 0;
      transform-origin: 0 0;
    }

    &:after {
      bottom: 0;
      transform-origin: 100% 0;
    }
  }

  &-icon .icon {
    cursor: pointer;
    color: $color-white;
    background: $primary;
    width: rem-calc(30);
    height: rem-calc(30);
    border-radius: 50%;
    vertical-align: middle;
    text-align: middle;
    font-size: rem-calc(14);
    padding: rem-calc(8);
    margin: 0 rem-calc(5);
  }

  &-slanted-line {
    border-bottom: 2px solid $color-blue-40;
    transform: skewY(-2deg);
  }
}

.filter-form__link .icon.contribution-big-icon {
  font-size: rem-calc(20);
  padding-left: rem-calc(10);
  vertical-align: -2px;
}

.bold-field-value .mdc-typography--body1 {
  font-weight: 700;
  color: $color-blue-10;
}

.correct-row {
  font-size: rem-calc(25);
  color: $color-green;
  float: left;
  margin-right: rem-calc(15);
  line-height: 1.2;
}

.correct-row-title {
  padding-left: rem-calc(40);
}

.contribution-color-scale {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 0px !important;
}

.contribution-color-scale-item {
  height: 40px;
  flex: none;
  padding: 8px 0;
  text-align: center;
  color: $color-white;

  @include responsive_between(sm, lg) {
    left: -50%;
    position: relative;
  }
}

.contribution-county-map {
  margin: auto;
  display: block;
  fill: none;

  path.selectable {
    cursor: pointer;
  }
}

.county-map-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contribution-filter {
  @include responsive_min(sm) {
    margin: 0 0 0 -25% !important;
  }
}

.contribution-separator {

  &--community {
    @include responsive_min(sm) {
      border-left: 1px solid $color-gray-20;
      border-right: 1px solid $color-gray-20;
    }
  }

  &--basketball {
    @include responsive_min(sm) {
      border-left: 1px solid $color-gray-20;
    }
  }
  
}

.contribution-link-mobile {
  @include responsive_max(sm) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem-calc(10);

    & span {
      font-size: rem-calc(22);
    }
  }
}