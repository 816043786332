@import "../helpers/functions";
@import "../helpers/variables";
@import "../components/icons";

$ui-speed: 0.5s;
$color-light-blue: #40D5FF;
$color-lightless-blue: #00BCF1;
$color-teal: #C0FFD3;
$color-sandy: #FFF6E1;
$canvas-width: 375px;
$canvas-height: 667px;
$canvas-mobile-width: 100%;
$canvas-mobile-height: 100%;
$carousel-slide-padding-top: 68px;

// TODO: This should be defined inside /components/icons sass file, like all others .icon-{{iconName}} css styles.
// But by some reason, if you add it there, then "npm run build" will fail. Not because of this particular css style rule,
// but seems like because of number of included icon2 mixins there. If you add it there, npm run build will fail,
// but if you add it and also remove some of the previous included icon2 mixins, npm run build will succeed.
.icon-share {
  @include icon2(share);
}

.wrapped {
  font-family: Commissioner, Arial, Helvetica, sans-serif;

  H1, H2, H3, H4, H5, H6 {
    font-family: AdieuBold, Arial, Helvetica, sans-serif;
  }

  /** Main parts */
  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10001;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__intro {
    background-color: $primary;
    position: relative;
    width: $canvas-width;
    height: $canvas-height;
    overflow: hidden;

    &-content {
      position: relative;
      height: 100%;
      z-index: 1;
    }

    &-logo {
      margin: 0 auto;
      max-width: 240px;
      padding-top: 34px;
    }

    &-heading {
      position: absolute;
      left: 40px;
      bottom: 72px;
      font-size: 48px;
      line-height: 49px;
      opacity: 1;
      visibility: visible;
      transition: visibility $ui-speed, opacity $ui-speed;

      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-heading-01 {
      font-size: 52px;
      line-height: 55px;
      color: $color-teal;
      text-align: right;
      right: 40px;
    }

    &-heading-02 {
      color: white;
      bottom: 208px;
    }

    &-heading-03 {
      color: $primary;
    }

    &-heading-04 {
      color: $color-teal;
    }

    &-year-dices {
      position: absolute;
      max-width: 100%;
      top: 0;
      left: 50%;
      transform: translate(-50%, 15%);
      transition: transform $ui-speed;

      &--lifted {
        transform: translate(-50%, -20%);
      }
    }

    &-waves-blue {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-6%);
      transition: transform $ui-speed;

      &--lifted {
        transform: translateY(0);
      }
    }

    &-waves-teal {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateY(60%);
      transition: transform $ui-speed;

      &--lifted-01 {
        transform: translateY(50%);
      }

      &--lifted-02 {
        transform: translateY(0);
      }
    }

    &-waves-dark-blue {
      position: absolute;
      top: 0;
      transform: translateY(75%);
      width: 100%;
      height: 100%;
      transition: transform $ui-speed;

      &--lifted {
        transform: translateY(0);
      }
    }
  }

  &__outro {
    background-color: $primary;
    position: relative;
    width: $canvas-width;
    height: $canvas-height;
    overflow: hidden;

    &-step-2 {
      .wrapped__outro-content { justify-content: center; }
    }

    &-step-3 {
      &.wrapped__outro { background-color: $color-lightless-blue; }
      .wrapped__outro-content { align-items: center; }
    }

    &-content {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      z-index: 2;
      opacity: 1;
      visibility: visible;
      transition: visibility $ui-speed, opacity $ui-speed;

      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-logo {
      margin: 0 auto;
      max-width: 327px;
      padding-top: 45px;
    }

    &-heading {
      font-size: 48px;
      line-height: 49px;
      text-align: center;
      color: #40D5FF;
      opacity: 1;
      visibility: visible;
      transition: visibility $ui-speed, opacity $ui-speed;

    }

    &-heading-1-1 {
      margin-top: 116px;
      margin-left: 23px;
      font-size: 32px;
      line-height: 55px;
      color: $color-white;
      text-align: left;
    }

    &-heading-1-2 {
      margin: auto 24px 126px;
      font-size: 52px;
      line-height: 55px;
      text-align: right;
    }

    &-heading-2-1 {
      font-size: 130px;
      line-height: 156px;
    }

    &-heading-2-2 {
      font-size: 52px;
      line-height: 55px;
    }

    &-heading-3-1 {
      font-size: 24px;
      line-height: 28px;
      margin-top: 92px;

      span > span {
        font-size: 44px;
        line-height: 47px;
      }
    }

    .actions2 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 40px;
    }

    .actions3 {
      width: 100%;
      max-width: $canvas-width;
      padding: 0 24px;
      margin: auto 0 64px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .mdc-button:first-of-type {
        padding: 0;

        .mdc-button__icon {
          margin-right: 0;
        }
      }

    }

    .mdc-button {
      background-color: $color-lightless-blue;
      border-radius: 20px;
      height: 40px;
      min-width: 40px;
      color: $primary;
    }

    &-year-dices {
      position: absolute;
      max-width: 100%;
      top: 3%;
      left: 0;
      right: 0;
      margin: 0 auto;

      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-wave-bg {
      z-index: 1 !important;
      min-height: $canvas-height;
    }

    &-offer {
      width: 733px;
      height: 1100px;

      &-wrapper {
        position: absolute;
        bottom: -40px;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
      }

      &--hidden {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__carousel {
    width: $canvas-width;
    height: $canvas-height;

    &-slide {
      width: $canvas-width;
      height: $canvas-height;
      position: relative;
      padding-top: $carousel-slide-padding-top;

      &__background {
        position: absolute;
        top: 0;
        z-index: -1;
      }

      &__foreground {
        position: absolute;
        top: 0;
        z-index: 1;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        z-index: 1;
        width: $canvas-width;
        height: 68px;
        background: linear-gradient(180deg, rgba(23, 32, 87, 0.7) 0%, rgba(23, 32, 87, 0) 91.18%);
      }

      h1 {
        font-size: rem-calc(32);
        line-height: rem-calc(36);
        color: $primary;
        margin: 0 20px;
        text-align: center;
      }

      h2 {
        font-size: rem-calc(24);
        line-height: rem-calc(28);
        color: $primary;
        margin: 0 20px;
        text-align: center;
      }

      h3 {
        font-size: rem-calc(22);
        line-height: rem-calc(26);
        color: $primary;
        margin: 0 20px;
        text-align: center;
      }

      p {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        color: $primary;
        margin: 0 20px;
        text-align: center;
      }

      i {
        font-style: italic;
      }

      img.full-screen {
        position: absolute;
        top: 0;
        width: 100%;
        max-width: 375px;
      }
    }
  }

  &__share-btn {
    position: absolute;
    bottom: 16px;
    color: white !important;
    font-size: rem-calc(18);
    line-height: rem-calc(26);
    height: auto;
    transform: translateY(calc(333px - 50vh));
    box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1) !important;
    z-index: 10;

    &__icon {
      width: 36px !important;
      height: 36px !important;
      font-size: 20px !important;
      background-color: white;
      color: $color-primary;
      border-radius: 50%;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }

  /** Mobile adaptation */
  &--mobile {
    .wrapped__intro {
      width: $canvas-mobile-width;
      height: $canvas-mobile-height;
    }

    .wrapped__outro {
      width: $canvas-mobile-width;
      height: $canvas-mobile-height;
    }

    .wrapped__carousel {
      width: $canvas-mobile-width;
      height: 100%;

      &-slide {
        width: $canvas-mobile-width;
        height: $canvas-mobile-height;

        &__background {
          width: $canvas-mobile-width;
          height: $canvas-mobile-height;
        }

        &__foreground {
          width: $canvas-mobile-width;
          height: $canvas-mobile-height;
        }

        &:before {
          width: $canvas-mobile-width;
        }
      }
    }

    &.wrapped__container {
      background-color: $color-gray-50;
    }

    .wrapped__share-btn {
      transform: none;
    }
  }

  /** Slides */
  &__el-general {
    &__content {
      position: relative;
      z-index: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 24px;
      padding-bottom: 65px;
    }

    &__proper-level {
      width: 100%;
      max-width: 372px;
      margin: 0 auto;
    }

    &__waves {
      position: absolute;
      bottom: 0;
    }
  }

  &__el-personal-fix {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      h1 {
        color: $color-sandy;
      }

      img {
        margin-left: auto;
      }

      h3 {
        color: $color-sandy;
        margin-bottom: 8px;
      }
    }
  }

  &__el-personal-pingevaba {
    background-color: #172057;

    &__content {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      position: relative;
      z-index: 1;

      p {
        color: $color-sandy;
        padding-bottom: 16px;
      }

      div.tableRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 8px;

        span.top {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          h1 {
            margin-right: 4px;
          }

          h3 {
            margin-left: 4px;
          }
        }
      }

      h1, h3 {
        color: #40D5FF;
      }
    }

    &__piggy {
      position: absolute;
      width: 100%;
      max-width: 375px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &__el-personal-bors {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        color: $color-sandy;
        margin-top: 24px;
      }

      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 8px;

        &:first-of-type {
          margin-top: 24px;
        }

        span.top {
          display: flex;
          flex-direction: row;
          align-items: baseline;

          h1 {
            margin-right: 4px;
          }

          h3 {
            margin-left: 4px;
          }
        }
      }

      h1, h3 {
        color: #C0FFD3;
      }
    }
  }

  &__gas-general {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        color: #40D5FF;
        margin-top: 24px;
      }

      h1 {
        margin-top: 148px;
        color: #FFF;

        span.big {
          font-size: 142px;
          font-weight: 700;
          line-height: 42px;
        }
      }

      h2 {
        color: #40D5FF;
        margin-top: auto;
        margin-bottom: 64px;
      }
    }

    &__foreground-eel {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
    }

    &__background-eel {
      position: absolute;
      top: 30%;
      right: 0;
      z-index: -1;
      width: auto;
      height: auto;
    }
  }

  &__gas-personal {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      img {
        margin: 0 auto;
      }

      h1 {
        color: #FFCCD3;
      }
    }
  }

  &__food-general {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 64px;

      p {
        color: #FDF8C7;
      }

      h1 {
        color: #FDF8C7;
        margin-top: auto;
      }
    }

    .imageFrame {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      img {
        margin: 0 auto;
      }
    }
  }

  &__food-top3 {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      div.imageFrame {
        position: absolute;
        top: 35px;
        width: 100%;
        display: flex;

        img {
          margin: 0 auto;
        }
      }

      div.tableWrapper {
        z-index: 2;

        h1 {
          text-align: start;
          margin-left: 40px;
        }
      }

      div.row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin: 0 20px;

        &:first-of-type {
          margin-top: 8px;
        }

        p {
          text-wrap: nowrap;
        }

        p:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      h1, p {
        color: #FDF8C7;
      }
    }
  }

  &__food-single {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      h1.singleLine {
        height: rem-calc(38);
        overflow: hidden;
      }

      h1, p {
        color: #FDF8C7;
      }
    }

    div.imageFrame {
      position: absolute;
      z-index: -1;
      top: 35px;
      width: 100%;
      display: flex;

      img {
        margin: 0 auto;
      }
    }
  }

  &__drink-general {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 64px;

      img {
        margin: 0 auto;
      }

      h1 {
        span:first-of-type {
          font-size: 83px;
        }
      }

      p, h1 {
        color: #FFF;
      }
    }
  }

  &__drink-refillable {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      p {
        color: #FFF;
      }

      img {
        margin: 0 auto;
      }

      h1 {
        color: #172057;
        z-index: 2;
      }
    }
  }

  &__drink-disposable {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      p {
        color: #FFF;
      }

      img {
        margin: 0 auto;
      }

      div {
        p {
          color: #172057;
        }
      }

      h1 {
        color: #172057;
      }
    }
  }

  &__cylinder-gas-general {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 29px;
      padding-bottom: 64px;

      p {
        color: #FFF;
      }

      img {
        margin: 0 auto;
      }

      h1 {
        color: #C0FFD3;
      }
    }
  }

  &__cylinder-gas-personal {
    background-color: #000633;
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        color: #FFF;
        margin-top: 24px;
      }

      h1 {
        color: $color-lightless-blue;
        margin-top: 45px;
      }
    }

    .imageFrame {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    &__background-gas {
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }

  &__fuel-general {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 24px;
      padding-bottom: 64px;
      height: 100%;

      p, h1 {
        color: #C0FFD3;
      }
    }

    .imageFrame {
      background-color: #050505;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__background {
      width: auto;
      height: auto;
      margin: auto;
    }
  }

  &__fuel-personal {
    background-color: #050505;
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      h1 {
        margin-top: auto;
      }

      p, h1 {
        color: #C0FFD3;
      }

      div.travel {
        position: relative;
        height: 126px;

        &:first-of-type {
          margin-top: auto;
        }

        &:last-of-type {
          margin-bottom: auto;
        }

        img.globe {
          position: absolute;
        }

        div.progress {
          height: 40px;
          left: 0;
          top: 56px;
          position: absolute;

          &.petrol {
            background: linear-gradient(90deg, rgba(192, 255, 211, 0) 0%, #C0FFD3 100%);
          }

          &.diesel {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
          }

          &.ev {
            background: linear-gradient(90deg, rgba(64, 213, 255, 0) 0%, #40D5FF 100%);
          }

          &.top {
            right: 97px;
          }

          &.mid {
            right: 148px;
          }

          &.small {
            right: 197px;
          }

          p {
            color: #172057;
            font-size: 16px;
            font-weight: 700;
            line-height: 36px;
            word-wrap: break-word;
            text-align: right;
            margin-top: 0;
          }
        }

        img.vehicle {
          top: 20px;
          position: absolute;

          &.top {
            right: 87px;
          }

          &.mid {
            right: 138px;
          }

          &.small {
            right: 187px;
          }
        }
      }
    }
  }

  &__ev-charging-general {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        margin-top: auto;
        margin-bottom: 8px;
      }

      h1 {
        margin: 0 0 62px;
      }

      p, h1 {
        color: #40D5FF;
      }
    }
  }

  &__ev-charging-personal {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        margin-top: 24px;
      }

      h1 {
        font-size: 52px;
        margin-top: auto;
        margin-bottom: 100px;
      }

      p, h1 {
        color: #172057;
      }
    }
  }

  &__community-general {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        color: #172057;
        margin-top: 24px;
      }

      h1 {
        font-size: 60px;
        margin: 194px 0 0;
      }

      h3 {
        margin-top: auto;
        margin-bottom: 64px;
      }

      h1, h3 {
        color: #C0FFD3;
      }
    }
  }

  &__community-day {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 84px;

      p {
        color: #172057;
      }

      h1 {
        color: #C0FFD3;
      }
    }
  }

  &__community-member {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-top: 24px;

      p {
        color: #172057;
      }

      h1 {

        &:first-of-type {
          color: #4E6267;
          font-size: 120px;
          margin-top: 118px;
        }

        &:last-of-type {
          color: #C0FFD3;
          margin-top: 178px;
        }
      }
    }
  }

  &__renewable-energy-general {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        &:first-of-type {
          margin-top: 24px;
        }

        &:last-of-type {
          margin-top: auto;
        }
      }

      h1 {
        margin-bottom: 64px;
      }

      p, h1 {
        color: #172057;
      }
    }
  }

  &__renewable-energy-rohesarts {
    background-color: #006B60;
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      p {
        color: #FFF;
      }

      h1 {
        color: #C0FFD3;
        z-index: 2;
      }
    }

    .imageFrame {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__background {
      width: auto;
      height: auto;
      margin: auto;
    }
  }

  &__smart-electricity-general {
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 64px;

      h1 {
        margin-top: 8px;
      }

      p, h1 {
        color: #172057;
      }
    }

    .imageFrame {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__background {
      width: auto;
      height: auto;
      margin: auto;
    }
  }

  &__trailer-general {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        margin-top: 24px;
      }

      h2 {
        margin-top: 24px;
        line-height: 36px;

        span.elephantsCount {
          font-size: 52px;
          line-height: 54px;
        }
      }

      p, h2 {
        color: #172057;
      }
    }

    &__background {
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: 0;
    }
  }

  &__trailer-personal {
    background-color: #40D5FF;
    padding-bottom: 101px;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;

      p {
        color: #172057;
        z-index: 2;
      }

      h1 {
        color: #40D5FF;
        line-height: 24px;
        z-index: 2;
      }
    }

    .imageFrame {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__background {
      width: auto;
      height: auto;
      margin: auto;
    }
  }

  &__loyalty {
    &__content {
      display: flex;
      flex-direction: column;
      height: 100%;

      p {
        color: #FFF6E1;
        margin-top: 24px;
      }

      h1 {
        color: #40D5FF;
        margin-top: auto;
        margin-bottom: 85px;
      }
    }
  }

  &__loyalty-personal {
    background-color: #40D5FF;
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 24px;
      padding-bottom: 92px;

      h1 {
        z-index: 2;
        color: #172057;
      }

      div {
        z-index: 2;
        h1 {
          color: #40D5FF;
        }
      }

      p {
        z-index: 2;
        color: #FFF6E1;
        margin-top: 4px;
      }
    }

    .imageFrame {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__background {
      width: auto;
      height: auto;
      margin: auto;
    }
  }

  &.ru {
    .wrapped__intro-heading-04 {
      font-size: 42px;
      line-height: 43px;
    }

    .wrapped__gas-general__content h1 span.big {
      font-size: 103px;
    }

    .wrapped__food-general__content h1 {
      font-size: rem-calc(28);
    }

    .wrapped__trailer-general__content h2 {
      font-size: rem-calc(18);
    }

    .wrapped__loyalty-personal__content div h1 {
      font-size: rem-calc(20);
      line-height: rem-calc(22);
    }

    .wrapped__loyalty-personal__content div p {
      font-size: rem-calc(16);
      line-height: rem-calc(18);
    }

    .wrapped__outro-heading-2-1 {
      font-size: 100px;
    }
  }

  &.en {
    .wrapped__gas-general__content h1 span.big {
      font-size: 130px;
    }

    .wrapped__food-general__content h1 {
      font-size: rem-calc(28);
    }
  }

  .wrapped__trailer-general__content h2 {
    font-size: rem-calc(14);
  }
}
