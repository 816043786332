/*  
  Radial Graph Settings

  - size:       .radial-graph, .shape, .mask, and .fill will all need
                to receive the same width & height properties. Then
                .mask and .fill need to have their clip property set
                accordingly. 

  - width:      The stroke width is made by just placing a "cutout" shape 
                on top, and can be sized and positioned as you like (or removed).

  - progress:   adjust the .fill.shim rotation to a percentage of 
                the total radius, and then the .mask.full-mask & .fill 
                rotations to a value of half of that. See inline
                comments below for the formulas.

*/

//          HTML structure
// ----------------------------------
// <div class="radial-graph">
//   <div class="shape">
//     <div class="mask full-mask">
//       <div class="fill"></div>
//     </div>
//     <div class="mask">
//       <div class="fill"></div>
//       <div class="fill shim"></div>
//     </div>
//   </div>
//   <div class="cutout">
//   </div>
// </div>

$radial-graph-size: 90px;

.radial-graph,
.shape,
.mask,
.fill {
  width: $radial-graph-size;
  height: $radial-graph-size;
  border-radius: 50%;
}

.shape,
.mask,
.fill {
  position: absolute;
  background-color: #d2ecfa;
}

.mask,
.fill {
  -webkit-backface-visibility: hidden;
  transition: transform 1s;
  border-radius: 50%;
}

.mask {
  clip: rect(0px, $radial-graph-size, $radial-graph-size, $radial-graph-size/2);
}
.fill {
  clip: rect(0px, $radial-graph-size/2, $radial-graph-size, 0px);
  background-color: $primary;
}

.cutout {
  margin-left: 4px;
  margin-top: 4px;
  width: calc(#{$radial-graph-size} - 8px);
  height: calc(#{$radial-graph-size} - 8px);
  position: absolute;
  background-color: $color-white;
  border-radius: 50%;
}

.mask.full-mask,
.fill {
  /*
    For a value of 70%
    0.7 * 360  / 2
  */
  transform: rotate(126deg);
}
.fill.shim {
  /* 
    For a value of 70%
    0.7 * 360
  */
  transform: rotate(252deg);
}


// Radial graph fill by percentage from 0/10 to 10/10
// example: radial-graph--4 for 40% fill
@for $i from 0 through 10 {
  .radial-graph--#{$i} {
    .shape {
      .mask.full-mask,
        .fill {
          transform: rotate(($i/10)*180deg);
        }
        .fill.shim {
          transform: rotate(($i/10)*360deg);
        }
    }
  }
}