.language-selector {
  display: flex;
}
.language-selector > button {
    color: white !important;
}

@include responsive_max(md) {
  .language-selector {
    margin: rem-calc(5) rem-calc(-10) rem-calc(5) 0;
    justify-content: flex-end;
    .mdc-button{
      color: $color-white;
      border-radius: 0;
        &:not(:last-child) {
            border-right: rem-calc(1) solid $color-white;
        }
    }
  }
}
.mobile-language {
  display: flex;
  align-items: flex-end;
  border-radius: rem-calc(50);
  background-color: $primary;

  &.close {
    background-color: $color-white;
    .language-selector {
      display: none;
    }
    .mdc-icon-button--language {
      margin: 0 rem-calc(3);
      width: rem-calc(25);
      color: $primary;
    }
  }

  .mdc-icon-button--language {
    padding: 0;
    font-size: rem-calc(26);
    height: rem-calc(25);
    width: rem-calc(30);
    margin: 0 rem-calc(3) rem-calc(6) 0;
    line-height: 1;
    color: $color-white;
  }
}
  