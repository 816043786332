@import "../helpers/variables";
@import "../helpers/functions";

.service-item {
  margin-bottom: rem-calc(8);

  &__location,
  &__card {
    margin-top: rem-calc(5);

    * {
      line-height: rem-calc(20);
    }
  }
}

.services-wrapper {
  border: 1px solid $color-gray-20;
  padding: rem-calc(8) 0;

  .service-item+& {
    margin-top: rem-calc(24);
  }

  .service-item {
    padding: 0 rem-calc(22);
    margin: 0;

    .badge-card {
      padding-left: 0;
      padding-right: 0;
      border-bottom: 1px solid $color-gray-20;
    }

    &:last-of-type .badge-card {
      border-bottom: none;
    }
  }
}


@include responsive_min(md) {

  .service-item {
    margin-bottom: rem-calc(16);

    .badge-card {
      padding: rem-calc(32) rem-calc(48);
    }
  }

  .services-wrapper {
    padding: rem-calc(16) 0;

    .service-item+& {
      margin-top: rem-calc(32);
    }

    .service-item {
      padding: 0 rem-calc(48);
    }
  }
}