@import "../helpers/variables";
@import "../helpers/functions";

%button-mobile {
    font-weight: bold;
    font-size: 1rem;
    text-transform: none;
    white-space: nowrap;
}

.badge-card {
    $self: &;

    padding: rem-calc(16);
    line-height: 2;
    display: flex;
    flex-direction: column;


    &-body {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
    }

    .badge {
        margin-right: rem-calc(20);
        flex: 0 0 auto;

        @include responsive_min(md) {
            margin-right: rem-calc(56);
        }
    }

    &__content {
        margin-bottom: rem-calc(8);
    }

    &__actions {
        line-height: 1;

        .mdc-button {
            font-weight: bold;
            text-transform: none;
            white-space: nowrap;
            color: $secondary;
            padding: 0;
            justify-content: flex-start;
            height: auto;
            font-size: rem-calc(16);
            line-height: rem-calc(24);

            @include theme() {
                color: t($t-color-info);
            }

            .icon:before {
                padding-right: rem-calc(10);
            }
        }
    }

    &-footer {
        margin-top: rem-calc(16);
    }

    &--highlighted {
        border-radius: rem-calc(8);
        padding: rem-calc(24);

        #{$self}__actions .mdc-button {
            width: 100%;
            height: rem-calc(44);
            justify-content: center;
        }

        &#{$self}--new,
        &#{$self}--gasoMeterSubmission,
        &#{$self}--permissionRequired {
            @include theme() {
                background-color: t($t-color-background);
                border: 2px solid t($t-color-secondary);
            }

            .mdc-button {
                @include theme() {
                    background: t($t-color-secondary-2);
                }

                color: $color-white;
            }
        }

        &#{$self}--inprogress {
            border: 2px solid $color-orange-40;

            .mdc-button {
                background-color: $color-orange-40;
                color: $color-white;
            }
        }

        &#{$self}--done {
            border: 2px solid $color-green;

            .mdc-button {
                border: 1px solid $secondary !important;
            }
        }

        &#{$self}--disabled {
            border: 2px solid $color-gray-30;

            .mdc-button {
                background-color: $color-gray-30;
                color: $color-white;
            }
        }
    }

    &--disabled {
        color: $color-gray-80;

        .badge {
            background-color: $color-gray-80;
        }
    }
}

@include responsive_min(md) {
    .badge-card {
        $self: &;

        padding: rem-calc(32);
        flex-direction: row;

        &-body {
            flex-direction: row;
            flex: 1 1 auto;

            .wrapper {
                display: flex;
                flex: 1 1 auto;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        &-footer {
            margin: 0;
            display: flex;
        }

        .badge {
            margin-right: rem-calc(56);
        }

        &__content {
            margin-bottom: 0;
            max-width: rem-calc(570);

            .title {
                margin-bottom: rem-calc(5);
                font-size: rem-calc(24);
                line-height: rem-calc(36);
            }
        }

        &__actions {
            display: flex;
            align-items: center;

            .mdc-button {
                justify-content: center;
                font-weight: normal;
                font-size: rem-calc(18);
                line-height: rem-calc(24);
                text-transform: uppercase;
                white-space: nowrap;
                min-width: rem-calc(230);

                padding: rem-calc(18);

                @include theme() {
                    border: 1px solid t($t-color-info);
                }

                #{$self}--highlighted & {
                    height: auto;
                    border: none;
                }
            }
        }


        &--no-actions {
            #{$self}-body .wrapper {
                flex-basis: 100%;
            }

            #{$self}__content {
                max-width: initial;
            }
        }
    }
}