$menu-text-field-border-color: #d8d9e2;

div.mdc-menu,
.mdc-menu {
    &-search {
        /*Error line pushes dropdown further down, so hack to show dropdown on top of error line*/
        .mdc-text-field-helper-line + .mdc-menu {
            margin-top: -26px;
        }
        .mdc-menu {
            border-radius: 0;

            @include theme() {
                border: 2px solid t($t-color-secondary);
                border-top: 0 none;
            }

            box-shadow: none !important;
            min-width: 100%;

            .mdc-text-field {
                margin-top: 0;
                border-top: 1px solid $menu-text-field-border-color;
                border-bottom: 1px solid $menu-text-field-border-color;
                padding: 0 !important;
                background-color: $color-white !important;

                &.mdc-text-field--with-leading-icon .mdc-text-field__input {
                    padding-left: rem-calc(54) !important;
                }

                &.mdc-text-field--with-trailing-icon .mdc-text-field__input {
                    padding-right: rem-calc(32);
                    text-overflow: ellipsis;
                }

                .mdc-text-field__icon {
                    &.icon-search {
                        left: rem-calc(18);
                        color: $color-gray-10;
                    }

                    &.icon-close {
                        color: $color-gray-10;
                        font-size: rem-calc(16);
                    }
                }

                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: $color-white !important;
                }
            }

            .mdc-list {
                $list: &;
                padding: 0;
                font-size: rem-calc(16);
                line-height: 1.625em;
                letter-spacing: 0;
                color: $color-gray-10;
                max-height: 20vh;
                overflow-y: auto;

                &-item {
                    min-height: rem-calc(48);
                    height: rem-calc(48);
                    padding: 0 rem-calc(16);
                    color: inherit;
                    margin-bottom: 0 !important;

                    @include responsive_min(sm) {
                        height: rem-calc(60);
                    }

                    &--activated,
                    &--selected,
                    &:hover,
                    &:focus {
                        background-color: #f5f5f5;
                        color: $color-gray-10 !important;
                    }
                }

                &--select {
                    .mdc-list-item {
                        height: auto;
                        padding: rem-calc(4) rem-calc(18);
                        color: $color-black !important;

                        &__text {
                            white-space: normal;
                        }
                    }
                }
            }
        }

        &__dropdown-icon {
            @extend .icon-angle-down;
            display: flex;
            align-items: center;
            margin-bottom: 1px;
            width: auto;
            height: auto;
            background: none;
            font-size: rem-calc(12);
            color: #aaa;
            z-index: 1;
            transition: transform 0.15s;
        }

        &--holder {
            .mdc-text-field__input {
                cursor: pointer;
            }
        }

        // focused state
        &--focused.mdc-text-field--outlined.mdc-text-field {
            .mdc-menu-search__dropdown-icon {
                transform: rotate(180deg);
            }

            .mdc-text-field__input:hover~.mdc-notched-outline,
            .mdc-notched-outline {

                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    @include theme() {
                        border-color: t($t-color-secondary);
                    }

                    border-bottom: none;
                }
            }
        }

        &--no-filter {
            .mdc-menu {
                @include theme() {
                    border-top: 1px solid t($t-color-secondary);
                }

                .mdc-list {
                    max-height: initial;
                }
            }
        }
    }
    &-autocomplete {
        /*Error line pushes dropdown further down, so hack to show dropdown on top of error line*/
        .mdc-text-field-helper-line + .mdc-menu {
            margin-top: -26px;
        }
    }
    &-surface {
        &--open {
            display: block;
        }

        .mdc-text-field~& {
            @extend .mdc-menu--custom;
            top: 100%;
        }

        .mdc-select>& {
            @extend .mdc-select__menu;
            left: 0;
            top: rem-calc(48);
            margin-top: -2px;

            @include responsive_min(sm) {
                top: rem-calc(60);
            }
        }
    }

    &:not(.full-width) {
        border-radius: 0;
        box-shadow: 0 rem-calc(30) rem-calc(60) rgba($color-black, 0.15);
    }

    &--secondary {
        margin: 0;
        max-width: rem-calc(300);

        .mdc-list {
            width: 100%;
            padding: rem-calc(19) rem-calc(60) rem-calc(7) 0;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;

            .mdc-list-item {
                display: block;
                margin: 0;
                padding: rem-calc(8) rem-calc(26) rem-calc(8) 0;
                font-size: rem-calc(16);
                line-height: 1.875em;
                color: $color-blue-10;

                &:hover {
                    color: $color-white;

                    &:before {
                        opacity: 1;
                    }
                }

                &:before {
                    display: block;
                    left: auto;
                    right: 0;
                    top: rem-calc(10);
                    width: auto;
                    height: auto;
                    background: none;
                    @extend .icon-triangle;
                    font-size: rem-calc(10);
                    line-height: inherit;
                    opacity: 1;
                    transform: rotate(-90deg) !important;
                }
            }
        }
    }

    &--links {
        margin: 0 !important;
    }

    &--notifications {
        margin-top: rem-calc(15);
        margin-left: rem-calc(-30);
    }

    &--no-transition {
        transform: none !important;
        transition: none !important;
        animation: none !important;
        will-change: unset !important;
    }

    &--custom {
        .mdc-list {
            &:empty {
                padding: 0 !important;
            }
        }
    }
}

@include responsive_min(sm) {

    div.mdc-menu,
    .mdc-menu {
        &--secondary {
            min-width: rem-calc(140);
            margin: 25px 0 0;
            padding: rem-calc(8) 0;
            box-shadow: 0 rem-calc(8) rem-calc(48) rgba($color-black, 0.32);
            background-color: $color-white;
            z-index: 103;

            .mdc-list {
                border-bottom: 0 none;
                padding: 0;
                align-items: stretch;
                text-align: left;

                .mdc-list-item {
                    padding: rem-calc(8) rem-calc(48) rem-calc(8) rem-calc(58);
                    line-height: 1.66667;
                    height: auto;

                    &:hover {
                        background-color: $secondary;
                    }

                    &:before {
                        top: rem-calc(12);
                        right: auto;
                        left: rem-calc(31);
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        &--main {
            margin-top: rem-calc(20);

            & .rmwc-collapsible-list {
                flex-direction: row;

                &__children {
                    &-inner {
                        .mdc-list-item {
                            padding: rem-calc(8) rem-calc(48) rem-calc(8) rem-calc(58);
                            line-height: 1.66667;

                            &:hover {
                                background-color: $secondary;
                            }

                            &:before {
                                top: rem-calc(12);
                                right: auto;
                                left: rem-calc(31);
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }

                &__handle {
                    margin: 0 rem-calc(-10);

                    @include responsive_min(xlg) {
                        margin: 0 rem-calc(-15);
                    }

                    & .mdc-button {
                        & .arrow {
                            display: none;
                        }
                    }

                    padding: rem-calc(15) rem-calc(10);
                    font-size: rem-calc(16);
                    white-space: nowrap;
                    flex-direction: row;
                    align-items: center;
                    color: $color-white;
                }

                &--open {
                    border-bottom: 0 none;
                }
            }
        }

        &--search {
            margin-left: rem-calc(-30);
            margin-top: rem-calc(10);
        }
    }
}

@include responsive_max(sm) {

    div.mdc-menu,
    .mdc-menu {
        &--secondary {
            .mdc-list {
                .mdc-list-item {
                    &:before {
                        color: $secondary !important;
                    }
                }
            }
        }

        &--main {
            position: relative;
            background: none;
            box-shadow: none !important;

            & .rmwc-collapsible-list {
                &__children {
                    &-inner {
                        .mdc-list-item {
                            &:before {
                                color: $secondary !important;
                            }
                        }
                    }
                }

                &__handle {
                    & .mdc-button {
                        margin: 0 !important;

                        &.active {
                            & .mdc-button__label {
                                a {
                                    color: $color-white;
                                }
                            }

                            color: $color-white;
                        }
                    }
                }

                &--open {
                    border-bottom: 1px solid rgba($secondary, 0.25);
                }
            }
        }
    }
}