$mdc-select-height: rem-calc(48);
$mdc-select-mobile-height: rem-calc(48);
$mdc-menu-surface-z-index: 103;

.mdc-select {
    $select: &;

    &__selected-text {
        display: block !important;
        align-items: center;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        line-height: $mdc-select-height !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &#{&}--code {

        &__selected-text {
            @extend .mdc-select__selected-text;
        }

        &#{$select}--outlined#{$select}--with-leading-icon {
            #{$select}__icon.flag-icon {
                bottom: auto;
                box-shadow: none;
                opacity: unset;
                margin-left: rem-calc(26);
            }

            #{$select}__selected-text {
                padding-left: rem-calc(65);
            }
        }
    }

    &__menu {
        z-index: $mdc-menu-surface-z-index !important;
        margin-top: rem-calc(-2) !important;
        margin-left: 0 !important;

        .mdc-list {
            &-item {
                min-height: $mdc-select-height !important;
                height: auto !important;
            }
        }

        .flag-icon {
            background-size: rem-calc(25);
            background-position: rem-calc(15) 50%;
            background-repeat: no-repeat;
            padding-left: rem-calc(50) !important;
            width: 100%;

            &--country {
                margin-left: rem-calc(0) !important;
                margin-right: rem-calc(0) !important;
            }

            &--phone {
                margin: 0;
            }
        }
    }
}

@include responsive_max(sm) {
    div.mdc-select {
        height: $mdc-select-mobile-height;
        &__selected-text {
            line-height: $mdc-select-mobile-height !important;
        }

        &__menu {
            .mdc-list {
                &-item {
                    min-height: $mdc-select-mobile-height !important;
                }
            }
        }
    }
}
