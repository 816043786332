.dashboard-list {

    &__item {
        border: 1px solid $color-blue-10;
        border-radius: rem-calc(4);
        padding: rem-calc(15);
        font-size: rem-calc(12);
        line-height: rem-calc(18);

        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: rem-calc(4);
        }

        &>*:not(:first-child) {
            padding-left: rem-calc(8);
        }



        .right {
            float: none;
            margin-left: auto;
        }

        .blue {
            color: $color-blue-10;
        }

        .green {
            color: $color-green;
        }

        .red {
            color: $color-red;
        }

        .orange {
            color: $color-orange;
        }
    }

    &__more-link {
        font-size: rem-calc(16);
        line-height: rem-calc(18);
        font-weight: bold;
        text-align: center;
        color: $color-blue-10;
    }
}