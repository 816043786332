@import "./loader";

.loader {
  &--background {
    display: contents;

    &:after {
      display: block !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      z-index: 100;
      background-color: rgba($loader-background-color, .6);
    }
  }

}

.lds-roller {
  &--center {
    display: block;
    margin: 0 auto;

    .loader--background~& {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.lds-roller--small {
        transform: translate(-50%, -50%) scale(0.5);
      }

      &.lds-roller--medium {
        transform: translate(-50%, -50%) scale(0.75);
      }
    }
  }

  &.mdc-select-field__icon {
    z-index: 10;
    transform: translate(0, -50%) scale(0.5);
    top: 50%;

    margin-left: rem-calc(-8);
    position: absolute;
  }

  &.mdc-text-field__icon {
    top: 50%;
    margin-right: rem-calc(-12);
    transform: translate(0, -50%) scale(0.5);
  }

  &.btn-loading {
    transform: scale(0.6);
  }

  div {
    &:after {
      @include theme() {
        background-color: t($t-color-secondary);
      }
    }
  }

  &--white {
    div {
      &:after {
        background-color: #ffffff;
      }
    }
  }
}