@import "../components/icons";

$badge-size: rem-calc(85);
$badge-size-medium: rem-calc(70);
$badge-size-mobile: rem-calc(75);
$badge-size-small:rem-calc(64);

$badge-done-tag-size: rem-calc(24);

.badge-wrapper {
    display: flex;
    align-items: center;
}

.badge {
    width: $badge-size;
    height: $badge-size;
    display: flex;
    justify-content: center;
    align-items: center;

    &--discount {
        background-color: $color-gray-80;
        border-radius: 100%;
        color: $color-white;

        &.done {
            background-color: $color-green;
            position: relative;

            &:after {
                content: "ö";
                @include icon2-styles;
                position: absolute;
                background-color: $color-white;
                color: $color-green;
                top: 0;
                right: 0;
                height: $badge-done-tag-size;
                width: $badge-done-tag-size;
                line-height: $badge-done-tag-size;
                border-radius: 100%;
                font-size: rem-calc(10);
                display: flex;
                justify-content: center;
            }
        }

        &.custom {
            position: relative;
            background-color: transparent;

            .badge-custom-img {
                position: absolute;
            }
        }

        &.disabled {
            background-color: $color-gray;
        }

        &.new,
        &.permissionRequired {
            @include theme() {
                background-color: t($t-color-secondary-2);
            }
        }

        &.inprogress {
            background-color: $color-orange;
        }

        .icon {
            font-size: rem-calc(25);
        }
    }

    &--medium {
        width: $badge-size-medium;
        height: $badge-size-medium;
    }

    &--contribution {
        background-color: $color-green;
        border-radius: 100%;
        color: $color-white;
        font-size: rem-calc(40);
        float: left;
        position: relative;
        width: $badge-size-small;
        height: $badge-size-small;
    }

    &-content {
        float: left;
        text-transform: uppercase;
        padding-left: rem-calc(15);
        max-width: calc(100% - #{$badge-size});

        &-data {
            font-size: rem-calc(35);
            color: $color-primary;
            margin-bottom: rem-calc(5);
        }

        &-title {
            font-size: rem-calc(18);
            color: $color-green;
        }

        position: relative;

        &--contribution &-title {
            font-size: rem-calc(16);
        }

        &--contribution &-data {
            font-size: rem-calc(24);
            line-height: rem-calc(28);
            font-weight: 700;
        }
    }

    &-info-tooltip {
        position: absolute;
        top: -5px;
        right: -5px;

        .tooltip-text__content {
            top: rem-calc(10);
        }
    }

    &-standalone-tooltip {
        top: -5px;
        right: -5px;

        .tooltip-text__content {
            top: rem-calc(10);
            word-break: break-all;
            line-height: 1.2;
        }
    }

    &--blue {
        background-color: $color-blue-10;
    }

    &--text-blue {
        color: $color-blue-10;
    }

    &--light-blue {
        background-color: $color-blue;
    }
}

@include responsive_max(sm) {
    .badge {
        width: $badge-size-mobile;
        height: $badge-size-mobile;

        &--contribution {
            width: $badge-size-small;
            height: $badge-size-small;
        }

        .mdc-typography.mdc-typography--headline5 {
            font-size: rem-calc(16) !important;
        }
    }
}