div.mdc-notched-outline  {

  .mdc-floating-label {
    position: absolute !important;
    top: auto !important;
    left: 0 !important;
    bottom: 100% !important;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.625em;
    letter-spacing: 0.3px;
    color: $color-gray-10 !important;
    transform: none !important;
    z-index: 1;

    @include responsive_min(sm) {
      width: 100%;
    }
    &.mdc-floating-label--float-above {
      font-size: 0.875rem;
    }
  }

  &--notched {

    .mdc-notched-outline__notch {
      border-top: 2px solid;
    }
  }
}

// two lines label
.dropped-label {

  .mdc-floating-label {

    margin-bottom: rem-calc(13);

    span {
      display: block;
      font-size: rem-calc(16);
      font-weight: 400;
      line-height: 1.625em; // 26px
      letter-spacing: 0;
      color: $color-gray-50;
    }
  }
}

div[class*="--disabled"] .mdc-floating-label {
  color: $color-gray-80 !important;
}
