.campaign-promo {

  // set fluid promo image
  img.fluid {
    max-width: 100%;

    @include responsive_max(sm) {
      @media (orientation: landscape) {
        max-width: 50%;
      }
    }

    @include responsive_min(sm) {
      width: 100%;
    }
  }
  
  &__intro {
    color: $color-black;
    
    .mdc-typography--subtitle2 {
      margin-bottom: rem-calc(15);
      color: $primary;
    }

    .mdc-typography--body1 {

      &:not(:last-child) {
        margin-bottom: rem-calc(15);
      }
    }

    ol {
      margin-left: rem-calc(24);
      list-style-type: decimal;

      li:not(:last-child) {
        margin-bottom: rem-calc(5);
      }
    }
  }
}
