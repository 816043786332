$mdc-checkbox-mark-color: $color-white;
$mdc-checkbox-border-color: $color-gray;
$mdc-checkbox-touch-area: 24px;
$mdc-checkbox-size: 24px;
$mdc-checkbox-ui-pct: percentage($mdc-checkbox-size / $mdc-checkbox-touch-area);
$mdc-checkbox-mark-stroke-size: 4px;

.theme--default .mdc-checkbox {
  --mdc-theme-secondary: #172057;
}

.mdc-checkbox {
  // hide finger circle
  &__background {
    border-radius: 5px !important;

    &:before {
      display: none;
    }
  }

  &__checkmark {
    transform: scale(0.7) !important;
  }

  @at-root .mdc-form-field.light & {

    & + label {
      padding-top: rem-calc(2);
      font-weight: 400;
      line-height: 1.625em; // 26px

      @include responsive_min(sm) {
        line-height: rem-calc(24);
      }
    }
  }

  // label
  @at-root .mdc-form-field & {

    & + label {
      padding-left: rem-calc(15);
      font-size: rem-calc(16);
      line-height: 1.625em; // 26px
      font-weight: 700;
      letter-spacing: 0;

      @include responsive_min(sm) {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
      }

      a:not(.link) {
        color: #2499d6;
        text-decoration: none;
      }

      .mdc-button {
        display: inline-flex;
        vertical-align: top;
      }

      .link {
        color: inherit;
      }
    }
  }

  // invalid state
  &.mdc-checkbox--invalid:not(.mdc-checkbox--disabled) {

    .mdc-checkbox__background {
      border-color: $color-red-10 !important;
    }
  }

  // disabled state
  &.mdc-checkbox--disabled {

    .mdc-checkbox__background {
      border-color: $color-gray-30 !important;
    }

    & + label {
      color: $color-gray-80;
    }
  }

  &__medium-text {
    font-size: rem-calc(16) !important;
  }
}

.field--colored {
  @include theme() {
    background-color: t($t-color-background);
  }
  width: 100%;
  padding: rem-calc(10);
  label {
    width: 100%;
  }
}

.field--columns {
  display: inline-flex;
  padding-bottom: rem-calc(20);

  .field--column {
    padding-right: rem-calc(10);
    width: rem-calc(280);
  }
}

input.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  background-color: $color-white;
}

// invalid state
input.mdc-checkbox__native-control:invalid ~ .mdc-checkbox__background {
  border-color: $color-red-10 !important;
}
