$mdc-layout-grid-max-width: rem-calc(1218);

.mdc-layout-grid {

  @include responsive_max(md) {
    padding-top: rem-calc(10);
  }

  &.mdc-layout-grid--base {
    padding-top: rem-calc(10);
    padding-bottom: 0;

    @include responsive_max(sm) {
      padding: rem-calc(10) rem-calc(24) 0;
    }

    &-narrow {
      @include responsive_max(sm) {
        padding: rem-calc(10) rem-calc(16) 0;
      }
    }

    .mdc-layout-grid--base {
      padding: 0;
    }
  }

  &.mdc-layout-grid--padded {
    padding: rem-calc(10) rem-calc(35) 0 rem-calc(35);
  }

  &.mdc-layout-grid--pull {
    @include responsive_min(md) {
      padding-left: 10%;
    }
  }

  // grid for multisteps form
  &.mdc-layout-grid--form {

    .mdc-layout-grid__inner {
      grid-gap: rem-calc(30);
    }
  }

  // grid for contacts form
  &.mdc-layout-grid--contact {

    &>.mdc-layout-grid__inner {
      grid-row-gap: rem-calc(28);

      @include responsive_min(sm) {
        grid-gap: rem-calc(45) !important;
      }
    }
  }

  // grid zero gap
  &.mdc-layout-grid--empty {

    &>.mdc-layout-grid__inner {
      grid-gap: rem-calc(20);

      @include responsive_min(sm) {
        grid-gap: 0;
      }
    }
  }

  &.mdc-layout-grid--no-gap,
  .mdc-layout-grid--no-gap {
    >.mdc-layout-grid__inner {
      grid-gap: 0;
    }
  }

  &.mdc-layout-grid--mobile-vertical {
    @include responsive_max(md) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--full-height {
    height: 100%;

    .mdc-layout-grid__inner {
      height: 100%;
    }
  }

  &.mdc-layout-grid--mobile-full {
    @include responsive_max(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include responsive_max(lg) {
    &--dashboard {
      .mdc-layout-grid__inner {
        grid-gap: rem-calc(20) !important;
      }
    }
  }
}

.mdc-layout-grid__inner {
  &--baseline {
    align-items: baseline !important;
  }

  &.border-top {
    border-top: 1px solid $color-gray;
  }

  &.border-bottom {
    border-bottom: 1px solid $color-gray;
  }
}

.mdc-layout-grid__cell {

  &--main {
    margin: 0;
    padding: 0 rem-calc(16);

    @include responsive_min(sm) {
      padding: 0;
    }

    @include responsive_min(md) {
      margin-bottom: rem-calc(88);
      margin-top: rem-calc(64);
    }

    @include responsive_min(lg) {
      margin-left: 8.3333%; // fix for ie 11

      @supports (display: grid) {
        margin-left: 0;
        grid-column-start: 2;
      }
    }

    &.push {
      margin-top: rem-calc(45);
      margin-bottom: rem-calc(120);

      // fix for mobile
      @include responsive_max(md) {
        &:not(:last-child) {
          margin-bottom: rem-calc(13);
        }
      }

      @include responsive_min(md) {
        margin-top: rem-calc(75);
        margin-left: 16.6666%; // fix for ie 11

        @supports (display: grid) {
          margin-left: 0;
          grid-column-start: 3;
        }
      }
    }

    &.pull {
      margin-bottom: rem-calc(72);

      @include responsive_min(sm) {
        margin-bottom: rem-calc(60);
      }
    }

    &.empty {
      margin-top: 0;
      padding: 0;

      @include responsive_max(sm) {
        margin-bottom: 0;
      }
    }

    &.clear {
      margin-top: 0;

      @include responsive_max(sm) {
        margin-bottom: 0;
      }
    }
  }

  &--pull {

    @include responsive_min(md) {
      margin-left: 8.3333% !important; // fix for ie 11

      @supports (display: grid) {
        margin-left: 0 !important;
        grid-column-start: 2;
      }
    }
  }

  &--aside {
    margin-top: rem-calc(36);
    padding: 0 rem-calc(16);

    @include responsive_min(md) {
      margin-top: rem-calc(16);
      margin-bottom: rem-calc(45);
    }

    &:not(.links) {

      @include responsive_max(md) {
        order: -1;
      }

      @include responsive_min(md) {

        @supports (display: grid) {
          padding: 0;
          grid-column-start: 10;
        }
      }
    }

    &.links {

      @include responsive_max(sm) {
        display: none;
      }
    }
  }

  @for $col from 1 through 12 {
    &--start-#{$col} {
      grid-column-start: $col;
    }

    @include responsive_max(sm) {
      &--start-#{$col}-phone {
        grid-column-start: $col;
      }
    }

    @include responsive_between(sm, md) {
      &--start-#{$col}-tablet {
        grid-column-start: $col;
      }
    }

    @include responsive_min(md) {
      &--start-#{$col}-desktop {
        grid-column-start: $col;
      }
    }
  }
}


.grid-gap-48 {
  >.mdc-layout-grid__inner {
    grid-gap: 48px;
  }
}

.grid-gap-32 {
  >.mdc-layout-grid__inner {
    grid-gap: 32px;
  }
}

.grid-gap-24 {
  >.mdc-layout-grid__inner {
    grid-gap: 24px;
  }
}

.grid-gap-16 {
  >.mdc-layout-grid__inner {
    grid-gap: 16px;
  }
}


@include responsive_max(lg) {
  .grid-gap-tablet-32 {
    >.mdc-layout-grid__inner {
      grid-gap: 32px;
    }
  }

  .grid-gap-tablet-24 {
    >.mdc-layout-grid__inner {
      grid-gap: 24px;
    }
  }

  .grid-gap-tablet-16 {
    >.mdc-layout-grid__inner {
      grid-gap: 16px;
    }
  }
}

@include responsive_max(sm) {
  .grid-gap-mobile-32 {
    >.mdc-layout-grid__inner {
      grid-gap: 32px;
    }
  }

  .grid-gap-mobile-24 {
    >.mdc-layout-grid__inner {
      grid-gap: 24px;
    }
  }

  .grid-gap-mobile-16 {
    >.mdc-layout-grid__inner {
      grid-gap: 16px;
    }
  }
}