$mdc-list-roles-item-height-mobile: rem-calc(84);
$mdc-list-roles-item-height: rem-calc(90);

*.mdc-list {

  // modification
  &-group--roles {

    &.mdc-list {
      padding: 0;
      .mdc-list-item {
        height: $mdc-list-roles-item-height-mobile;
        margin-bottom: rem-calc(5);
        padding: 0 rem-calc(16);
        transition: background-color 365ms cubic-bezier(.4,0,.2,1);

        @include theme() {
          background: t($t-color-background);
        }
  
        @include responsive_min(sm) {
          height: $mdc-list-roles-item-height;
          padding: 0 rem-calc(30);
        }
  
        // list item hover state
        &:hover {
          color: $color-white;
          @include theme() {
            background: t($t-color-primary);
          }
  
          & * {
            color: $color-white;
          }

          .icon {
            color: $color-white;
          }

          &:before {
            background: none;
          }
        }
      }
    }

    // list item text
    .mdc-list-item__text {
      line-height: rem-calc(26);
      align-self: center !important;

      @include responsive_min(sm) {
        line-height: rem-calc(28);
      }
    }

    // list item primary text
    .mdc-list-item__primary-text {
      margin-bottom: 0;
      font-size: rem-calc(16);
      font-weight: bold;
      line-height: inherit;
      @include theme() {
        color: t($t-color-primary);
      }

      &:before,
      &:after {
        display: none;
      }
    }

    // list item secondary text
    .mdc-list-item__secondary-text {
      font-size: rem-calc(16);
      line-height: inherit;
      color: $color-gray-10;

    }

    // list item arrow icon
    .icon:not(.icon-alert-small) {
      margin-left: auto;
      transform: rotate(180deg);
      @include theme() {
        color: t($t-color-secondary);
      }
    }
  }

  // marked list (with angle)
  &.mdc-list--marked {
    margin-top: rem-calc(15);
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: 0;

    .mdc-list-item {
      height: auto;
      padding: 0 0 0 rem-calc(30);
      cursor: default !important;

      &:not(:last-child) {
        margin-bottom: rem-calc(15);
      }

      &:before {
        top: rem-calc(10) !important;
        left: rem-calc(6) !important;
        width: 0 !important;
        height: 0 !important;
        border-style: solid;
        border-width: 0 rem-calc(6) rem-calc(10) rem-calc(6);
        border-color: transparent transparent $secondary transparent;
        border-radius: 0 !important;
        background-color: transparent !important;
        opacity: 1 !important;

        @include responsive_min(lg) {
          top: rem-calc(11) !important;
        }
      }

      &:after {
        display: none;
      }

      &__text {
        text-overflow: clip;
        white-space: normal;
        overflow: visible;
      }
    }
  }

  &.mdc-list--verbose {
    .mdc-list-item {
      &-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-left: rem-calc(25);
        margin-bottom: rem-calc(10);
        line-height: 1.2;
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .mdc-list-item--small {
    font-size: rem-calc(14);
    color: $color-gray-50;
  }

  &.mdc-list--bordered {
    border-top: 2px solid $color-blue-20;
    padding: 0;

    &.wide {
      margin-left: rem-calc(-24);
      margin-right: rem-calc(-24);

      @include responsive_min(sm) {
        margin: 0;
      }
    }

    &.inline {
      margin-top: rem-calc(10);
      margin-bottom: rem-calc(30);
      border-top-width: 1px;

      @include responsive_min(sm) {
        margin-top: rem-calc(19);
        margin-bottom: rem-calc(33);
      }

      .mdc-list-item {
        border-bottom-width: 1px;
        padding: rem-calc(10) rem-calc(12) rem-calc(9);

        @include responsive_min(sm) {
          height: auto;
          padding: rem-calc(10) rem-calc(15) rem-calc(9);
        }

        .title {
          
          @include responsive_min(sm) {
            min-width: rem-calc(240);
            margin-right: rem-calc(30);
          }
        }

        .mdc-typography {
          margin-bottom: 0;

          @include responsive_max(sm) {
            font-size: rem-calc(14) !important;
            line-height: 1.57143em !important; // 22px
          }
        }
      }
    }

    .mdc-list-item {
      height: auto;
      border-bottom: 2px solid $color-blue-20;
      padding: rem-calc(20) rem-calc(24);
      cursor: default !important;
      overflow: visible;

      @include responsive_min(sm) {
        height: rem-calc(92);
        padding: rem-calc(25) rem-calc(45);
      }

      &:before,
      &:after {
        display: none;
      }

      &__text {
        margin-bottom: auto;
        text-overflow: clip;
        white-space: normal;
        overflow: visible;
        letter-spacing: 0 !important;

        @include responsive_min(sm) {
          margin-bottom: 0;
        }

        .tooltip {
          float: right;
          margin-left: rem-calc(10);
        }
      }

      &__meta {
        @include clearfix;
        text-align: right;
        letter-spacing: 0;

        @include responsive_min(sm) {
          text-align: left;
        }

        .mdc-switch {
          float: right;
        }
      }
    }

    .link {
      letter-spacing: 0 !important;
      color: $color-blue-10;
      transition: color .15s;

      &:hover,
      &:focus {
        color: $primary;
      }
    }
  }

  &.mdc-list--links {
    padding: rem-calc(30);
    flex-direction: column;
    font-size: rem-calc(16);
    line-height: 1.625;

    li.mdc-list-item {
      display: block;
      height: auto;
      padding: 0 0 0 rem-calc(21);
      color: $color-blue-10;
      transition: color .15s;

      &:hover {
        color: $primary;
      }

      &:before {
        display: block;
        left: 0 !important;
        top: 5px;
        width: auto !important;
        height: auto !important;
        background: none !important;
        @extend .icon-triangle;
        font-size: rem-calc(10);
        line-height: inherit;
        opacity: 1 !important;
        transform: rotate(-90deg);
      }

      &:after {
        display: none;
      }

      &:not(:last-child) {
        margin-bottom: rem-calc(15);
      }

      &.mdc-list-item--selected {
        color: $color-gray-10;
        font-weight: 700;

        &:before {
          color: $color-gray-80;
          transform: rotate(-180deg);
        }
      }
    }
  }

  &.mdc-list--notifications {
    padding: 0;
    letter-spacing: 0;

    .mdc-list-item {
      flex-direction: column;
      height: auto;
      padding: rem-calc(15) rem-calc(30);
      align-items: flex-start;

      @include responsive_min(sm) {
        padding: rem-calc(15) rem-calc(30);
      }

      &:before,
      &:after {
        display: none;
      }

      time {
        display: block;
        margin-bottom: rem-calc(5);
        font-size: rem-calc(12);
        color: $color-gray-80;
        opacity: .75;
      }

      &__text {
        max-width: 100%;
        font-size: rem-calc(14);
        color: $color-gray-10;
        white-space: normal;
        overflow: visible;
        opacity: .9;
      }
    }
  }
}

.mdc-list-group__header {
  padding: 0 0 rem-calc(15);
  font-size: rem-calc(16);
  color: $color-gray-80;
  text-transform: uppercase;
}

// item with primary text with marker
.mdc-list-item__primary-text {
  position: relative;
  padding-right: rem-calc(18);
  margin-right: rem-calc(18); // fix for IE

  &.marked {

    &:after {
      position: absolute;
      display: block;
      right: 0;
      top: 50%;
      width: rem-calc(8);
      height: rem-calc(8);
      margin-top: -#{rem-calc(4)};
      border-radius: 50%;
      background-color: $color-red;
      content: '';
    }
  }
}
