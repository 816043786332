@import "../helpers/_functions";
@import "../helpers/_variables";

.contact-info-item {
    display: flex;
    flex-direction: row;
    position: relative;

    &__icon {
        position: absolute;
        left: 0;

        // top: rem-calc((24 - 16) / 2); // (line-height - font-size) / 2 
        width: rem-calc(21);
        font-size: rem-calc(21);

        &.icon-envelope {
            font-size: rem-calc(15);
            margin-top: rem-calc(5);
        }

        &.icon-location {
            font-size: rem-calc(24);
        }
    }

    &__content {
        margin-left: rem-calc(37);
    }
}

.contact-card {
    display: flex;
    align-items: center;
    margin-top: rem-calc(24);

    &__image {
        width: rem-calc(96);
        height: rem-calc(96);
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__content {
        margin-left: rem-calc(24);
    }

    @include responsive_max(sm) {
        margin-top: rem-calc(16);

        &__content {
            margin-left: rem-calc(16);
        }
    }
}