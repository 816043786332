$divider-height: rem-calc(2);
$divider-rotate: skewY(-2deg);

$divider-bg-primary: $primary;
$divider-bg-secondary: $secondary;
$divider-bg-light: $color-blue-20;

.divider {
  height: $divider-height;
  margin: 0 -#{rem-calc(16)};
  border: 0;
  opacity: .25;

  @include responsive_min(sm) {
    // width: 100%;
    margin: rem-calc(30) rem-calc(18); // fix for ie

    @supports (display: grid) {
      margin: rem-calc(18) 0;
    }
  }

  // divider modifications
  &--rotate {
    transform: $divider-rotate;
    transform-origin: 50% 50%;
  }

  &--primary {
    background-color: $divider-bg-primary;
  }

  &--secondary {
    background-color: $divider-bg-secondary;
  }

  &--light {
    margin: rem-calc(30) 0;
    background-color: $divider-bg-light;
    opacity: 1;

    @include responsive_min(sm) {
      margin: rem-calc(45) 0;
    }
  }
}
