.mdc-icon-button {
    background: none;

    & i {
        display: none;
        &.mdc-icon-button__icon--on {
            display: block !important;
        }
    }
    &::before,
    &:hover:before {
        background: none !important;
    }
}
