.table-wrap {
    overflow: auto;
}
  
.table-detail-list {
    width: 100%;
    white-space: nowrap;
    text-align: left;
    line-height: rem-calc(24);
    font-size: rem-calc(16);

    thead {
        width: 100%;
        font-weight: 600;
        @include theme() {
        background-color: t($t-color-background);
        }
        
        tr {
        @include theme() {
            border-bottom: 1px solid t($t-color-link);
        }
        }
    }

    th, td {
        padding: rem-calc(16) 0 rem-calc(16) rem-calc(24);
    }

    tr {
        border-bottom: 1px solid $color-gray-30;
    }

    .bold {
        font-weight: 600;
    }
}
  