.invoice-status {
    display: flex;
    flex: 0 1 auto;
    align-self: center;
    align-items: center;
    font-size: rem-calc(16);

    &--inline {
        display: inline-flex;
    }

    &--label {
        display: inline-flex;
        font-size: rem-calc(12);
        padding: rem-calc(4);
        color: $color-white;
        border-radius: rem-calc(4);
        font-weight: 600;
        

        &.mdc-theme--secondary {
            background-color: $color-gray-80;
            color: $color-white !important;
        }

        &.mdc-theme--alert {
            background-color: $color-red;
        }

        &.mdc-theme--warning {
            background-color: $color-orange-30;
        }

        &.mdc-theme--done {
            background-color: $color-green-10;
            color: $color-white !important;
        }
    }
}