@import "../helpers/variables";
@import "../helpers/functions";
@import "../base/typography";
@import "../components/digital-stamps";

$padding: rem-calc(25);
$border-radius: 5px;
$box-shadow: lightgray 1.95px 1.95px 2.6px;

.digital-stamps-client {
    position: relative;

    .mdc-button {
        width: 100%;
    }

    &--header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $padding;
        background-color: $color-blue-10;
    }

    &--content {
        & > div {
            margin: $padding;
        }

        &--title {
            margin-bottom: $padding;
            font-weight: 600;
            font-family: $font-secondary;
            font-size: rem-calc(20);
        }

        &-search {
            border-radius: $border-radius;
            padding: $padding $padding rem-calc(15);
            background-color: $color-blue-20;
            box-shadow: $box-shadow;

            &-text {
                padding: rem-calc(10);
                font-size: rem-calc(18);
                text-align: end;
            }
        }

        &-products {
            background-color: $color-blue-20;
            padding: $padding;
            border-radius: $border-radius;
            box-shadow: $box-shadow;
        }
    }

    &--separator {
        margin-top: $padding;
        margin-bottom: $padding;
        border-bottom: 1px solid $color-gray;
    }

    &--dialog {
        .mdc-dialog__button--dismiss {
            width: auto;
        }
    }

    .form {
        top: 0;
        width: 100%;

        &--main {
            transform: translateY(25%);
        }

        &--btn {
            justify-content: flex-end;
        }
    }
}
