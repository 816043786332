@import "../helpers/variables";
@import "../helpers/functions";


.map {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;

  // @include responsive_max(md) {
  //   margin-left: -#{rem-calc(16)};
  //   margin-right: -#{rem-calc(16)};
  // }

  .cluster div {
    line-height: rem-calc(40) !important;
  }


  &-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 1;
    padding: rem-calc(18);
    font-size: rem-calc(12);
  }

  &-focus {
    position: absolute;
    bottom: rem-calc(188);
    right: rem-calc(10);

    height: rem-calc(40);
    width: rem-calc(40);
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    background-color: white;
    color: #666;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &--rotating {
      svg {
        animation: rotate 2s linear infinite;
      }
    }
  }

  .station-info {
    $side-padding: 24;
    $max-width: 352;

    max-width: rem-calc($max-width);
    padding-bottom: rem-calc($side-padding / 2);

    &__directions {
      margin-left: rem-calc(-$side-padding);
      margin-right: rem-calc(-$side-padding);
    }

    &__icons {
      overflow-x: auto;
      padding-bottom: rem-calc($side-padding / 2);

      i {
        margin: 0 rem-calc(3);
      }
    }

    @media screen and (max-width: #{$max-width + 50 + 'px'}) {
      max-width: 90vw;
    }
  }
}

// Overrides for google maps style
.gm-style {

  .gm-style-iw-c {
    padding: 0;
  }

  .gm-style-iw-d {
    overflow: auto;
  }

  .gm-style-iw-t:after {
    content: none;
    height: 0;
    width: 0;
  }
}