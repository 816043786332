.mdc-text-field {
    &__icon {
        outline: none;
        font-size: rem-calc(18);
        top: rem-calc(15);
        bottom: rem-calc(20);
        
        &.icon-close {
            font-size: rem-calc(20) !important;
        }
        &.mdc-menu-search__dropdown-icon {
            font-size: rem-calc(12);
        }
    }

    .mdc-notched-outline {
        &__notch {
            .mdc-floating-label {
                white-space: normal;
            }
        }
    }
}