.nav {
    &--primary {
        .mdc-list  {
            padding: 0;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .mdc-list-submenu {
                padding-left: 0;
                height: auto;
            }
        }
    }

    &--secondary {
        .nav__link {
            height: rem-calc(30) !important;

            &--disabled {
                color: $color-gray-50;
            }
        }
    }

    &-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 12px 10px 8px;
        display: flex;
        z-index: 1000;
        width: 100%;
        height: rem-calc(68);

        @include theme() {
            background-color: t($t-color-primary);
        }

        &-item {
            display: flex;
            flex-direction: column;
            flex: 1 1 0px;
            align-items: center;
            justify-content: center;
            color: $color-white;
            font-size: rem-calc(32);

            img {
                width: rem-calc(45);
            }

            &.active,
            &:active {
                @include theme() {
                    color: t($t-color-secondary);
                }
            }

            &-text {
                font-size: rem-calc(12) !important;
                line-height: 1rem !important;
                margin: 4px 4px 0;
            }

            .icon {
                font-size: rem-calc(24);
            }
        }

    }
}

.mdc-menu {
    &--search {
        min-width: rem-calc(628) !important;
        min-width: 39.25rem !important;

        &.wrap-search {
            display: none;
            position: absolute;
            top: rem-calc(-15) !important;
            height: rem-calc(60);
            min-width: rem-calc(628) !important;
            background-color: $color-white;
            align-items: center;

            &.open,
            &.mdc-menu-surface--open {
                display: flex;
            }

            &>.mdc-icon-button {
                display: flex;
                flex: 0 rem-calc(65);
                height: rem-calc(36);
                border-right: 1px solid $color-gray;
                padding: 0;
                color: $color-gray;
                align-items: center;
                justify-content: center;
                transition: color 0.15s;

                &:hover,
                &:focus {
                    color: $color-blue-10;
                }
            }

            &>div {
                flex: 1 auto;
            }

            .mdc-menu {
                box-shadow: 0 rem-calc(24) rem-calc(48) rgba($color-black, 0.16);
            }
        }

        &.wrap-results {
            padding-left: rem-calc(65);
            margin: 0 !important;
        }
    }
}