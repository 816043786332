@import "../helpers/variables";
@import "../helpers/functions";

.app-campaigns {
    .illustration img {
        margin: auto;
    }

    .icon-label {
        color: $color-gray-10;
        font-size: rem-calc(14);
        font-weight: bold;
    }

    .rmwc-collapsible-list {
        border: 2px solid #ccc;
        border-radius: 4px;
    }

    td {
        white-space: normal;
        word-wrap: break-word;
    }

    .mdc-text-field-helper-text {
        color: var(--mdc-theme-error);
        opacity: 1;
    }
}