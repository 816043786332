*.mdc-list {
    &.mdc-list--links {
        div.mdc-list-item {
            @extend li.mdc-list-item;
            & a {
                color: $color-blue-10;
                &:hover {
                    color: $primary;
                }
            }
            &:before {
                transform: rotate(-90deg) !important;
            }
        }
    }
}
