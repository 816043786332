@import "../helpers/functions";
@import "../helpers/variables";
@import "../base/typography";

$badge-size-medium: rem-calc(70);
$badge-done-tag-size: rem-calc(24);

.link-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rem-calc(16);

        & > div {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem-calc(75);
            height: rem-calc(75);
            border-radius: 50%;
            background-color: black;
            color: $color-white;
            font-size: rem-calc(28);

            &.icon-gift {
                background-color: $color-primary;
            }

            &.icon-members {
                background-color: $secondary;
            }

            &.icon-calculator {
                background-color: $color-pink-10;
            }

            &.icon-list {
                background-color: $color-primary;
            }

            & > .badge {
                position: absolute;
                background-color: $color-pink;
                color: $color-white;
                top: 0;
                right: 0;
                height: $badge-done-tag-size;
                width: $badge-done-tag-size;
                line-height: $badge-done-tag-size;
                border-radius: 100%;
                font-size: rem-calc(14);
                font-weight: bold;
            }
        }
    }

    &--content {
        font-family: $font-secondary;
        color: $color-primary;

        &-title {
            font-weight: 600;
            font-size: rem-calc(22);
            margin-bottom: rem-calc(12);
        }

        &-description {
            font-family: $font-primary;
            color: $color-gray-10;
            margin-bottom: rem-calc(12);
            height: rem-calc(56);

            @include responsive_max(lg) {
                height: auto;
            }
        }

        &-url {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            & > a {
                color: $color-primary;
                font-weight: 600;
                margin-right: rem-calc(10);
            }

            & > i {
                font-size: rem-calc(20);
            }
        }
    }
}