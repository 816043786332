@import "../helpers/functions";
@import "../helpers/variables";

$tooltip-padding: rem-calc(30);

.tooltip-text {
  color: $color-blue-10;
  font-size: rem-calc(20);
  font-weight: bold;
  position: relative;
  display: inline-block;
  user-select: none;
  border-radius: 5px;
  border: 2px solid $color-blue-10;
  padding: rem-calc(10);

  @include responsive_max(md) {
    padding: rem-calc(5);
    font-size: rem-calc(14);
    line-height: 1;

    &__content {
      top: rem-calc(35);
    }
  }

  &--on-left-side .tooltip-text__content {
    padding: rem-calc(15);
    transform: none;
    left: 0;
  }

  &--green {
    color: $color-green;
    border-color: $color-green;
  }

  &__content {
    position: absolute;
    top: rem-calc(45);
    left: 50%;
    transform: translateX(-50%);
    background: $primary;
    color: $secondary;
    font-size: rem-calc(16);
    padding: rem-calc(15);
    border-radius: rem-calc(5);
    width: rem-calc(190);
    white-space: normal;
    z-index: 2;
    font-weight: normal;
  }
}

.tooltip-info {
  color: $color-blue-10;
  font-size: rem-calc(26);
  user-select: none;
  cursor: pointer;

  &__wrapper {
    position: relative;
  }
}