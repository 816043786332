.loading-cycle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    svg {
        width: 150px;
        height: 150px;
        text-align: center;

        circle {
            transform-box: fill-box;
            transform-origin: center;
            transform-origin: 150px, 150px;
            transform: rotate(-90deg);
        }

        text {
            font-size: 40px;
        }
    }
}