$mdc-select-arrow-padding: 60px;
$mdc-select-outlined-idle-border: $color-gray;

div.mdc-select {
  width: 100%;

  @include responsive_max(sm) {
    height: rem-calc(48);
  }

  &:not(.mdc-select--code):not(.mdc-select--no-label) {
    margin-top: rem-calc(26);
  }

  // size modification
  &.small {

    @include responsive_min(sm) {
      max-width: rem-calc(150);
    }
  }

  // size modification
  &.wide {

    @include responsive_min(lg) {
      width: rem-calc(300);
    }
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    transition: none;
  }

  &--outlined .mdc-select__native-control,
  &--outlined .mdc-select__selected-text {
    font-size: rem-calc(16);
    height: rem-calc(48);
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
  }

  .mdc-select__dropdown-icon {
    @extend .icon;
    @extend .icon-angle-down;
    left: auto;
    right: 20px;
    bottom: 17px;
    width: auto;
    height: auto;
    background: none;
    font-size: rem-calc(12);
    color: #aaa;
    z-index: 1;
    transition: transform .15s;
  }

  // invalid state
  &.mdc-select--invalid:not(.mdc-select--disabled) {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $color-red-10;
    }
  }

  // disabled state
  &.mdc-select--disabled {

    .mdc-select__dropdown-icon {
      color: $mdc-select-outlined-idle-border;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: $color-gray-30;
    }
  }

  // focused state
  &.mdc-select--focused {
    z-index: 9;

    select {
      opacity: 0;
    }

    .mdc-select__dropdown-icon {
      transform: rotate(180deg);
      bottom: 17px;
    }
  }

  // focused state
  &.mdc-select--code {
    .mdc-select__dropdown-icon {
      left: rem-calc(11);
      right: auto;
      font-size: rem-calc(8);
      color: $color-gray-10;
      bottom: 21px;
    }

    .mdc-select__selected-text {
      min-width: inherit;
      padding-right: rem-calc(16);
      padding-left: rem-calc(34);
      margin-left: 5px;
      color: $color-gray;

      @include responsive_min(sm) {
        padding-left: rem-calc(40);
        line-height: rem-calc(34);
      }
    }

    &:not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
      border-right: 2px solid;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      background-color: #f5f5f5 !important;
    }

    .mdc-notched-outline__trailing {
        border-right: none;
    }

    &.mdc-select--disabled {

      .mdc-select__dropdown-icon {
        color: $mdc-select-outlined-idle-border;
      }

      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        background-color: $color-white !important;
      }

      .flag-icon {
        opacity: .25;
      }
    }

    & ~ .mdc-menu-surface {
      border-top: 2px solid $secondary;
    }

    .flag-icon {
      float: left;
      margin-right: rem-calc(8);
      height: auto;
      box-shadow: 0px 1px 2px rgba($color-black, .25);
      align-self: center;

      @include responsive_max(sm) {
        max-width: rem-calc(24);
      }
    }
  }

  &--vertical-aligned {
    vertical-align: middle;
  }
}

div.select-field--wrapper {
  width: 100%;
  div.mdc-select.mdc-select--no-margin {
    margin-top: 0;
  }
}

@include responsive_max(sm) {
  div.select-field--wrapper {
    width: 100%;
    max-width: none;
  }
}