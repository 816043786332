.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em
}
.flag-icon:before{
  content: "\00a0";
}

.flag-icon-ee {
  background-image: url('../../images/flags/ee.svg');
}

.flag-icon-lv {
  background-image: url("../../images/flags/lv.svg");
}

.flag-icon-lt {
  background-image: url("../../images/flags/lt.svg");
}

.flag-icon-fi {
  background-image: url("../../images/flags/fi.svg");
}

.flag-icon-se {
  background-image: url("../../images/flags/se.svg");
}

.flag-icon-no {
  background-image: url("../../images/flags/no.svg");
}