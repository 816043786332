@import "footer";
@import "../base/typography";
@import "../helpers/variables";

$mobile-footer-height: 68;
$footer-item-padding-bottom: 1rem;

.footer {
  padding-top: 3.625rem;

  a {
    color: $color-white;
  }

  .icon {
    width: 1.75rem;
    margin-right: 0.75rem;
    font-size: 1.75rem;

    @include theme() {
      color: t($t-color-secondary);
    }

    vertical-align: middle;
    text-align: center;
  }

  &__menu {
    padding: rem-calc(24) 0;
    background: $color-primary;
    color: $color-white;

    & .mdc-layout-grid__inner {
      &--first-level {
        align-items: flex-end !important;
        display: grid;

        & .mdc-typography {
          margin-bottom: 1em;

          @include theme() {
            color: t($t-color-link);
          }
        }
      }

      &--second-level {
        & .mdc-typography {
          margin: 0.5em 0;

          &--body2 {
            font-size: rem-calc(14) !important;
          }
        }
      }
    }
  }

  &__contacts {
    @extend .footer__menu;
    position: relative;
    max-width: none;
    padding: 2rem;

    & .mdc-layout-grid__inner {
      & .mdc-typography {
        margin: 0.75em 0;

        &--body2 {
          margin: 0.75em 0;
          line-height: 1.5rem;
        }
      }
    }

    &::before {
      background: none;
      border-top: 2px solid;
      border-bottom: 2px solid;

      @include theme() {
        border-color: lighten(t($t-color-secondary), 25);
      }
    }
  }

  .mdc-typography--headline6 {
    font-weight: 700;
    margin-bottom: rem-calc(16);

    @include theme() {
      color: t($t-color-secondary);
    }
  }

  @include responsive_max(md) {
    padding-bottom: rem-calc($mobile-footer-height);
    padding-top: 0;

    &__menu,
    &__contacts,
    &__privacy {
      display: none;
    }
  }

  &-icon {
    padding-bottom: $footer-item-padding-bottom;
  }

  &-icon:before {
    content: "";
    display: block;
    width: 199px;
    height: 45px;

    @include theme() {
      background: url(t($t-image-logo)) no-repeat left 0/100%;
    }
  }

  &-social-media {
    display: flex;

    &-item {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      padding-top: rem-calc(24);
      font-size: rem-calc(20);
      transition: opacity .3s ease;

      &>p {
        font-size: rem-calc(14);
        padding-top: rem-calc(10);
      }

      &:not(:last-child) {
        padding-right: rem-calc(24);
      }

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }

  &-item {
    padding-bottom: $footer-item-padding-bottom;
    line-height: rem-calc(24) !important;
  }

  &-section-title {
    color: $color-blue;
    font-weight: 600 !important;
    padding-bottom: $footer-item-padding-bottom;
  }

  &-logo {
    width: rem-calc(125);
    margin-left: auto;
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__item {

      &--privacy {

        &,
        & a {
          color: $color-primary;

          &:hover {
            color: $color-black;
          }
        }
      }

      &>p {
        margin-right: auto;
      }

      &>a {
        text-align: center;
      }
    }
  }
}