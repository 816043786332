// NB! TOO NOT REPLACE THIS FILE WITH ADM FILE
.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 0;
  padding-left: 0;
}

.mdc-select + .mdc-select-helper-text {
  margin-right: 0;
  margin-left: 0;
}

.mdc-text-field-helper-text,
.mdc-select-helper-text,
.mdc-checkbox-helper-text {
  margin-top: rem-calc(10);
  padding: 0;
  font-size: rem-calc(14);
  line-height: 1.143em; // 16px
  letter-spacing: 0;

  &:before {
    display: none;
  }

  &.mdc-text-field-helper-text--validation-msg,
  &.mdc-select-helper-text--validation-msg {
    position: relative;
    padding-left: rem-calc(24);

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      line-height: 1;
    }
  }
}

.mdc-checkbox-helper-text {
  margin-left: rem-calc(47);
}
