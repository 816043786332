@import "../helpers/_variables";
@import "../helpers/_functions";
@import "../helpers/_theme";
@import "../helpers/_mixins";

@import "../components/invoice-status";
@import "../components/icons";
@import "../components/table";

.invoice {
    $self: &;

    &__line {
        width: 100%;
        display: flex;

        @include responsive_min(lg) {
            white-space: nowrap;
        }

        .name,
        .value {
            flex: 1 0 0;
        }

        .value {
            @include responsive_max(sm) {
                text-align: right;
            }
        }

        .name {
            @include responsive_max(sm) {
                flex-basis: auto;
            }
        }

        &--big {

            .name,
            .value {
                font-weight: bold !important;
                font-size: 1.25rem !important;
            }
        }

        &--italic {
            font-style: italic;
        }

        &--bold {
            font-weight: bold;
        }
    }

    &__header {
        display: flex;
    }

    &__services {
        margin-top: rem-calc(14) !important;

        .table-list {
            td .title {
                display: none;
            }

            .wrap {
                white-space: break-spaces;
            }

            @include responsive_max(sm) {

                thead {
                    display: none;
                }

                tbody,
                tfoot {
                    tr {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        padding: rem-calc(12) 0;

                        &:first-child {
                            border-top: $table-border;
                        }

                        & .name {
                            white-space: normal;
                            color: $color-primary;
                            font-weight: bold;
                            line-height: rem-calc(26);
                            margin-bottom: rem-calc(8);
                        }
                    }

                    td {
                        width: 100%;
                        display: flex;
                        flex: 0 0 auto;
                        justify-content: space-between;
                        padding: 0;
                        line-height: rem-calc(28);
                    }
                }


                tfoot {
                    background-color: initial;

                    tr:first-of-type() {
                        border-top: none;
                        padding-top: rem-calc(36);
                    }
                }
            }
        }
    }

    &__totals {
        display: inline-flex;
        flex-direction: column;

        margin-top: rem-calc(14) !important;

        // @include responsive_max(sm) {
        //     margin-top: rem-calc(4) !important
        // }

        .invoice__line {
            .name {
                @include responsive_max(md) {
                    flex-basis: auto;
                }
            }

            .value {
                text-align: right;
            }
        }
    }

    &__actions {
        $actions: &;
        display: flex;
        justify-content: flex-end;

        @include responsive_max(sm) {
            flex-direction: column;
            // justify-self: center;
        }

        >* {
            order: 1;

            @include responsive_max(sm) {
                &#{$actions}__button--pay {
                    order: 0;
                }
            }
        }

        .links {
            @include responsive_max(sm) {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
            }
        }
    }

    &__balance {
        padding: rem-calc(12);
        border: 1px solid $color-gray;
        border-radius: rem-calc(4);

        .invoice__line {
            padding: rem-calc(4) 0;

            .value {
                text-align: right;
            }
        }
    }

    &__payment-methods {
        .mdc-button {
            height: rem-calc(48);

            img {
                min-width: rem-calc(80);
                flex-shrink: 1;
            }

            &:disabled img {
                opacity: 0.6;
            }
        }
    }

    &__green-thanks {
        color: $color-green-10;
    }
}