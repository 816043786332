@import "../helpers/functions";
@import "../helpers/variables";

.marketing-notifications {

    &__item {
        display: flex;
        flex-direction: row;
        flex: 1 0 100%;

        &:not(:last-of-type) {
            padding-bottom: rem-calc(8);
            margin-bottom: rem-calc(8);
            border-bottom: 1px solid $color-gray-30;
        }

        &__content {
            flex: 1 0 0%;
        }
    }
}