@import "@material/rtl/mixins";
@import "@material/drawer/variables";

.mdc-drawer {
    z-index: 1003 !important;

    .mdc-drawer__header {
        display: flex;
        min-height: rem-calc(70);
        margin-bottom: rem-calc(30);
        padding: 0;
        color: $color-white;
        align-items: center;

        .item {
            @include theme() {
                color: t($t-color-primary);
            }

            &:not(:first-child) {
                margin-left: rem-calc(25);
            }

            .icon-search {
                padding: 0 1px;
                font-size: rem-calc(23);
            }

            .icon-user-mobile {
                font-size: rem-calc(40);
                line-height: 1;
            }

            &:focus,
            &.active {
                &:not(.close) {
                    &:before {
                        position: absolute;
                        display: block;
                        left: 50%;
                        top: 100%;
                        width: auto;
                        height: auto;
                        margin: 10px 0 0 -5px;
                        background: none;
                        opacity: 1;
                        @extend .icon-triangle;
                        font-size: rem-calc(7);
                        line-height: 1em;
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }

    .mdc-drawer__content {
        padding: rem-calc(10) 0 0;

        .mdc-list-item {
            font-weight: 400;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .disabled{
            color: $color-gray-50 !important;
        }
        .mdc-list--links {
            border-bottom: 1px solid $secondary;

            .mdc-list-item:before {
                @include responsive_max(sm) {
                    top: 0;
                }
            }
        }
    }

    .mdc-drawer-section {
        display: none;
        height: 100%;
        overflow-y: auto;

        &.active {
            display: block;
        }

        &.mdc-menu-surface {
            margin-left: rem-calc(-32);
            margin-right: rem-calc(-32);
        }

        &--navigation {
            padding-bottom: rem-calc(25);
            & .rmwc-collapsible-list {
                display: flex;
                flex-direction: column;
                padding: 0;
                width: 100%;

                &__children {
                    margin-right: rem-calc(-60);
                    width: 100%;
                    &-inner {
                        width: 100%;
                        padding: 0 0 rem-calc(6) rem-calc(45);
                        text-align: left;

                        .mdc-list-item {
                            display: block;
                            margin: 0;
                            padding: rem-calc(8) 0;
                            font-size: rem-calc(16);
                            line-height: 1.875em;
                            color: $color-white;

                            &:hover {
                                color: $color-white;
                            }
                        }
                    }
                }

                &__handle {
                    margin: 6px 4px rem-calc(16) rem-calc(40);
                    margin: 0;

                    & .mdc-button {
                        padding: rem-calc(16) 0 rem-calc(16) rem-calc(25);
                        height: auto;
                        width: 100%;
                        font: {
                            size: rem-calc(18);
                        }
                        line-height: 1.556em;
                        color: $color-blue-10;
                        & .mdc-button__label {
                            a, i {
                                color: $color-blue-10;
                                &.menu-active {
                                    color: $primary;
                                }
                            }

                        }
                        & .arrow {
                            display: block;
                            position: absolute;
                            right: rem-calc(25);
                            top: rem-calc(22);

                            &:before {
                                @extend .icon-angle-down;
                                font-size: rem-calc(12);
                                line-height: inherit;
                            }
                        }
                    }
                }

                &--open {
                    .arrow {
                        transform: rotate(180deg);
                    }
                }
            }

            position: relative;
            background: none;
            box-shadow: none !important;
        }

        &--profile {
            background-color: $color-white;
            width: 100vw;
            border-radius: 0;
            box-shadow: 0 rem-calc(30) rem-calc(60) rgba($color-black, 0.15);

            @include responsive_max(md) {
                position: static;
                top: auto;
                left: auto;
                max-width: none;
                width: auto;
                box-shadow: none;
            }

            @include responsive_min(md) {
                width: rem-calc(200);
            }

            &.wide {
                margin-left: rem-calc(-24);
                margin-right: rem-calc(-24);

                @include responsive_min(sm) {
                    margin: 0;
                }
            }
        }
    }

    &-modal {
        height: auto;
        bottom: 0;
    }

    &-scrim {
        z-index: 102 !important;
    }

    // fluid modification
    &.mdc-drawer--fluid {
        width: 100%;
        border: 0 none;
        padding: 0;
        background-color: $color-white;

        &.mdc-drawer--open + .mdc-drawer-scrim {
            display: none;
        }
        .mdc-drawer__header {
            padding: rem-calc(25) rem-calc(28) rem-calc(25);
            margin-bottom: 0;
            background-color: $color-white;
        }

        .mdc-drawer-section--navigation {
            & > a, 
            & > div {
                border-top: 1px solid $color-gray-30;
            }
            & > :last-child {
                border-bottom: 1px solid $color-gray-30;
            }
        }
    }

    &--reverse {        
        &.mdc-drawer--animate {
            transform: translateX(100%);
        
            @include mdc-rtl {
                transform: translateX(-100%);
            }
        }
        
        &.mdc-drawer--opening {
            transform: translateX(0);
            transition-duration: $mdc-drawer-animation-enter;
        
            // Required to level the specificity with animate class.
            @include mdc-rtl {
                transform: translateX(0);
            }
        }
        
        &.mdc-drawer--closing {
            transform: translateX(100%);
            transition-duration: $mdc-drawer-animation-exit;
        
            @include mdc-rtl {
                transform: translateX(-100%);
            }
        }
    }

    &--new_item {
        &-icon{
            background-color: $color-pink;
            border-radius: 50%;
            height: rem-calc(15);
            width: rem-calc(15);
            display: inline-flex;
            margin-left: rem-calc(15);
        }
        &-text{
            color: $color-pink;
            display: inline-flex;
            margin-left: rem-calc(15);
        }
    }     
}

.drawer-padding {
    @include responsive_max(md) {
        padding-bottom: rem-calc(70);
    }
}