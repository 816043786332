@import "../helpers/variables";
@import "../helpers/functions";
@import "../helpers/mixins";
@import "../base/typography";
@import "../components/radial-graph";
@import "../components/chart";
@import "../components/discount";
@import "../components/benefits";
@import "../components/badge";
@import "../components/contribution-card";
@import "../components/alert";

@import "../components/dashboard-tile";
@import "../components/dashboard-list";

@import "../components/card-qr-button";
@import "../components/card-qr-overlay";

.overview__program-description {
    border-top: 2px solid $color-gray-20;
    margin-top: rem-calc(48);
    padding-top: rem-calc(24);
}

.energy-discount-btn-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
