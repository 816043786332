@import "../helpers/variables";
@import "../helpers/functions";

$filter-form-bg: #f7f9fa;

.filter-form {
  display: none;
  margin: 0 rem-calc(-24);
  padding: rem-calc(30) rem-calc(24) rem-calc(30);
  background-color: $filter-form-bg;
  position: relative;

  // open state
  &--open {
    display: block;
  }

  &__head {
    display: inline;
  }

  &__export, &__pdfPrint {
    margin-inline-end: rem-calc(20);
    vertical-align: super;
  }

  // filter area toggle link
  &__link {
    position: relative;
    float: right;
    padding-top: rem-calc(8);
    margin: 0;
    align-self: flex-end;

    &:not(.active):hover {
      color: $primary;
    }

    &.active {
      color: $primary;

      .icon {
        transform: rotate(180deg);
      }
    }

    .icon {
      color: inherit;
      font-size: rem-calc(13);
      transition: transform .15s linear;
    }
  }

  label {
    font-weight: 400 !important;
  }

  &__quick-link {
    @include theme() {
      color: t($t-color-secondary);

      &.active {
        color: t($t-color-primary);

        .mdc-button__label {
          border-bottom: 2px solid t($t-color-primary);
        }
      }
    }
  }
}

@include responsive_max(sm) {
  .filter-form {
    &__link {
      font-size: rem-calc(14) !important;
      padding-top: rem-calc(6);

      .icon {
        font-size: rem-calc(9);
      }
    }
  }
}

@media (min-width: 320px) and (max-width: $screen-min-xs) {
  .filter-form__quick-link {
    width: 50%;
  }
}